import React, { useEffect, useState } from "react";
import Url from "../../Element/Api";
import axios from "axios";

function Singleagentmap({ selectedServiceAreaOption, properties }) {
  const [map, setMap] = useState(null);
  const [mapInitialized, setMapInitialized] = useState(false);

  //Function for initialising map
  const initMap = async () => {
    if (window.google) {
      const { Map } = await window.google.maps.importLibrary("maps");
      let mapCenter = { lat: 45.4869, lng: -122.804 };

      if (selectedServiceAreaOption) {
        try {
          const detailsResponse = await axios.get(`${Url.BASEURL}/api/user/getplacedetails`, {
            params: {
              input: selectedServiceAreaOption,
            },
          });

          if (detailsResponse && detailsResponse.data && detailsResponse.data.result) {
            const { geometry } = detailsResponse.data.result;

            if (geometry && geometry.location) {
              const { lat, lng } = geometry.location;
              mapCenter = { lat, lng };
            }
          } else {
            console.error("Error getting place details:", detailsResponse);
          }
        } catch (error) {
          console.error("Error fetching place details:", error);
        }
      }

      const newMap = new Map(document.getElementById("map"), {
        center: mapCenter,
        zoom: 12,
      });

      properties.forEach((property) => {
        const latitude = parseFloat(property.Latitude);
        const longitude = parseFloat(property.Longitude);

        if (!isNaN(latitude) && !isNaN(longitude)) {
          const marker = new window.google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            map: newMap,
            title: property.UnparsedAddress,
          });
        }
      });
      setMap(newMap);
      setMapInitialized(true);
    } else {
      console.error("Google Maps JavaScript API is not loaded");
    }
  };

  //Function for changing the location in map based on servicearea
  useEffect(() => {
    initMap();
  }, [selectedServiceAreaOption]);

  //If map is not initialised initialise map
  useEffect(() => {
    if (!mapInitialized) {
      initMap();
    }
  }, [mapInitialized]);


  return <div id="map" style={{ height: "806px", width: "100%" }}></div>;
}

export default Singleagentmap;
