import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateUserInfo } from "../components/Redux/Action/action";

const Auth = () => {
  const dispatch = useDispatch();
  const userIdToken = useSelector((state) => state.jwtuser);
  const refreshToken = userIdToken.refreshToken;
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    const fetchNewAccessToken = async () => {
      try {
        const response = await axios.post(`https://oregonhomeseeker.com:3001/api/user/refresh-token`, { refreshToken: refreshToken });
        dispatch(updateUserInfo({ jwtToken: response.data.accessToken }));
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    if (refreshToken) {
      const twoHours = 2 * 60 * 60 * 1000;
      const refreshTimer = setInterval(fetchNewAccessToken, twoHours);
      return () => clearInterval(refreshTimer);
    }
  }, []);

  useEffect(() => {
    const inactivityTimer = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastActivity > 60 * 60 * 1000) {
        dispatch(updateUserInfo({ image: "" }));
        localStorage.removeItem("jwtToken");
        dispatch(updateUserInfo({ jwtToken: "", _id: "" }));
        window.location.reload();
      }
    }, 10 * 60 * 1000);

    const updateUserActivity = () => {
      setLastActivity(Date.now());
    };

    window.addEventListener("mousemove", updateUserActivity);
    window.addEventListener("keypress", updateUserActivity);

    return () => {
      clearInterval(inactivityTimer);
      window.removeEventListener("mousemove", updateUserActivity);
      window.removeEventListener("keypress", updateUserActivity);
    };
  }, [lastActivity]);

  return null;
};

export default Auth;
