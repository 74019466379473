import React, { useEffect, useState } from "react";
import "./Navigation.css";
import axios from "axios";
import Url from "../Element/Api";
import { useNavigate } from "react-router-dom";
import Otp from "../../public/assets/otp.svg";
import Twitter from "../../public/assets/x.svg";
import Facebook from "../../public/assets/fb.svg";
import Cross from "../../public/assets/cross.svg";
import Google from "../../public/assets/gplus.svg";
import Phones from "../../public/assets/phone.svg";
import LinkedIn from "../../public/assets/link.svg";
import Message from "../../public/assets/message.svg";
import Username from "../../public/assets/login2.svg";
import Password from "../../public/assets/login1.svg";
import Success from "../../public/assets/success.png";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../Redux/Action/action";
import { useSelectedOptions } from "../SelectedOptionsContext";

function LoginProcessModal(props) {
  const dispatch = useDispatch();
  const Domain = window.location.hostname;
  const Usertoken_id = useSelector((state) => state.jwtuser);
  const regtoken = Usertoken_id.accessToken;
  const [otp, setOtp] = useState("");
  const [userdata, setUserdata] = useState({});
  const [showModal, setShowModal] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [activeTab, setActiveTab] = useState("login");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordStage, setForgotPasswordStage] = useState("initial");
  const [mailVerificationStage, setMailVerificationStage] = useState("initial");
  const [emailVerificationStatus, setEmailVerificationStatus] = useState("pending");
  const { showredirectloginModal, setShowredirectloginModal } = useSelectedOptions(false);
  const [error, setError] = useState({
    forgotPasswordError: "",
  });

  const [loginError, setLoginError] = useState({
    email: "",
    password: "",
  });
  const [signupError, setSignupError] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [formDataForLogin, setFormDataForLogin] = useState({
    email: "",
    password: "",
  });

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
    setForgotPasswordStage("sendOTP");
  };

  const handleSentOTPClick = (e) => {
    e.preventDefault();
    setForgotPasswordStage("enterOTP");
  };

  const handleGetOTPClick = (e) => {
    e.preventDefault();
    setForgotPasswordStage("changePassword");
  };

  const handleSavePasswordClick = (e) => {
    e.preventDefault();
    setForgotPasswordStage("passwordChanged");
  };

  const handleSendOTP = async () => {
    // Implement logic to send OTP
    setMailVerificationStage("enterOTP");
  };
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setError({ forgotPasswordError: "" });
  };

  const handleEmailChange = (e) => {
    setForgotPasswordEmail(e.target.value);
    setError({ forgotPasswordError: "" });
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    setError({ forgotPasswordError: "" });
  };

  const modalcloser = (e) => {
    setShowForgotPassword(false);
    setShowredirectloginModal(false);
    setEmailVerificationStatus(false);
    setEmailSent(false);
    setLoginError({ email: "", password: "" });
    setSignupError({ name: "", email: "", phone: "", password: "" });
    setFormData({ name: "", email: "", phone: "", password: "" });
    setFormDataForLogin({ email: "", password: "" });
    setError({});
  };

  const handleBackToLogin = (e) => {
    e.preventDefault();
    setShowForgotPassword(false);
  };

  const handleChange = (event) => {
  const { name, value } = event.target;
  
  // Check if the input field is "phone"
  if (name === "phone") {
    // Limit the input value to 15 digits
    if (value.length <= 15) {
      // Update the state with the new value
      setFormData({
        ...formData,
        [name]: value
      });

      setSignupError((prevState) => ({
        ...prevState,
        [name]: ""
      }));
    }
  } else {
    // For other fields, update the state directly
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear any previous error message for other fields
    setSignupError((prevState) => ({
      ...prevState,
      [name]: ""
    }));
  }
};

  const handleSignup = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${Url.BASEURL}/api/user/register?domain=${Domain}`, formData);
      if (response.status == 200) {
        setMailVerificationStage("sendOTP");
        setEmailSent(true);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        setSignupError({
          name: err.response.data.errors.name,
          email: err.response.data.errors.email,
          phone: err.response.data.errors.phone,
          password: err.response.data.errors.password,
        });
      } else if (err.response && err.response.data && err.response.data.error){
setError((prevState) => ({
          ...prevState,
          signupError: err.response.data.error
        }));
      }
      else {
        setError((prevState) => ({
          ...prevState,
          signupError: "An error occurred while processing your request.",
        }));
      }
      console.log(err);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(`https://oregonhomeseeker.com:3001/api/user/verify-email`, {
        email: formData.email,
        otp: otp,
      });
      if (response.status === 200) {
        setEmailVerificationStatus("verified");
        window.location.reload();
        if (response.data && response.data.accessToken) {
          dispatch(updateUserInfo({ accessToken: response.data.accessToken }));
          dispatch(updateUserInfo({ jwtToken: response.data.accessToken }));
        } else {
          console.error("JWT token not found in response data");
        }
      } else {
        console.log("Email verification failed:", response.status);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: err.response.data.message,
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: "An error occurred while processing your request.",
        }));
      }
    }
  };

  const handleLoginChange = (event) => {
    const { name, value } = event.target;
    setFormDataForLogin({
      ...formDataForLogin,
      [name]: value,
    });

    setLoginError((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleLoginTabClick = () => {
    setLoginError({ email: "", password: "" });
    setFormData({ name: "", email: "", phone: "", password: "" });
  };

  const handleSignupTabClick = () => {
    setSignupError({ name: "", email: "", phone: "", password: "" });
    setFormDataForLogin({ email: "", password: "" });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${Url.BASEURL}/api/user/login?domain=${Domain}`, formDataForLogin);
      setUserdata(response.data);
      const { success, accessToken, refreshToken } = response.data;
      const user = response.data.user;

      if (success) {
        if (user && user.verified) {
          setUserdata(accessToken);
          localStorage.setItem("jwtToken", accessToken);
          dispatch(updateUserInfo({ accessToken: response.data.accessToken }));
          dispatch(updateUserInfo({ jwtToken: response.data.accessToken }));
          dispatch(updateUserInfo({ refreshToken: refreshToken }));
          dispatch(updateUserInfo({ _id: user._id }));
          // dispatch(updateUserInfo({ verified: user.verified }));
          dispatch(updateUserInfo({ image: user.image }));
          setActiveTab("");
          window.location.reload();
        } else {
          setMailVerificationStage("sendOTP");
          setEmailSent(true);

          setFormData({
            ...formData,
            email: formDataForLogin.email,
          });
        }
      } else {
        console.log("user is not success");
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        setLoginError({
          email: err.response.data.errors.email,
          password: err.response.data.errors.password,
        });
      } else if (err.response.data.error) {
        setError((prevState) => ({
          ...prevState,
          loginError: err.response.data.error,
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          loginError: "An error occurred while processing your request.",
        }));
      }
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Url.BASEURL}/api/user/forgot-password`, {
        email: forgotPasswordEmail,
      });

      if (response.status === 200) {
        setForgotPasswordStage("enterOTP");
      } else {
        console.log("Received status:", response.status);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: err.response.data.message,
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: "An error occurred while processing your request.",
        }));
      }
    }
  };

  const handleVerifyOTPSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Url.BASEURL}/api/user/verify-otp`, {
        email: forgotPasswordEmail,
        otp: otp,
      });
      if (response.status === 200) {
        setForgotPasswordStage("changePassword");
      } else {
        console.log("Received status:", response.status);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: err.response.data.message,
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: "An error occurred while processing your request.",
        }));
      }
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Url.BASEURL}/api/user/reset-password`, {
        email: forgotPasswordEmail,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      });
      if (response.status === 200) {
        setForgotPasswordStage("passwordChanged");
      } else {
        console.log("Received status:", response.status);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: err.response.data.message,
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          forgotPasswordError: "An error occurred while processing your request.",
        }));
      }
      console.log(err);
    }
  };
  useEffect(() => {
    if (regtoken) {
      const style = document.createElement("style");
      style.textContent = ".modal-backdrop { display: none !important; }";
      document.head.append(style);
    }
  }, [regtoken]);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "YOUR_FACEBOOK_APP_ID",
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });

      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          console.log("User is logged in and authenticated");
        } else {
          console.log("User is not logged in");
        }
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleFacebookLogin = () => {
    const facebookLoginUrl = `http://localhost:3001/api/sociallogin/auth/facebook`;
    window.open(facebookLoginUrl, "_blank", "width=500,height=500");
  };

  const loginWithGoogle = () => {
    const googleAuthUrl = `${Url.BASEURL}/api/sociallogin/auth/google?domain=${Domain}`;
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const options = `width=${width},height=${height},left=${left},top=${top},modal=yes,centerscreen=yes`;
    const googleWindow = window.open(googleAuthUrl, "Google Authentication", options);

    window.addEventListener("message", async (event) => {
      if (event.source === googleWindow) {
        const { success, accessToken, refreshToken, user, image } = event.data;
        if (success) {
          console.log("Authentication successful");
          setUserdata(accessToken);
          localStorage.setItem("jwtToken", accessToken);
          dispatch(updateUserInfo({ jwtToken: accessToken }));
          dispatch(updateUserInfo({ accessToken: accessToken }));
          dispatch(updateUserInfo({ refreshToken: refreshToken }));
          dispatch(updateUserInfo({ _id: user }));
          dispatch(updateUserInfo({ image: image }));
          googleWindow.close();
          window.location.reload();
        } else {
          googleWindow.close();
        }
      }
    });
  };

  const handleLinkedInLogin = () => {
    const linkedInAuthUrl = `${Url.BASEURL}/api/sociallogin/auth/linkedin?domain=${Domain}`;
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const options = `width=${width},height=${height},left=${left},top=${top},modal=yes,centerscreen=yes`;
    const linkedInWindow = window.open(linkedInAuthUrl, "LinkedIn Authentication", options);

    window.addEventListener("message", async (event) => {
      if (event.source === linkedInWindow) {
        const { success, accessToken, user } = event.data;
        if (success) {
          setUserdata(accessToken);
          localStorage.setItem("jwtToken", accessToken);
          dispatch(updateUserInfo({ jwtToken: accessToken }));
          dispatch(updateUserInfo({ accessToken: accessToken }));
          dispatch(updateUserInfo({ _id: user._id }));
          dispatch(updateUserInfo({ image: user.image }));
          linkedInWindow.close();
          window.location.reload();
        } else {
          linkedInWindow.close();
        }
      }
    });
  };

  const handleTwitterLogin = () => {
    const twitterAuthUrl = `${Url.BASEURL}/api/sociallogin/auth/twitter?domain=${Domain}`;
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const options = `width=${width},height=${height},left=${left},top=${top},modal=yes,centerscreen=yes`;
    const twitterWindow = window.open(twitterAuthUrl, "Twitter Authentication", options);

    window.addEventListener("message", async (event) => {
      if (event.source === twitterWindow) {
        const { success, accessToken, user, image } = event.data;
        if (success) {
          setUserdata(accessToken);
          localStorage.setItem("jwtToken", accessToken);
          dispatch(updateUserInfo({ jwtToken: accessToken }));
          dispatch(updateUserInfo({ accessToken: accessToken }));
          dispatch(updateUserInfo({ _id: user._id }));
          dispatch(updateUserInfo({ image: image }));
          twitterWindow.close();
          window.location.reload();
        } else {
          twitterWindow.close();
        }
      }
    });
  };

  return (
    <>
      {!regtoken && showModal && (
        <div className="modal fade" id="loginModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!showModal} data-backdrop={!showModal ? "" : "static"} data-keyboard={!showModal ? "" : "false"} role="dialog">
          {/* <EmailVerification email={formData.email} /> */}
          <div className="modal-dialog custom-modal-width modal-dialog-centered">
            <div className="modal-content">
              <div className="modal_closer">
                <img src={Cross} alt="User Icon" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => modalcloser(e)} />
              </div>
              <div className="modal-body">
                <div className="full_modal">
                  <div className="left_modal">
                    {emailSent ? (
                      <>
                        {mailVerificationStage == "sendOTP" && (
                          <div className="mail-verification-content">
                            <h2>Mail Verification</h2>
                            <p>Enter your OTP sent to your email.</p>
                            <div className="mb-3 fpass">
                              <div className="input-group">
                                <span className="input-group-text bg_nol" id="basic-addon1">
                                  <img src={Otp} alt="otp" />
                                </span>
                                <input type="text" className="form-control" placeholder="OTP*" value={otp} onChange={handleOtpChange} />
                              </div>
                            </div>
                            {error.forgotPasswordError && (
                              <div className="alert alert-danger" role="alert">
                                {error.forgotPasswordError}
                              </div>
                            )}

                            <button className="btn login_btn" onClick={handleVerifyOTP}>
                              Verify OTP
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {!showForgotPassword && (
                          <div className="tab-navigation">
                            <button
                              onClick={() => {
                                setActiveTab("login");
                                handleLoginTabClick();
                              }}
                              className={`tab-button ${activeTab === "login" ? "active" : ""}`}
                            >
                              Login
                            </button>
                            <button
                              onClick={() => {
                                setActiveTab("signup");
                                handleSignupTabClick();
                              }}
                              className={`tab-button ${activeTab === "signup" ? "active" : ""}`}
                            >
                              Sign up
                            </button>
                          </div>
                        )}
                        {activeTab === "login" && !showForgotPassword && (
                          <div className="tab-content">
                            <form onSubmit={handleLogin}>
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text bg_nol" id="basic-addon1">
                                    <img src={Username} alt="User Icon" />
                                  </span>
                                  <input name="email" type="email" className="form-control" placeholder="Email*" value={formDataForLogin.email} onChange={handleLoginChange} />
                                </div>
                                <span className="loginerror" style={{ color: "red", fontSize: "small" }}>
                                  {loginError.email}
                                </span>
                              </div>
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text bg_nol" id="basic-addon2">
                                    <img src={Password} alt="Password Icon" />
                                  </span>
                                  <input name="password" type="password" className="form-control" placeholder="Password*" value={formDataForLogin.password} onChange={handleLoginChange} />
                                </div>
                                <span className="loginerror" style={{ color: "red", fontSize: "small" }}>
                                  {loginError && loginError.password}
                                </span>
                              </div>
                              <div className="mb-3 d-flex justify-content-end fgt">
                                <a href="#" onClick={handleForgotPasswordClick} className="text-decoration-none">
                                  Forgot Password?
                                </a>
                              </div>

                              {emailVerificationStatus === "verified" && (
                                <div className="mail-verification-content">
                                  <h2>Email Verified</h2>
                                  <p>Your email has been verified successfully.</p>
                                </div>
                              )}
                              {emailVerificationStatus === "failed" && (
                                <div className="mail-verification-content">
                                  <h2>Email Verification Failed</h2>
                                  <p>There was an error verifying your email. Please try again.</p>
                                </div>
                              )}
                              {error.loginError && (
                                <div className="alert alert-danger" role="alert">
                                  {error.loginError}
                                </div>
                              )}
                              <button type="submit" className="btn login_btn">
                                Login
                              </button>
                              <p className="text-center or_login">Or Login with</p>
                            </form>
                          </div>
                        )}

                        {/* forgot-password-flow */}
                        {showForgotPassword && forgotPasswordStage === "sendOTP" && (
                          <div className="forgot-password-content">
                            <h2>Forgot Password</h2>
                            <p>Enter your registered email to get OTP</p>
                            <div className="mb-3 fpass">
                              <div className="input-group">
                                <span className="input-group-text bg_nol" id="basic-addon1">
                                  <img src={Message} alt="User Icon" />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email*"
                                  value={forgotPasswordEmail}
                                  onChange={handleEmailChange} // Use the modified handler
                                />
                              </div>
                            </div>
                            {error.forgotPasswordError && (
                              <div className="alert alert-danger" role="alert">
                                {error.forgotPasswordError}
                              </div>
                            )}
                            <button className="btn login_btn" onClick={handleForgotPasswordSubmit}>
                              Sent OTP
                            </button>
                            <p className="text-center or_login">
                              <a href="#" onClick={handleBackToLogin}>
                                Login
                              </a>{" "}
                              with password or
                            </p>
                          </div>
                        )}
                        {showForgotPassword && forgotPasswordStage === "enterOTP" && (
                          <div className="forgot-password-content">
                            <h2>Verify OTP</h2>
                            <p>Write One time password to change the password, One time password sent on mail. </p>
                            <div className="mb-3 fpass">
                              <div className="input-group">
                                <span className="input-group-text bg_nol" id="basic-addon1">
                                  <img src={Otp} alt="otp" />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="OTP*"
                                  value={otp}
                                  onChange={handleOtpChange} // Use the modified handler
                                />
                              </div>
                            </div>
                            {error.forgotPasswordError && (
                              <div className="alert alert-danger" role="alert">
                                {error.forgotPasswordError}
                              </div>
                            )}
                            <button className="btn login_btn" onClick={handleVerifyOTPSubmit}>
                              Verify
                            </button>
                            <p className="text-center or_login">
                              <a href="#" onClick={handleBackToLogin}>
                                Login
                              </a>{" "}
                              with password or
                            </p>
                          </div>
                        )}
                        {showForgotPassword && forgotPasswordStage === "changePassword" && (
                          <div className="forgot-password-content">
                            <h2>Change Password</h2>
                            <p>Make sure to use a strong password. </p>
                            <div className="mb-3 fpass">
                              <div className="input-group">
                                <span className="input-group-text bg_nol" id="basic-addon2">
                                  <img src={Password} alt="Password Icon" />
                                </span>
                                <input type="password" className="form-control" placeholder="New Password**" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text bg_nol" id="basic-addon3">
                                  <img src={Password} alt="Password Icon" />
                                </span>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirm Password**"
                                  value={confirmNewPassword}
                                  onChange={handleConfirmNewPasswordChange} // Use the modified handler
                                />
                              </div>
                            </div>
                            {error.forgotPasswordError && (
                              <div className="alert alert-danger" role="alert">
                                {error.forgotPasswordError}
                              </div>
                            )}
                            <button className="btn login_btn" onClick={handleResetPasswordSubmit}>
                              Save
                            </button>
                            <p className="text-center or_login">
                              <a href="#" onClick={handleBackToLogin}>
                                Login
                              </a>{" "}
                              with password or
                            </p>
                          </div>
                        )}
                        {showForgotPassword && forgotPasswordStage === "passwordChanged" && (
                          <div className="forgot-password-content">
                            <h2>Password Changed Successfully!</h2>
                            <div className="successful">
                              {" "}
                              <img src={Success} alt="Password Icon" />
                            </div>
                            <p className="scful">
                              You can now{" "}
                              <a href="#" onClick={handleBackToLogin}>
                                Login
                              </a>{" "}
                              with your new password.
                            </p>
                          </div>
                        )}
                        {/* forgot-password-flow */}
                        {activeTab === "signup" && (
                          <form onSubmit={handleSignup}>
                            <div className="tab-content">
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text bg_nol" id="basic-addon4">
                                    <img src={Username} alt="User Icon" />
                                  </span>
                                  <input type="text" name="name" className="form-control" placeholder="Name*" value={formData.name} onChange={handleChange} />
                                </div>
                                <span className="loginerror" style={{ color: "red", fontSize: "small" }}>
                                  {signupError.name}
                                </span>
                              </div>
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text bg_nol" id="basic-addon5">
                                    <img src={Message} alt="User Icon" />
                                  </span>
                                  <input name="email" type="email" className="form-control" placeholder="Email*" value={formData.email} onChange={handleChange} />
                                </div>
                                <span className="loginerror" style={{ color: "red", fontSize: "small" }}>
                                  {signupError.email}
                                </span>
                              </div>
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text bg_nol" id="basic-addon6">
                                    <img src={Phones} alt="User Icon" />
                                  </span>
                                  <input name="phone" type="text" className="form-control" placeholder="Phone*" value={formData.phone} onChange={handleChange} />
                                </div>
                                <span className="loginerror" style={{ color: "red", fontSize: "small" }}>
                                  {signupError.phone}
                                </span>
                              </div>
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text bg_nol" id="basic-addon7">
                                    <img src={Password} alt="Password Icon" />
                                  </span>
                                  <input name="password" type="password" className="form-control" placeholder="Password*" value={formData.password} onChange={handleChange} />
                                </div>
                                <span className="loginerror" style={{ color: "red", fontSize: "small" }}>
                                  {signupError.password}
                                </span>
                              </div>
                              {error.signupError && (
                                <div className="alert alert-danger" role="alert">
                                  {error.signupError}
                                </div>
                              )}
                              <button className="btn login_btn">Signup</button>
                              <p className="text-center or_login">Or connect with</p>
                            </div>
                          </form>
                        )}
                        <div className="justify-content-between target_urls">
                          <div className="sbtn">
                            <button className="custom-btn" onClick={handleFacebookLogin}>
                              <img src={Facebook} alt="Facebook" /> Continue with Facebook
                            </button>
                          </div>
                          <div className="sbtn">
                            <button className="custom-btn" onClick={handleLinkedInLogin}>
                              <img src={LinkedIn} alt="LinkedIn" /> Continue with LinkedIn
                            </button>
                          </div>
                          <div className="sbtn">
                            <button className="custom-btn" onClick={handleTwitterLogin}>
                              <img src={Twitter} alt="twiteter" /> Continue with Twitter
                            </button>
                          </div>
                          <div className="sbtn">
                            <button className="custom-btn" onClick={loginWithGoogle}>
                              <img src={Google} alt="Google Plus" /> Continue with Google
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="right_modal">
                    <h2>Let's Work Together!</h2>
                    <p>By registering on the site you will have full access to up-to-date and accurate MLS listing information. Save the searches you perform or save your favorite listings so you can be notified when new listings hit the market or a listing price changes!</p>
                    <p>Looking for market information so you can get the best value for your property? Check out our FREE market report tool which will provide immediate market details using MLS data.</p>
                    <p>Your contact information is never shared or distributed.</p>
                    <div className="foot_info">
                      By signing up you agree to our <a>Terms and Privacy Policy</a>
                      Protected by reCAPTCHA. <a>Privacy</a> • <a>Terms</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default LoginProcessModal;
