import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ViewByLocation.css";
import axios from "axios";
import Url from "../../Element/Api";
import { useSelectedOptions } from "../../SelectedOptionsContext";

function ViewByLocation() {
  const Domain = window.location.hostname;
  const [arealist, setArealist] = useState([]);
  const { setselectedSearchvalue, setSearchValue } = useSelectedOptions();
  const { setSelectedBudget } = useSelectedOptions({ minPrice: "", maxPrice: "" });

  //Function to fetch city list
  useEffect(() => {
    const fetchCityList = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/api/mls/reso/cities-list?domain=${Domain}&limit=4`);
        setArealist(response.data.uniqueValues);
      } catch (error) {
        console.error("Error fetching city list:", error);
      }
    };

    fetchCityList();
  }, []);

  //Function to save the city and price on state
  const handleLinkClick = (minPrice, maxPrice, city) => {
    setSearchValue(city);
    setselectedSearchvalue(city);
    setSelectedBudget({ minPrice, maxPrice });
  };

  return (
    <div className="overall_location">
      <div className="container">
        <div className="viewbylocatioon">
          <h2>View by Locations</h2>
          <Link className="all_locations" to="/search-by-area">
            View All Locations
          </Link>

          <div className="row">
            <div className="col-lg-12">
              <div className="location_prop_listing d-flex">
                {arealist.slice(0, 4).map((city, index) => (
                  <div key={index} className="single_prop_listing">
                    <img src={`/assets/properties/l${(index % 4) + 1}.png`} className="img-fluid" alt={`property list ${index}`} />
                    <button type="button" className="prop_btn">
                      {city}
                    </button>
                    <div className="overlay">
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(1, 300000, city)}>
                          Under $ 300,000
                        </Link>
                      </p>
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(200000, 300000, city)}>
                          $ 200,000 to 300,000
                        </Link>
                      </p>
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(300000, 400000, city)}>
                          $ 300,000 to 400,000
                        </Link>
                      </p>
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(400000, 500000, city)}>
                          $ 400,000 to 500,000
                        </Link>
                      </p>
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(500000, 600000, city)}>
                          $ 500,000 to 600,000
                        </Link>
                      </p>
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(700000, 800000, city)}>
                          $ 700,000 to 800,000
                        </Link>
                      </p>
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(900000, 1000000, city)}>
                          $ 900,000 to 100,000
                        </Link>
                      </p>
                      <p>
                        <Link to="/search-result" onClick={() => handleLinkClick(1000000, Infinity, city)}>
                          Luxury Homes
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewByLocation;
