import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import Url from "../Element/Api";
import Loader from "../Element/Loader";

function AllBlogs() {
  const blogsPerPage = 12;
  const Domain = window.location.hostname;
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const apiUrl = `${Url.BASEURL}/api/user/broker-blogs?domain=${Domain}`;
  const indexOfLastBlog = (currentPage + 1) * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  //Function for getting all blogs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}&page=${currentPage + 1}`);
        const data = await response.json();
        if (data && data.blogs && Array.isArray(data.blogs)) {
          setBlogs(data.blogs);
          setTotalPages(data.totalPages);
        } else {
          console.error("Error: Invalid data structure", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [currentPage]);

  //Function to update page
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (
    <div className="all_agents blogs_all">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1>Blogs</h1>
            {loading && <Loader />}
            <div className="no-blogs-message">{blogs.length === 0 && !loading && <p>No blogs available</p>}</div>

            <div className="d-flex search_count blogs">
              <div className="Second_coint">{blogs.length > 0 && <ReactPaginate pageCount={totalPages} pageRangeDisplayed={5} marginPagesDisplayed={1} onPageChange={handlePageClick} breakClassName="page-item" breakLinkClassName="page-link" containerClassName="paginationn" activeClassName="active" breakLabel="..." previousLabel={<GoChevronLeft />} nextLabel={<GoChevronRight />} previousLinkClassName="custom-button" nextLinkClassName="custom-button" />}</div>
              {blogs.length > 0 && (
                <div className="agents_count">
                  <span className="ml">
                    {indexOfFirstBlog + 1}-{Math.min(indexOfLastBlog, blogs.length)}
                  </span>
                  of <span>{blogs.length}</span> Results
                </div>
              )}
            </div>

            <div className="blog_posts d-flex">
              {currentBlogs.map((blog) => (
                <Link to={`/single-blog/${blog.slug}`} key={blog.slug} className="single_blog_block">
                  <div className="blog-image-container">
                    <img src={`https://oregonhomeseeker.com:3001/uploads/${blog.image}`} className="img-fluid" alt="Blog" />
                  </div>

                  <h2>{blog.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${blog.description.split(" ").slice(0, 31).join(" ")}...`,
                    }}
                  />
                </Link>
              ))}
            </div>

            {blogs.length > 0 && <ReactPaginate pageCount={totalPages} pageRangeDisplayed={5} marginPagesDisplayed={1} onPageChange={handlePageClick} breakClassName="page-item" breakLinkClassName="page-link" containerClassName="paginationn" activeClassName="active" breakLabel="..." previousLabel={<GoChevronLeft />} nextLabel={<GoChevronRight />} previousLinkClassName="custom-button" nextLinkClassName="custom-button" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllBlogs;
