import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Url from "../components/Element/Api";

function MetaTags() {
  const [metaTags, setMetaTags] = useState({});
  const Domain = window.location.hostname;

  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/api/user/broker-metatags?domain=${Domain}`);
        const { metatags } = response.data;
        if (metatags && metatags.length > 0) {
          setMetaTags(metatags[0]);
        }
      } catch (error) {
        console.error("Error fetching meta tags:", error);
      }
    };

    fetchMetaTags();
  }, []);

  return (
    <Helmet>
      <title>{metaTags.title || "Wovnn"}</title>
      <meta name="description" content={metaTags.metaDescription || ""} />
      <meta name="keywords" content={metaTags.metaKeywords || ""} />
    </Helmet>
  );
}

export default MetaTags;
