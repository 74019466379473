import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Navigation.css";
import Url from "../Element/Api";
import Icon1 from "../../public/u1.svg";
import Icon2 from "../../public/u2.svg";
import Icon3 from "../../public/u3.svg";
import ProfileModal from "./ProfileModal";
import LoginProcessModal from "./LoginProcessModal";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../Redux/Action/action";
import UserImage from "../../public/assets/blogs/t2.jpeg";
import { updateBrokerInfo } from "../Redux/Action/action";
import Logo from "../../public/assets/white-default-logo.svg";
import { useSelectedOptions } from "../SelectedOptionsContext";
import AdvancedSearch from "../AboutusPages/AdvancedSearch/AdvancedSearch";
import DreamHomeFinder from "../AboutusPages/DreamHomeFinder/DreamHomeFinder";
import FreeMarketAnalysis from "../AboutusPages/FreeMarketAnalysis/FreeMarketAnalysis";

function Navigation() {
  const Domain = window.location.hostname;
  const dispatch = useDispatch();
  const location = useLocation();
  const isHomepage = location.pathname === "/";
  const accessToken = useSelector((state) => state.jwtuser);
  const brokerInfo = useSelector((state) => state.brokerInfo);
  const [metaTagname, setmetaTagname] = useState(null);
  const [userImage, setUserImage] = useState(UserImage);
  const [dataFetched, setDataFetched] = useState(false);
  const { Brandings, setBrandings } = useSelectedOptions();
  const { showredirectloginModal } = useSelectedOptions(false);
  const [showAdvancedSearchModal, setShowAdvancedSearchModal] = useState(false);
  const [showDreamHomeFinderModal, setShowDreamHomeFinderModal] = useState(false);
  const [showFreeMarketAnalysisModal, setShowFreeMarketAnalysisModal] = useState(false);
  const { setSearchValue, setselectedSearchvalue, setselectedType, setSelectedFeatures, setSelectedBudget, setSearchValue1, setSearchValue2, setSearchValue3, setSelectedSuggestion1, setSelectedSuggestion2, setSelectedSuggestion3, setselectednearsearchresult1, setselectednearsearchresult2, setselectednearsearchresult3, setnearsearchresult1, setnearsearchresult2, setnearsearchresult3 } = useSelectedOptions();

  const handleImageChange = (image) => {
    setUserImage(image);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/broker-brandings?domain=${Domain}`, {
          method: "GET",
        });

        const data = await response.json();
        dispatch(updateBrokerInfo({ agentType: data.brokerData.agentType }));
        dispatch(updateBrokerInfo({ companyName: data.brokerData.companyName }));
        dispatch(updateBrokerInfo({ bio: data.brokerData.bio }));
        setBrandings(data.brandings);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setDataFetched(true);
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    dispatch(updateUserInfo({ image: "" }));
    localStorage.removeItem("accessToken");
    dispatch(updateUserInfo({ accessToken: "", _id: "" }));
    window.location.reload();
  };

  useEffect(() => {
    if (showredirectloginModal) {
      const targetElement = document.querySelector('[data-bs-target="#loginModal"]');
      if (targetElement) {
        targetElement.click();
      }
    }
  }, [showredirectloginModal]);

  const handleImageClick = () => {
    setSearchValue("");
    setselectedSearchvalue("");
    setselectedType([]);
    setSelectedFeatures([]);
    setSelectedBudget({
      minPrice: "",
      maxPrice: "",
      minMonthly: "",
      maxMonthly: "",
    });
    setSearchValue1("");
    setSearchValue2("");
    setSearchValue3("");
    setSelectedSuggestion1(null);
    setSelectedSuggestion2(null);
    setSelectedSuggestion3(null);
    setselectednearsearchresult1([]);
    setselectednearsearchresult2([]);
    setselectednearsearchresult3([]);
    setnearsearchresult1([]);
    setnearsearchresult2([]);
    setnearsearchresult3([]);
  };

  return (
    <div className={`overall_nav ${isHomepage ? "homepage" : "otherpage"}`}>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light custom_navi ">
          {dataFetched && (
            <div className="brand_set">
              {Brandings && Brandings.websiteLogo ? (
                <Link to={Brandings.logoLink} className="navbar-brand">
                  <img src={`https://oregonhomeseeker.com:3001/uploads/${Brandings.websiteLogo}`} alt={Brandings.logoAlternateText} onClick={handleImageClick} />
                </Link>
              ) : (
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt="Default Logo" onClick={handleImageClick} />
                </Link>
              )}
            </div>
          )}

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown">
                  Search
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/foreclosure">
                    Foreclosure
                  </Link>
                  <Link className="dropdown-item" to="/open-houses">
                    Open House
                  </Link>
                  <Link className="dropdown-item" to="/featured-Listing">
                    Featured Listing
                  </Link>
                  <Link className="dropdown-item" to="/map-search">
                    Map Search
                  </Link>
                  <Link className="dropdown-item" to="/search-by-area">
                    Search By Area
                  </Link>
                  <a className="dropdown-item" href="#" onClick={() => setShowAdvancedSearchModal(true)}>
                    Advanced Search
                  </a>
                </div>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown">
                  Resources
                </a>
                <div className="dropdown-menu">
                  {metaTagname &&
                    metaTagname.map((metaTag, index) => (
                      <Link key={index} to={`/blogs/${metaTag.name}`} className="dropdown-item">
                        {metaTag.name}
                      </Link>
                    ))}
                  <a href="#" className="dropdown-item">
                    Schools
                  </a>
                  <a href="#" className="dropdown-item">
                    Relocations
                  </a>
                  <a href="#" className="dropdown-item">
                    Weather
                  </a>
                  <a href="#" className="dropdown-item">
                    Real Estate Glossary
                  </a>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-bs-toggle="dropdown">
                  About
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/about-us">
                    About Us
                  </Link>
                  {brokerInfo.agentType === 1 && (
                    <Link className="dropdown-item" to="/agents">
                      Our Agents
                    </Link>
                  )}

                  <Link className="dropdown-item" to="/blogs">
                    Our Blogs
                  </Link>
                  <a className="dropdown-item" href="#" onClick={() => setShowFreeMarketAnalysisModal(true)}>
                    Free Market Analysis
                  </a>
                  <a className="dropdown-item" href="#" onClick={() => setShowDreamHomeFinderModal(true)}>
                    Dream Home Finder
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  Contact Us
                </Link>
              </li>

              {accessToken.accessToken ? (
                <>
                  <li className="nav-item dropdown login-dropper">
                    {accessToken.image ? (
                      <Link className="nav-link dropdown-toggle" id="userDropdown" role="button" data-bs-toggle="dropdown">
                        <img src={accessToken.image} alt="user1" className="user_blocker" />
                      </Link>
                    ) : (
                      <Link className="nav-link dropdown-toggle" id="userDropdown" role="button" data-bs-toggle="dropdown">
                        <img src={userImage} alt="user1" className="user_blocker" />
                      </Link>
                    )}
                    <div className="dropdown-menu custom_log_droping" aria-labelledby="userDropdown">
                      <Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#ProfileModal">
                        <img src={Icon2} alt="Icon1" /> Profile
                      </Link>
                      <Link to="/wishlists" className="dropdown-item">
                        <img src={Icon1} alt="Icon2" /> Wish List
                      </Link>
                      <p className="last_drop_item"></p>
                      <Link className="dropdown-item" onClick={handleLogout}>
                        <img src={Icon3} alt="Icon3" /> Logout
                      </Link>
                    </div>
                    <ProfileModal onImageChange={handleImageChange} />
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item logins">
                    <span className="nav-link cur" data-bs-toggle="modal" data-bs-target="#loginModal">
                      <img src="/assets/login.png" alt="login" />
                    </span>
                    <LoginProcessModal />
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
      {/* {showredirectloginModal && <LoginProcessModal />} */}
      <FreeMarketAnalysis showModal={showFreeMarketAnalysisModal} setShowModal={setShowFreeMarketAnalysisModal} />
      <DreamHomeFinder showModal={showDreamHomeFinderModal} setShowModal={setShowDreamHomeFinderModal} />
      <AdvancedSearch showModal={showAdvancedSearchModal} setShowModal={setShowAdvancedSearchModal} />
    </div>
  );
}
export default Navigation;
