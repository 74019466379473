import React, { useState, useRef, useEffect } from "react";
import "./Navigation.css";
import axios from "axios";
import Cross from "../../public/assets/cross.svg";
import UserImage from "../../public/assets/blogs/t2.jpeg";
import Edit from "../../public/assets/edit.svg";
import User from "../../public/assets/a1.svg";
import Password from "../../public/assets/a2.svg";
import Mail from "../../public/assets/a3.svg";
import call from "../../public/assets/call.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../Redux/Action/action";
import Url from "../Element/Api";

function ProfileModal(props) {
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const Usertoken_id = useSelector((state) => state.jwtuser);
  const regtoken = Usertoken_id.accessToken;
  const [showModal, setShowModal] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(UserImage);
  const [showChangePasswordSection, setShowChangePasswordSection] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
    image: ",",
  });

  const showImage = imageFile ? URL.createObjectURL(imageFile) : userData.image || imagePreviewUrl;

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImageFile(file);
      setImagePreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleEditPicture = () => {
    fileInputRef.current.click();
  };

  const handleProfileButtonClick = () => {
    fetchUserProfile(true);
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${Url.BASEURL}/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${regtoken}`,
        },
      });

      const userProfileData = response.data;

      setUserData({
        name: userProfileData.profile.name || "",
        email: userProfileData.profile.email || "",
        mobile: userProfileData.profile.phone || "",
        image: userProfileData.profile.image || "",
      });
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const updateProfile = async (e) => {
    e.preventDefault();
    try {
      setUpdateInProgress(true);
      const formData = new FormData();
      formData.append("name", userData.name);
      formData.append("phone", userData.mobile);
      formData.append("image", imageFile);

      const response = await axios.post(`${Url.BASEURL}/api/user/update-profile`, formData, {
        headers: {
          Authorization: `Bearer ${regtoken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      window.location.reload();

      if (response.data.user && response.data.user.image) {
        dispatch(updateUserInfo({ image: response.data.user.image }));
        setShowModal(false);
      } else {
        console.error("image not found in response data");
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const toggleChangePasswordSection = (e) => {
    e.preventDefault();
    setShowChangePasswordSection(!showChangePasswordSection);
  };

  const modalcloser = (e) => {
    setShowChangePasswordSection(false);
  };
  const handleChangePassword = async () => {
    try {
      if (newPassword !== confirmNewPassword) {
        return;
      }
      const headers = {
        Authorization: `Bearer ${Usertoken_id.accessToken}`, // Assuming accessToken is your authentication token
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        `${Url.BASEURL}/api/user/change-password`,
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmNewPassword: confirmNewPassword,
        },
        { headers }
      );
      setShowChangePasswordSection(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {showModal && (
        <div className="modal fade" id="ProfileModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog custom-modal-width modal-dialog-centered">
            <div className="modal-content">
              <div className="modal_closer">
                <img src={Cross} alt="Close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => modalcloser(e)} />
              </div>
              <div className="modal-body">
                <div className="full_modal">
                  <div className="left_modal profile_entries">
                    {!showChangePasswordSection && (
                      <div className="first_entries">
                        <button className="tab-button" onClick={handleProfileButtonClick}>
                          Profile
                        </button>
                        <form>
                          <div className="profile_form_entries">
                            <div className="image_uploader">
                              <div className="image_upload_profile">
                                <img src={showImage} alt="user" />
                                <div className="image_edit_icon" onClick={handleEditPicture}>
                                  <img src={Edit} alt="Edit" />
                                </div>
                              </div>
                              <input type="file" ref={fileInputRef} onChange={handleImageChange} style={{ display: "none" }} />
                            </div>
                            <div className="forms_area">
                              <div className="single_fields">
                                <input type="text" className="form-control" placeholder="Name " value={userData.name} onChange={(e) => setUserData({ ...userData, name: e.target.value })} />
                                <div className="u_icon">
                                  {" "}
                                  <img src={Mail} alt="user" />
                                </div>
                              </div>
                              <div className="single_fields">
                                <input type="text" className="form-control" placeholder="Email" value={userData.email} readOnly />
                                <div className="u_icon">
                                  <img src={User} alt="user" />
                                </div>
                              </div>
                              <div className="single_fields">
                                <input type="number" className="form-control" placeholder="Mobile" value={userData.mobile} onChange={(e) => setUserData({ ...userData, mobile: e.target.value })} />
                                <div className="u_icon">
                                  <img src={call} alt="user" />
                                </div>
                              </div>
                              <button className="change_password" onClick={(e) => toggleChangePasswordSection(e)}>
                                Change Password?
                              </button>
                              <button className="logout" onClick={(e) => updateProfile(e)}>
                                {updateInProgress ? "Updating..." : "Update"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}

                    {showChangePasswordSection && (
                      <div className="state_change">
                        <button className="tab-button">Change Password</button>
                        <p className="make_sure">Make sure to use a strong password.</p>
                        <form>
                          <div className="profile_form_entries change_pass">
                            <div className="forms_area">
                              <div className="single_fields">
                                <input type="password" className="form-control" placeholder="Current password*" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                                <div className="u_icon">
                                  <img src={Password} alt="user" />
                                </div>
                              </div>
                              <div className="single_fields">
                                <input type="password" className="form-control" placeholder="New password*" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                <div className="u_icon">
                                  <img src={Password} alt="user" />
                                </div>
                              </div>
                              <div className="single_fields">
                                <input type="password" className="form-control" placeholder="Confirm new password*" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                <div className="u_icon">
                                  <img src={Password} alt="user" />
                                </div>
                              </div>
                              <button type="button" className="logout save_btn" onClick={handleChangePassword}>
                                Save
                              </button>
                              <div className="back_to_profile" onClick={(e) => toggleChangePasswordSection(e)}>
                                Back to Profile
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                  <div className="right_modal">
                    <h2>Let's Work Together!</h2>
                    <p>By registering on the site you will have full access to up-to-date and accurate MLS listing information. Save the searches you perform or save your favorite listings so you can be notified when new listings hit the market or a listing price changes!</p>
                    <p>Looking for market information so you can get the best value for your property? Check out our FREE market report tool which will provide immediate market details using MLS data.</p>
                    <p>Your contact information is never shared or distributed.</p>
                    <div className="foot_info">
                      By signing up you agree to our <a>Terms and Privacy Policy</a>
                      Protected by reCAPTCHA. <a>Privacy</a> • <a>Terms</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileModal;
