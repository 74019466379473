import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Changed import here
import React, { useEffect, useState, useRef } from 'react';
import { useSelectedOptions } from './components/SelectedOptionsContext';
import Navigation from './components/Navigation/Navigation';
import MobileNavigation from './components/Navigation/MobileNavigation';
import HomePage from './components/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import SearchView from './components/SearchPages/SearchView/SearchView';
import PropertyDetail from './components/PropertyDetail/PropertyDetail';
import AboutUs from './components/AboutusPages/AboutUs/AboutUs';
import AgentPage from './components/AboutusPages/AgentsPage/AgentPage';
import ContactUs from './components/ContactUs/ContactUs';
import SingleAgent from './components/AboutusPages/AgentsPage/SingleAgent';
import AllBlogs from './components/Blogs/AllBlogs';
import SingleBlog from './components/Blogs/SingleBlog';
import Agent from './components/AboutusPages/AgentsPage/SingleAgent';
import MetatagBlog from './components/Blogs/MetatagBlog';
import SearchByArea from './components/SearchPages/SearchByArea/SearchByArea';
import EmailVerificationsuccess from './components/Navigation/LoginProcess/Successfulverified';
import { useSelector } from 'react-redux';
import ProtectedRoute from './Auth/Protectedroute'
import Wishlists from './components/Wishlist/Wishlist';
import Metatag from './Auth/Metatag';
import Auth from './Auth/Auth';
import ScrollToTopOnRouteChange from './scroll'
import ForgotPassword from './components/Navigation/LoginProcess/ForgotPassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function getFaviconEl() {
  return document.getElementById('favicon');
}

function App() {
  const { Brandings } = useSelectedOptions();
  const userIdToken = useSelector((state) => state.jwtuser);

  //Function to update favicon
  useEffect(() => {
    const updateFavicon = () => {
      if (Brandings && Brandings.favicon) {
        const favicon = getFaviconEl();
        if (favicon) {
          favicon.href = 'https://oregonhomeseeker.com:3001/uploads/' + Brandings.favicon;
        } else {
          const newFavicon = document.createElement('link');
          newFavicon.rel = 'icon';
          newFavicon.href = 'https://oregonhomeseeker.com:3001/uploads/' + Brandings.favicon;
          document.head.appendChild(newFavicon);
        }
      } else {
        console.log('Brandings data or favicon not found.');
      }
    };
    updateFavicon();
  }, [Brandings]);

  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const topRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div className="app-container">
      <Router scrollRestoration={false}>{/* Changed BrowserRouter to Router here */}
        <ScrollToTopOnRouteChange />
        <div className="app-container" ref={topRef}>

          <Metatag />
          <Auth />
          <Navigation />
          <MobileNavigation />
          <Routes>
            
            <Route path="/reset-password/:id/:token" element={<ForgotPassword />} />
            <Route path="/" element={<HomePage />} />
            <Route path="search-result" element={<SearchView />} />
            <Route path="foreclosure" element={<SearchView />} />
            <Route path="featured-listing" element={<SearchView />} />
            <Route path="map-search" element={<SearchView />} />
            <Route path="open-houses" element={<SearchView />} />
            <Route path="search-by-area" element={<SearchByArea />} />
            <Route path="/property-detail/:id" element={<PropertyDetail />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="agents" element={<AgentPage />} />
            <Route path="single-agent" element={<SingleAgent />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="/single-blog/:slug" element={<SingleBlog />} />
            <Route path="blogs" element={<AllBlogs />} />
            <Route path="/agent/:name" element={<Agent />} />
            <Route path="/blogs/:name" element={<MetatagBlog />} />
            <Route element={<ProtectedRoute />}>
              <Route element={<Wishlists />} path="/wishlists" exact />
            </Route>
            <Route path="/email-verification/:userId/:token" element={<EmailVerificationsuccess />} />
          </Routes>
          <Footer />
        </div>
      </Router>
      {showScrollTopButton && (
        <button className="scroll-to-top-button" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}    </div>

  );
}

export default App;
