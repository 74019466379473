import React from "react";
import "./CtaTwo.css";
import cta2Image from "../../../public/assets/cta2.png";
import Rounds from "../../../public/assets/round.svg";

function CtaTwo() {
  return (
    <div className="container">
      <div className="cta-two">
        <div className="roundup_image">
          <img src={Rounds} alt="circles" />
        </div>
        <div className="cta-content">
          <div className="cta-left">
            <h2>Enter your Address for a Free Market Report</h2>
            <div className="input-group">
              <input type="text" placeholder="Enter your address for a market report" />
              <button>Submit</button>
            </div>
          </div>
          <div className="cta-right">
            <img src={cta2Image} alt="cta2" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CtaTwo;
