import React from "react";
import "./FreeMarketAnalysis.css";
import Modal1 from "../../../public/assets/modal1.svg";
import Cross from "../../../public/assets/cross.svg";
import PropertySidebarForm from "../../PropertySidebarForm/PropertySidebarForm";

function FreeMarketAnalysis({ showModal, setShowModal }) {
  return (
    <div className="free_market_analysis mutual_popuus">
      <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal_closer">
              <img src={Cross} alt="close Icon" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body">
              <div className="overall_mutual_modal coller">
                <div className="left_mutual_modal">
                  <PropertySidebarForm />
                </div>
                <div className="right_mutual_modal">
                  <h1>Free Market Analysis</h1>
                  <p>Please complete the following information for a free comparative market analysis of your home.</p>
                  <img src={Modal1} alt="Modal" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Backdrop for modal */}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
}

export default FreeMarketAnalysis;
