
// actions.js
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const updateUserInfo = (data) => ({
  type: UPDATE_USER_INFO,
  payload: data,
});
export const UPDATE_PROPERTY_TYPES = 'UPDATE_PROPERTY_TYPES';
export const updatePropertyTypes = (propertyTypes) => ({
  type: UPDATE_PROPERTY_TYPES,
  payload: propertyTypes,
});
export const UPDATE_REALTOR_INFO = 'UPDATE_REALTOR_INFO ';
export const updateRealtorInfo= (realtorInfo) => ({
  type: UPDATE_REALTOR_INFO ,
  payload: realtorInfo,
});
export const UPDATE_BROKER_INFO = 'UPDATE_REALTOR_INFO ';
export const updateBrokerInfo= (data) => ({
  type: UPDATE_BROKER_INFO ,
  payload: data,
});
