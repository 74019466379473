import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./JustListed.css";
import axios from "axios";
import Url from "../../Element/Api";

function JustListed() {
  const Domain = window.location.hostname;
  const [properties, setProperties] = useState([]);

  //Function for fetching just listed properties
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/api/mls/reso/search-result?page=1&size=6`, {
          params: {
            domain: Domain,
            page: 1,
            status: "Active",
            limit: 5,
          },
        });

        const { docs } = response.data.properties;
        if (docs && Array.isArray(docs)) {
          setProperties(docs);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container just_listed">
      <div className="row">
        <div className="col-md-12">
          <h2 className="recent_listed text-center">Just Listed Homes for Sale , OR</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Link to="/search-by-area">
            <button className="all_locations border-0">View All Locations</button>
          </Link>
        </div>
      </div>
      <div className="row">
        {properties.map((property) => (
          <div key={property._id} className="col-md-6">
            <Link to={`/property-detail/${property.ListingKey}`}>
              <div className="property-card d-flex">
                <div className="left_property_image">
                  <img src={property.Photo1URL} alt="Wovnn properties" />
                </div>
                <div className="bottom_property">
                  {/* <h2>{property.PropertySubType}</h2> */}
                  <div className="location_flex d-flex">
                    <div className="location_icon">
                      <img src="/assets/blue-location.png" alt="Wovnn location-icon" />
                    </div>
                    <div className="location_name">{property.UnparsedAddress}</div>
                  </div>
                  <div className="amenities d-flex custom_properties">
                    <div className="single_amenities d-flex">
                      <div className="left_amenities">
                        <img src="/assets/amenties2.png" alt="Wovnn amenities-icon" />
                      </div>
                      <div className="right_amenities">{property.BedroomsTotal !== null && String(property.BedroomsTotal)}</div>
                    </div>
                    <div className="single_amenities d-flex">
                      <div className="left_amenities">
                        <img src="/assets/amenties1.png" alt="Wovnn amenities-icon" />
                      </div>
                      <div className="right_amenities">{property.BathroomsTotalInteger !== null && String(property.BathroomsTotalInteger)}</div>
                    </div>
                    <div className="single_amenities d-flex">
                      <div className="left_amenities">
                        <img src="/assets/amenties3.png" alt="Wovnn amenities-icon" />
                      </div>
                      <div className="right_amenities">
                        {property.BuildingAreaTotal && typeof property.BuildingAreaTotal === "object" ? property.BuildingAreaTotal.$numberDecimal : property.BuildingAreaTotal} <span className="area">sq ft</span>
                      </div>
                    </div>
                  </div>
                  <div className="price-amenities">
                    <div className="price">
                      <span className="price-sign">$</span> {property.CurrentPriceForStatus.$numberDecimal}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JustListed;
