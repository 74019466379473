import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../AboutusPages/AboutUsPages.css";
import Arrow from "../../../public/assets/arrows.svg";
import ClipLoader from "react-spinners/ClipLoader";
import Url from "../../Element/Api";
import { useDispatch } from "react-redux";
import { updateRealtorInfo } from "../../Redux/Action/action";

function AgentPage() {
  const dispatch = useDispatch();
  const Domain = window.location.hostname;
  const [searchTerm, setSearchTerm] = useState("");
  const [realtors, setRealtors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const indexOfLastRealtor = currentPage * 10;
  const indexOfFirstRealtor = indexOfLastRealtor - 10;
  const filteredRealtors = realtors.filter((realtor) => (realtor.firstname + " " + realtor.lastname).toLowerCase().includes(searchTerm.toLowerCase()));
  const currentRealtors = filteredRealtors.slice(indexOfFirstRealtor, indexOfLastRealtor);

  //Function for fetching realtors list for the broker of the webiste
  useEffect(() => {
    const fetchRealtors = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/getRealtors?domain=${Domain}&page=${currentPage}&searchQuery=${searchTerm}`);
        if (!response.ok) {
          throw new Error("Failed to fetch realtors");
        }
        const data = await response.json();
        setRealtors(data.realtors);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching realtors:", error);
      }
      setLoading(false);
    };

    fetchRealtors();
  }, [currentPage]);

  //Function for getting realtor data from search
  useEffect(() => {
    const fetchsearchedquery = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/getRealtors?domain=${Domain}&page=${currentPage}&searchQuery=${searchTerm}`);
        if (!response.ok) {
          throw new Error("Failed to fetch realtors");
        }
        const data = await response.json();
        setRealtors(data.realtors);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching realtors:", error);
      }
    };

    fetchsearchedquery();
  }, [searchTerm, currentPage]);

  //Function for updating the searched value in state
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  //Function for dispatching realtor id to redux
  const handleRealtorClick = (realtorId) => {
    dispatch(updateRealtorInfo(realtorId));
  };

  return (
    <div className="agent_list all_agents">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1>Meet Our Realtors</h1>
            <div className="d-flex search_count">
              <div className="agent_search">
                <input type="search" className="form-control" placeholder="Search Agent by Name" value={searchTerm} onChange={handleSearchChange} />
              </div>
              {filteredRealtors.length > 0 && (
                <div className="agents_count">
                  <span className="ml">
                    {indexOfFirstRealtor + 1}-{Math.min(indexOfLastRealtor, filteredRealtors.length)}
                  </span>
                  of <span>{filteredRealtors.length}</span> Results
                </div>
              )}
            </div>

            {loading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" />
              </div>
            ) : (
              <div>
                {currentRealtors.length > 0 ? (
                  <div className="overall_agents d-flex">
                    {currentRealtors.map((realtor) => (
                      <div className="agent_one" key={realtor._id} onClick={() => handleRealtorClick(realtor._id)}>
                        <Link to={`/agent/${realtor.firstname.toLowerCase() + "-" + realtor.lastname.toLowerCase()}`}>
                          <img src={realtor.image} className="img-fluid" alt="agents" />
                          <h3>
                            {realtor.firstname} {realtor.lastname}
                          </h3>
                          <h4>{realtor.profileTagline}</h4>
                          <p>{realtor.metaDescription}</p>
                          <div className="view_agent">View Profile</div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no_realtor">No realtors available.</div>
                )}
                {filteredRealtors.length > 0 && (
                  <div className="pagination">
                    <button className={`left_awo ${currentPage <= 1 ? "disabled-btn" : ""}`} disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}>
                      <img src={Arrow} alt="Left Arrow" />
                    </button>
                    {[...Array(totalPages)].map((_, i) => (
                      <button key={i} onClick={() => setCurrentPage(i + 1)} className={currentPage === i + 1 ? "active" : ""}>
                        {i + 1}
                      </button>
                    ))}
                    <button className={`${currentPage >= totalPages ? "disabled-btn" : ""}`} disabled={currentPage >= totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
                      <img src={Arrow} alt="Right Arrow" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentPage;
