import React from "react";
import "./DreamHomeFinder.css";
import "../../AboutusPages/AboutUsPages.css";
import PropertySidebarForm from "../../PropertySidebarForm/PropertySidebarForm";
import Modal2 from "../../../public/assets/modal2.svg";
import Cross from "../../../public/assets/cross.svg";

function DreamHomeFinder({ showModal, setShowModal }) {
  return (
    <div className="free_market_analysis mutual_popuus">
      <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal_closer">
              <img src={Cross} alt="close Icon" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body">
              <div className="overall_mutual_modal">
                <div className="left_mutual_modal">
                  <PropertySidebarForm />
                </div>
                <div className="right_mutual_modal">
                  <h1>Dream Home Finder</h1>
                  <p>For detailed information on buying a home or property, please complete the form below describing the type of home or property you are looking for. Someone will get back to you as quickly as possible with your results!</p>
                  <img src={Modal2} alt="img" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Backdrop for modal */}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
}

export default DreamHomeFinder;
