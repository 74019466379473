import React, { useState } from "react";
import Password from "../../../public/assets/login1.svg";
import axios from "axios";
import { useParams } from "react-router-dom";

function ForgotPassword() {
  const { id, token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSavePasswordClick = async (password) => {
    try {
      const response = await axios.post(`http://localhost:3001/api/user/forgot-password-verify/${id}/${token}`, {
        password: password,
        confirmPassword: confirmPassword,
      });
    } catch (error) {
      console.error("Password reset failed:", error.response.data);
    }
  };

  const handleSavePasswordClick = () => {
    if (!password || !confirmPassword) {
      alert("Please enter password and confirm password");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    onSavePasswordClick(password);
  };

  return (
    <div>
      <h2>Change Password</h2>
      <p>Make sure to use a strong password.</p>

      <div className="mb-3 fpass">
        <div className="input-group">
          <span className="input-group-text bg_nol" id="basic-addon2">
            <img src={Password} alt="Password Icon" />
          </span>
          <input type="password" className="form-control" placeholder="Write password**" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text bg_nol" id="basic-addon3">
            <img src={Password} alt="Password Icon" />
          </span>
          <input type="password" className="form-control" placeholder="Write again**" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
      </div>
      <button className="btn login_btn" onClick={handleSavePasswordClick}>
        Save
      </button>
    </div>
  );
}

export default ForgotPassword;
