import React, { useState, useEffect } from "react";
import ReactSlider from "react-slider";
import "./MonthlyPaymentCalculator.css";
import Icon1 from "../../public/cal1.png";
import Icon2 from "../../public/cal2.png";
import Icon3 from "../../public/cal3.png";
import Icon4 from "../../public/cal4.png";

function MonthlyPaymentCalculator(props) {
  const { currentPriceForStatus } = props;
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(5);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [propertyCost, setPropertyCost] = useState(100000);
  const [downPaymentAmount, setDownPaymentAmount] = useState(10000);

  //Function for updating property cost on state
  useEffect(() => {
    setPropertyCost(currentPriceForStatus);
  }, [currentPriceForStatus]);

  //Function forcalculation of monthly payment
  useEffect(() => {
    if (interestRate && loanTerm && propertyCost && downPaymentAmount) {
      const monthlyInterestRate = interestRate / 12 / 100;
      const n = loanTerm * 12;
      const principal = propertyCost - downPaymentAmount;
      const payment = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -n));
      setMonthlyPayment(payment);
    }
  }, [interestRate, loanTerm, propertyCost, downPaymentAmount]);

  useEffect(() => {
    if (downPaymentAmount > propertyCost) {
      setDownPaymentAmount(propertyCost);
    }
  }, [downPaymentAmount, propertyCost]);

  return (
    <div className="calculator-wrapper">
      <h2 className="features_all">Estimated Monthly Payment</h2>
      <div className="calculation_zone">
        <div className="top_calc">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-md-12">
              <label htmlFor="interestRate">Interest Rate, %</label>
              <input
                type="text"
                className="form-control"
                id="interestRate"
                value={interestRate.toString()}
                onChange={(e) => {
                  let value = e.target.value.trim() !== "" ? parseFloat(e.target.value) : 0;
                  value = Math.min(Math.max(value, 0), 30);
                  setInterestRate(value);
                }}
              />

              <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={1} max={30} step={0.1} value={interestRate} onChange={(value) => setInterestRate(value)} />
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 ppt">
              <label htmlFor="loanTerm">Loan Term, Years</label>
              <input
                type="text"
                className="form-control"
                id="loanTerm"
                value={loanTerm}
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  value = isNaN(value) ? 0 : Math.min(value, 30);
                  setLoanTerm(value);
                }}
              />

              <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={1} max={30} step={1} value={loanTerm} onChange={(value) => setLoanTerm(value)} />
            </div>
          </div>
          <div className="row hmtp ">
            <div className="col-lg-6 col-sm-12 col-md-12">
              <label htmlFor="propertyCost">Property Cost</label>

              <input
                type="text"
                className="form-control"
                id="propertyCost"
                value={currentPriceForStatus}
                onChange={(e) => {
                  const value = e.target.value.trim() !== "" ? parseFloat(e.target.value) : 0;
                  setPropertyCost(value);
                }}
              />

              <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={1} max={1000000} step={1000} value={propertyCost} />
            </div>

            <div className="col-lg-6 col-sm-12 col-md-12 dpay">
              <label htmlFor="downPayment">Down Payment</label>
              <input
                type="text"
                className="form-control"
                id="downPayment"
                value={downPaymentAmount}
                onChange={(e) => {
                  const value = e.target.value.trim() !== "" ? parseFloat(e.target.value) : 0;
                  setDownPaymentAmount(value);
                }}
              />
              <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={1} max={propertyCost} step={1000} value={downPaymentAmount} onChange={(value) => setDownPaymentAmount(value)} />
            </div>
          </div>
        </div>

        <div className="bottom_calc">
          <div className="row mt-4">
            <div className="col-lg-5 offset-lg-1">
              <div className="d-flex align-items-center tt">
                <span className="icon-placeholder mr-2">
                  <img src={Icon1} alt="calc1" />
                </span>
                <div>
                  <div className="font-weight-bold">{interestRate.toFixed(2)}%</div>
                  <small>Interest Rate</small>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="d-flex align-items-center tt">
                <span className="icon-placeholder mr-2">
                  <img src={Icon2} alt="calc2" />
                </span>
                <div>
                  <div className="font-weight-bold">${(propertyCost - downPaymentAmount).toFixed(2)}</div>

                  <small>Loan Amount</small>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-6">
            <div className="col-lg-5 offset-lg-1">
              <div className="d-flex align-items-center tt huf">
                <span className="icon-placeholder mr-2">
                  <img src={Icon3} alt="calc3" />
                </span>
                <div>
                  <div className="font-weight-bold">{loanTerm}</div>
                  <small>Period (Years)</small>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="d-flex align-items-center tt">
                <span className="icon-placeholder mr-2">
                  <img src={Icon4} alt="calc4" />
                </span>
                <div>
                  <div className="font-weight-bold">${monthlyPayment.toFixed(2)}</div>
                  <small>Monthly Payment</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlyPaymentCalculator;
