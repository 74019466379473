import React, { useState, useEffect } from "react";
import "./SearchForm.css";
import axios from "axios";
import Url from "../../../Element/Api";
import star from "../../../../public/star.svg";
import SearchImage1 from "../../../../public/assets/s1.svg";
import { useSelectedOptions } from "../../../SelectedOptionsContext";
import BudgetDropdown2 from "../../../ReusableComponents2/BudgetDropdown/BudgetDropdown";
import PropertyTypeDropdown2 from "../../../ReusableComponents2/PropertyTypeDropdown/PropertyTypeDropdown";
import PropertyFeaturesDropdown2 from "../../../ReusableComponents2/PropertyFeaturesDropdown/PropertyFeaturesDropdown";
import LifeStyleSearchDropdown2 from "../../../ReusableComponents2/LifestyleSearchDropdown/LifeStyleSearchDropdown";

function SearchForm() {
  const [isSticky, setSticky] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { selectedSearchvalue, setselectedSearchvalue, searchValue, setSearchValue, searchValue1, setSearchValue1, searchValue2, setSearchValue2, searchValue3, setSearchValue3, nearsearchresult1, setnearsearchresult1, selectednearsearchresult1, setselectednearsearchresult1, selectednearsearchresult2, setselectednearsearchresult2, selectednearsearchresult3, setselectednearsearchresult3, nearsearchresult2, setnearsearchresult2, nearsearchresult3, setnearsearchresult3, isOpen, setIsOpen, isTriggeredsearch, setisTriggeredsearch, isTriggeredisochrone, setisTriggeredisochrone, selectedType, setselectedType, selectedFeatures, setSelectedFeatures, selectedBudget, setSelectedBudget } = useSelectedOptions();
  const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
  const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
  const [budgetOpen, setBudgetOpen] = useState(false);
  const [lifestyleOpen, setLifestyleOpen] = useState(false);

  //Function to fetch suggestions
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        if (searchValue && searchValue !== selectedSearchvalue) {
          const response = await axios.get(`${Url.BASEURL}/api/user/suggestions?input=${searchValue}`);
          const responseData = response.data;
          setSuggestions(responseData);
        } else {
          setSuggestions(null);
        }
      } catch (error) {
        setSuggestions([]);
      }
    };

    const timerId = setTimeout(() => {
      fetchSuggestions();
    }, 2000);

    return () => clearTimeout(timerId);
  }, [searchValue, selectedSearchvalue]);

  //Function to update input value on state
  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

    //Function to update selected value on state
  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion.description);
    setselectedSearchvalue(suggestion.description);
    setSuggestions([]);
    setnearsearchresult1(suggestion.nearbySearchResults);
    setSearchValue1("");
  };

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //Function to save nearby search value for 1st input field
  const handleSelectNearbyResult1 = (result) => {
    setSearchValue1(result.name);
    setselectednearsearchresult1(result.name);
    setnearsearchresult1([]);
  };

    //Function to save nearby search value for 2nd input field
  const handleSelectNearbyResult2 = (result) => {
    setSearchValue2(result.name);
    setselectednearsearchresult2(result.name);
    setnearsearchresult2([]);
  };

    //Function to save nearby search value for 3rd input field
  const handleSelectNearbyResult3 = (result) => {
    setSearchValue3(result.name);
    setselectednearsearchresult3(result.name);
    setnearsearchresult3([]);
  };

  //Function to close all dropdowns
  const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setPropertyFeaturesOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);
  };

  
  const handleSearchButtonClick = () => {
    setisTriggeredsearch(true);
    setisTriggeredisochrone(true);
  };

  const handleDocumentClick = (e) => {
    if (!e.target.closest(".select_custom") && (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)) {
      closeAllDropdowns();
    }
  };

  document.addEventListener("click", handleDocumentClick);

  return (
    <div className="full_search_view">
      <div className={`search-form search-property ${isSticky ? "sticky" : ""}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <form>
                <div className="all_form_flex d-flex">
                  <div className="single_entry">
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <img src="/assets/location.svg" alt="Woovn Search" />
                      </span>
                      <input type="text" className="form-control" placeholder="Enter City or Zipcode" value={searchValue} onChange={handleInputChange} />
                      {suggestions && suggestions.length > 0 && (
                        <div className="suggestion-container">
                          <ul>
                            {suggestions.map((suggestion, index) => (
                              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                {suggestion.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="single_entry topp">
                    <PropertyTypeDropdown2
                      isOpen={propertyTypeOpen}
                      toggleDropdown={() => {
                        setPropertyTypeOpen(!propertyTypeOpen);
                        closeAllDropdowns();
                      }}
                      className="select_custom"
                    />
                  </div>
                  <div className="single_entry topp">
                    <PropertyFeaturesDropdown2
                      isOpen={propertyFeaturesOpen}
                      toggleDropdown={() => {
                        setPropertyFeaturesOpen(!propertyFeaturesOpen);
                        closeAllDropdowns();
                      }}
                    />
                  </div>
                  <div className="single_entry topp">
                    <BudgetDropdown2
                      isOpen={budgetOpen}
                      toggleDropdown={() => {
                        setBudgetOpen(!budgetOpen);
                        closeAllDropdowns();
                      }}
                    />
                  </div>
                  <div className="single_entry">
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <img src={SearchImage1} alt="property-features" />
                      </span>
                      <input type="text" className="form-control" placeholder="Advanced Search (Optional)" />
                    </div>
                  </div>
                  <div className="single_entry last_entrty">
                    <LifeStyleSearchDropdown2
                      isOpen={lifestyleOpen}
                      toggleDropdown={() => {
                        setLifestyleOpen(!lifestyleOpen);
                        closeAllDropdowns();
                      }}
                    />
                  </div>
                  <div className="single_entry">
                    <button type="button" className="btn btn-primary w-100 custom_search" onClick={handleSearchButtonClick}>
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* {searchValue1.trim().length > 0 && nearsearchresult1?.length > 0 && (
          <div className="suggestion1-container">
            <input type="text" className="form-control" placeholder="Search for nearby places or anything else" value={searchValue1} onChange={(e) => setSearchValue1(e.target.value)} />
            <ul>
              {nearsearchresult1.map((result, index) => (
                <li key={index} onClick={() => handleSelectNearbyResult1(result)}>
                  <div>
                    <strong>{result.name}</strong> - {result.vicinity}
                  </div>
                  <div>
                    <span>Open: {result.open_now ? "Yes" : "No"}</span>
                    <span>Contact: {result.formatted_phone_number}</span>
                    <span>Ratings: {result.rating}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )} */}
        {searchValue1.trim().length > 0 && nearsearchresult1?.length > 0 && (
          <div className="suggestions_sidebar">
            <div className="single_search_area">
              <input
                type="text"
                className="form-control"
                placeholder="Search for nearby places or anything else"
                value={searchValue1}
                onChange={(e) => setSearchValue1(e.target.value)}
                // onFocus={handleInputFocus}
                // onBlur={handleInputBlur}
              />
              {/* <div className="sear_icon" onClick={() => setIsOpenSearchTwo(false)}>
                {showCrossIcon && (
                  <div className="cross_icon" onClick={clearInput}>
                    <img src={crossing} alt="Clear" />
                  </div>
                )}
                <img src={Search} alt="propertyType" />
              </div> */}
            </div>
            {nearsearchresult1.map((suggestion, index) => (
              <div key={index} className="single_suggestion" onClick={() => handleSelectNearbyResult1(suggestion)}>
                <h3>{suggestion.name}</h3>
                <p>{suggestion.vicinity}</p>
                <div className="main_suggestion d-flex">
                  <div className="left_sugg d-flex">
                    <div className="open_close">{suggestion.vicinity}</div>
                    <div className="phone_sugges">{suggestion.phone}</div>
                  </div>
                  <div className="right_sugg">
                    <img src={star} alt="" />
                    {suggestion.rating} <span className="span_count">({suggestion.user_ratings_total})</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {searchValue2.trim().length > 0 && nearsearchresult2?.length > 0 && (
          <div className="suggestions_sidebar">
            <div className="single_search_area">
              <input
                type="text"
                className="form-control"
                placeholder="Search for nearby places or anything else"
                value={searchValue2}
                onChange={(e) => setSearchValue2(e.target.value)}
                // onFocus={handleInputFocus}
                // onBlur={handleInputBlur}
              />
              {/* <div className="sear_icon" onClick={() => setIsOpenSearchTwo(false)}>
                {showCrossIcon && (
                  <div className="cross_icon" onClick={clearInput}>
                    <img src={crossing} alt="Clear" />
                  </div>
                )}
                <img src={Search} alt="propertyType" />
              </div> */}
            </div>
            {nearsearchresult2.map((suggestion, index) => (
              <div key={index} className="single_suggestion" onClick={() => handleSelectNearbyResult2(suggestion)}>
                <h3>{suggestion.name}</h3>
                <p>{suggestion.vicinity}</p>
                <div className="main_suggestion d-flex">
                  <div className="left_sugg d-flex">
                    <div className="open_close">{suggestion.vicinity}</div>
                    <div className="phone_sugges">{suggestion.phone}</div>
                  </div>
                  <div className="right_sugg">
                    <img src={star} alt="" />
                    {suggestion.rating} <span className="span_count">({suggestion.user_ratings_total})</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {searchValue3.trim().length > 0 && nearsearchresult3?.length > 0 && (
          <div className="suggestions_sidebar">
            <div className="single_search_area">
              <input
                type="text"
                className="form-control"
                placeholder="Search for nearby places or anything else"
                value={searchValue3}
                onChange={(e) => setSearchValue3(e.target.value)}
                // onFocus={handleInputFocus}
                // onBlur={handleInputBlur}
              />
              {/* <div className="sear_icon" onClick={() => setIsOpenSearchTwo(false)}>
                {showCrossIcon && (
                  <div className="cross_icon" onClick={clearInput}>
                    <img src={crossing} alt="Clear" />
                  </div>
                )}
                <img src={Search} alt="propertyType" />
              </div> */}
            </div>
            {nearsearchresult3.map((suggestion, index) => (
              <div key={index} className="single_suggestion" onClick={() => handleSelectNearbyResult3(suggestion)}>
                <h3>{suggestion.name}</h3>
                <p>{suggestion.vicinity}</p>
                <div className="main_suggestion d-flex">
                  <div className="left_sugg d-flex">
                    <div className="open_close">{suggestion.vicinity}</div>
                    <div className="phone_sugges">{suggestion.phone}</div>
                  </div>
                  <div className="right_sugg">
                    <img src={star} alt="" />
                    {suggestion.rating} <span className="span_count">({suggestion.user_ratings_total})</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchForm;
