import React, { useState, useEffect } from "react";
import "./Navigation.css";
import LoginProcessModal from "./LoginProcessModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DreamHomeFinder from "../AboutusPages/DreamHomeFinder/DreamHomeFinder";
import { faBars, faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import FreeMarketAnalysis from "../AboutusPages/FreeMarketAnalysis/FreeMarketAnalysis";
import { useSelectedOptions } from "../SelectedOptionsContext";
import Url from "../Element/Api";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../Redux/Action/action";
import UserImage from "../../public/assets/blogs/t2.jpeg";
import { useLocation, Link } from "react-router-dom";
import { updateBrokerInfo } from "../Redux/Action/action";
import Logo from "../../public/assets/white-default-logo.svg";

function MobileNavigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openedDropdown, setOpenedDropdown] = useState(null);
  const [showDreamHomeFinderModal, setShowDreamHomeFinderModal] = useState(false);
  const [showFreeMarketAnalysisModal, setShowFreeMarketAnalysisModal] = useState(false);
  const Domain = window.location.hostname;
  const dispatch = useDispatch();
  const location = useLocation();
  const isHomepage = location.pathname === "/";
  const accessToken = useSelector((state) => state.jwtuser);
  const brokerInfo = useSelector((state) => state.brokerInfo);
  const [metaTagname, setmetaTagname] = useState(null);
  const [userImage, setUserImage] = useState(UserImage);
  const [dataFetched, setDataFetched] = useState(false);
  const { Brandings, setBrandings } = useSelectedOptions();
  const { showredirectloginModal } = useSelectedOptions(false);
  const [showAdvancedSearchModal, setShowAdvancedSearchModal] = useState(false);
  const { setSearchValue, setselectedSearchvalue, setselectedType, setSelectedFeatures, setSelectedBudget, setSearchValue1, setSearchValue2, setSearchValue3, setSelectedSuggestion1, setSelectedSuggestion2, setSelectedSuggestion3, setselectednearsearchresult1, setselectednearsearchresult2, setselectednearsearchresult3, setnearsearchresult1, setnearsearchresult2, setnearsearchresult3 } = useSelectedOptions();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (dropdownId) => {
    setOpenedDropdown(openedDropdown === dropdownId ? null : dropdownId);
  };

  const handleImageChange = (image) => {
    setUserImage(image);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/broker-brandings?domain=${Domain}`, {
          method: "GET",
        });

        const data = await response.json();
        dispatch(updateBrokerInfo({ agentType: data.brokerData.agentType }));
        dispatch(updateBrokerInfo({ companyName: data.brokerData.companyName }));
        dispatch(updateBrokerInfo({ bio: data.brokerData.bio }));
        setBrandings(data.brandings);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setDataFetched(true);
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    dispatch(updateUserInfo({ image: "" }));
    localStorage.removeItem("accessToken");
    dispatch(updateUserInfo({ accessToken: "", _id: "" }));
    window.location.reload();
  };

  useEffect(() => {
    if (showredirectloginModal) {
      const targetElement = document.querySelector('[data-bs-target="#loginModal"]');
      if (targetElement) {
        targetElement.click();
      }
    }
  }, [showredirectloginModal]);

  const handleImageClick = () => {
    setSearchValue("");
    setselectedSearchvalue("");
    setselectedType([]);
    setSelectedFeatures([]);
    setSelectedBudget({
      minPrice: "",
      maxPrice: "",
      minMonthly: "",
      maxMonthly: "",
    });
    setSearchValue1("");
    setSearchValue2("");
    setSearchValue3("");
    setSelectedSuggestion1(null);
    setSelectedSuggestion2(null);
    setSelectedSuggestion3(null);
    setselectednearsearchresult1([]);
    setselectednearsearchresult2([]);
    setselectednearsearchresult3([]);
    setnearsearchresult1([]);
    setnearsearchresult2([]);
    setnearsearchresult3([]);
  };


  return (
    <div className="mobile-nav">
      <div className="mobile-logo">
      {dataFetched && (
            <div className="brand_set">
              {Brandings && Brandings.websiteLogo ? (
                <Link to={Brandings.logoLink} className="navbar-brand">
                  <img src={`https://oregonhomeseeker.com:3001/uploads/${Brandings.websiteLogo}`} alt={Brandings.logoAlternateText} onClick={handleImageClick} />
                </Link>
              ) : (
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt="Default Logo" onClick={handleImageClick} />
                </Link>
              )}
            </div>
          )}
      </div>
      <button className="mobile-menu-btn" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>

      {isOpen && (
        <div className="mobile-menu-content">
          <div className="dropdown" onClick={() => toggleDropdown("search")}>
            Search <FontAwesomeIcon icon={faChevronDown} />
            {openedDropdown === "search" && (
              <div className="dropdown-content">
                <Link className="mobile-link" to="/foreclosure">
                  Foreclosure
                </Link>
                <Link className="mobile-link" to="/open-houses">
                  Open House
                </Link>
                <Link className="mobile-link" to="/featured-listing">
                  Featured Listing
                </Link>
                <Link className="mobile-link" to="/map-search">
                  Map Search
                </Link>
                <Link className="mobile-link" to="/search-by-area">
                  Search By Area
                </Link>
                <Link className="mobile-link" to="/advanced-search">
                  Advanced Search
                </Link>
              </div>
            )}
          </div>

          {/* Resources Dropdown */}
          <div className="dropdown" onClick={() => toggleDropdown("resources")}>
            Resources <FontAwesomeIcon icon={faChevronDown} />
            {openedDropdown === "resources" && (
              <div className="dropdown-content">
                <a href="#" className="mobile-link">
                  Tips For Buyers
                </a>
                <a href="#" className="mobile-link">
                  Tips For Sellers
                </a>

                <a href="#" className="mobile-link">
                  Homeowner
                </a>
                <a href="#" className="mobile-link">
                  Title & Escrow
                </a>
                <a href="#" className="mobile-link">
                  Mortgage
                </a>
                <a href="#" className="mobile-link">
                  Mortgage Rates
                </a>
                <a href="#" className="mobile-link">
                  Schools
                </a>
                <a href="#" className="mobile-link">
                  Relocations
                </a>
                <a href="#" className="mobile-link">
                  Weather
                </a>
                <a href="#" className="mobile-link">
                  Real Estate Glossary
                </a>
              </div>
            )}
          </div>

          <div className="dropdown" onClick={() => toggleDropdown("about")}>
            <span>About</span>
            <FontAwesomeIcon icon={faChevronDown} />
            {openedDropdown === "about" && (
              <div className="dropdown-content">
                <Link className="mobile-link" to="/about-us">
                  About Us
                </Link>

                <Link className="mobile-link" to="/agents">
                  Our Agents
                </Link>
                <Link className="mobile-link" to="/blogs">
                  Our Blogs
                </Link>
                <a href="#" className="mobile-link" onClick={() => setShowFreeMarketAnalysisModal(true)}>
                  Free Market Analysis
                </a>

                <a href="#" className="mobile-link" onClick={() => setShowDreamHomeFinderModal(true)}>
                  Dream Home Finder
                </a>
              </div>
            )}
          </div>

          <Link className="mobile-link" to="/contact-us">
            Contact
          </Link>
          <li className="nav-item logins">
            <span className="nav-link cur" data-bs-toggle="modal" data-bs-target="#loginModal">
              <img src="/assets/login.png" alt="login" />
            </span>
            <LoginProcessModal />
          </li>

          <FreeMarketAnalysis showModal={showModal} setShowModal={setShowModal} />
        </div>
      )}
      <FreeMarketAnalysis showModal={showFreeMarketAnalysisModal} setShowModal={setShowFreeMarketAnalysisModal} />
      <DreamHomeFinder showModal={showDreamHomeFinderModal} setShowModal={setShowDreamHomeFinderModal} />
    </div>
  );
}

export default MobileNavigation;
