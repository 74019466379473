import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Breadcrumb = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter((segment) => segment !== "");
    const breadcrumbItems = pathSegments.map((segment, index) => ({
      name: segment,
      path: `/${pathSegments.slice(0, index + 1).join("/")}`,
    }));

    setBreadcrumbs(breadcrumbItems);
  }, [location]);

  return (
    <ul className="breads">
      {breadcrumbs.map((item, index) => (
        <React.Fragment key={index}>
          <li>{index !== breadcrumbs.length - 1 ? <Link to={item.path}>{item.name}</Link> : item.name}</li>
          {index !== breadcrumbs.length - 1 && (
            <li className="arrow">
              <FontAwesomeIcon icon={faChevronRight} />
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default Breadcrumb;
