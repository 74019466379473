import React from "react";
import Home from "../../public/assets/home.svg";
import Cross from "../../public/assets/cross.svg";
import { useSelectedOptions } from "../SelectedOptionsContext";
import "../../components/AboutusPages/DreamHomeFinder/DreamHomeFinder.css";

function CommentwithoutLogin({ showModal, setShowModal }) {
  const { showredirectloginModal, setShowredirectloginModal } = useSelectedOptions(false);

  const handleredirectlogin = () => {
    setShowModal(false);
    setShowredirectloginModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="free_market_analysis mutual_popuus loggers">
      <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content ">
            <div className="modal_closer">
              <img src={Cross} alt="close Icon" onClick={handleClose} />
            </div>

            <div className="modal-body">
              <div className="w_login">
                <h2>Only Logged-in Users Can Comment In The Blog</h2>
                <img src={Home} alt="Dream" className="img-fluid" />
                <p>Welcome to our blog! We value your thoughts and insights. Please log in to join the conversation and share your comments.</p>
                <p>By logging in, you'll have the opportunity to engage with other readers, ask questions, and contribute to our community. Your feedback is essential to us!</p>

                <div className="W_header">
                  <h3>Ready to Share Your Thoughts? Login Now!</h3>
                </div>
                <div className="W_btns">
                  <button className="btans btan1" onClick={handleredirectlogin}>
                    Login
                  </button>
                  <button className="btans btan2" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Backdrop for modal */}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
}

export default CommentwithoutLogin;
