import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import liked from "../../public/liked.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import alternative1 from "../../public/alternative1.jpg";
import "./wishlist.css";
import Url from "../Element/Api";
import Loader from "../Element/Loader";

function Allwishlist() {
  const UserIdtoken = useSelector((state) => state.jwtuser);
  const wishlistPerPage = 12;
  const regdtoken = UserIdtoken.jwtToken;
  const refreshToken = UserIdtoken.refreshToken;
  const userId = UserIdtoken._id;
  const apiUrl = `${Url.BASEURL}/api/user/Allwishlist`;

  const [wishlist, setWishlist] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [wishlistedProperties, setwishlistedProperties] = useState([]);

  //Function for fetching wishlist data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}?page=${currentPage + 1}&userId=${userId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${regdtoken}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (data && data.success && Array.isArray(data.properties)) {
          setWishlist(data.properties);
          setTotalPages(data.pages);
          setTotalItems(data.total);
        } else {
          console.error("Error: Invalid data structure", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setisLoading(false);
    };

    fetchData();
  }, [currentPage, wishlistedProperties]);

  //Function for changing page
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  //Function for updaing wishlist property
  const handleHeartClick = async (property, event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      const response = await axios.post(
        `${Url.BASEURL}/api/user/create-wishlist`,
        {
          ListingKey: property.ListingKey,
        },
        {
          headers: {
            Authorization: `Bearer ${regdtoken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setwishlistedProperties([...wishlistedProperties, { userId, ListingKey: property.ListingKey }]);
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };

  const formatPropertyType = (propertyType) => {
    if (propertyType === null || propertyType === undefined) {
      return "";
    }
    return propertyType.replace(/([A-Z])/g, " $1").trim();
  };

  return (
    <div className="all_agents blogs_all">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1>Saved Properties</h1>
            <div className="d-flex search_count blogs">
              <div className="Second_coint">{wishlist.length > 0 && <ReactPaginate pageCount={totalPages} pageRangeDisplayed={5} marginPagesDisplayed={1} onPageChange={handlePageClick} forcePage={currentPage} breakClassName="page-item" breakLinkClassName="page-link" containerClassName="paginationn" activeClassName="active" breakLabel="..." previousLabel={<GoChevronLeft />} nextLabel={<GoChevronRight />} previousLinkClassName="custom-button" nextLinkClassName="custom-button" />}</div>
              {wishlist.length > 0 && (
                <div className="agents_count">
                  <span className="ml">
                    {currentPage * wishlistPerPage + 1}-{Math.min((currentPage + 1) * wishlistPerPage, totalItems)}
                  </span>
                  of <span>{totalItems}</span> Results
                </div>
              )}
            </div>
            <div className="row">
              {isLoading ? (
                <Loader />
              ) : wishlist.length > 0 ? (
                wishlist.map((property) => (
                  <div className="col-lg-4 col-md-4" key={property._id}>
                    <Link to={`/property-detail/${property.ListingKey}`}>
                      <div className="property-card">
                        <div className="property-overlays d-flex">
                          <div className="left-overlays">
                            <button className="featured">Featured</button>
                            <button className="featured hot">Hot</button>
                          </div>
                          <div className="right-overlays d-flex">
                            <div className="right_single_block d-flex">
                              <div className="left_single_icon">
                                <img src="/assets/camera.svg" alt="Camera icon" />
                              </div>
                              <div className="right_single_text"></div>
                            </div>
                            <div className="right-overlays d-flex">
                              <div className="right_single_block d-flex">
                                <div className="left_single_icon">
                                  <img src="/assets/camera.svg" alt="Camera icon" />



                                </div>
                                <div className="right_single_text"></div>
                              </div>
                              <div className="right_single_text"></div>
                            </div>
                            <div className="circle_heart d-flex">
                              <div className="left_single_icon heart_set">
                                <img
                                  src={liked}
                                  alt="Heart icon"
                                  onClick={(event) => {
                                    if (regdtoken) {
                                      handleHeartClick(property, event);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <LazyLoadImage src={property.Photo1URL} effect="blur" placeholderSrc={alternative1} />
                        <div className="bottom_property">
                          <h2>{property.PropertyName}</h2>
                          <div className="location_flex d-flex">
                            <div className="location_icon">
                              <img src="/assets/blue-location.png" alt="Location icon" />
                            </div>
                            <div className="location_name">{String(property.UnparsedAddress)}</div>
                          </div>
                          <div className="price-amenities d-flex">
                            <div className="price">
                              <span className="price-sign">$</span> {property.CurrentPriceForStatus && property.CurrentPriceForStatus.$numberDecimal ? Number(property.CurrentPriceForStatus.$numberDecimal).toLocaleString("en-US") : "N/A"}
                            </div>
                            <div className="amenities d-flex">
                              <div className="single_amenities d-flex">
                                <div className="left_amenities">
                                  <img src="/assets/amenties2.png" alt="Amenities icon" />
                                </div>
                                <div className="right_amenities">{property.BedroomsTotal !== null && String(property.BedroomsTotal)}</div>
                              </div>
                              <div className="single_amenities d-flex">
                                <div className="left_amenities">
                                  <img src="/assets/amenties3.png" alt="Amenities icon" />
                                </div>
                                <div className="right_amenities">{property.BathroomsTotalInteger !== null && String(property.BathroomsTotalInteger)}</div>
                              </div>

                              <div className="single_amenities d-flex">
                                <div className="left_amenities">
                                  <img src="/assets/amenties1.png" alt="Amenities icon" />
                                </div>
                                <div className="right_amenities">
                                  {property.LotSizeSquareFeet} <span className="area">sq ft</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex_overal">
                          <div className="first_flex_overaly">{formatPropertyType(property.PropertySubType)}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="col-lg-12 text-center">
                  <p>No saved properties available.</p>
                </div>
              )}
            </div>
            {wishlist.length > 0 && <ReactPaginate pageCount={totalPages} pageRangeDisplayed={5} marginPagesDisplayed={1} onPageChange={handlePageClick} forcePage={currentPage} breakClassName="page-item" breakLinkClassName="page-link" containerClassName="paginationn" activeClassName="active" breakLabel="..." previousLabel={<GoChevronLeft />} nextLabel={<GoChevronRight />} previousLinkClassName="custom-button" nextLinkClassName="custom-button" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Allwishlist;
