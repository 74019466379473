import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./SpaceType.css";
import Url from "../../Element/Api";
import { useSelectedOptions } from "../../SelectedOptionsContext";

function SpaceType() {
  const Domain = window.location.hostname;
  const [propertyData, setPropertyData] = useState([]);
  const { selectedType, setselectedType } = useSelectedOptions();

  //Function for getting random property types
  useEffect(() => {
    fetch(`${Url.BASEURL}/api/mls/reso/property-type?random=true&domain=${Domain}`)
      .then((response) => response.json())
      .then((data) => setPropertyData(data))
      .catch((error) => console.error("Error fetching property data:", error));
  }, []);

  //Formatting property types
  const formatPropertyType = (propertysubType) => {
    if (propertysubType === null || propertysubType === undefined) {
      return "";
    }
    return propertysubType.replace(/([A-Z])/g, " $1").trim();
  };

   //Function to save property type in state
  const handlePropertyDetailsClick = (type, subtype) => {
    const selectedValue = {
      [type]: [subtype],
    };
    setselectedType(selectedValue);
  };

  return (
    <div className="space-type">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-12 col-md-6">
            <div className="image_text">
              <h3>Villa</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidi dunt</p>
            </div>
            <Link to="/search-result">
              <div className="image_blocks imageblock1" onClick={() => handlePropertyDetailsClick(Object.keys(propertyData[0])[0], propertyData[0]?.[Object.keys(propertyData[0])[0]][0]?.subtype)}>
                <div className="property-details">
                  <span className="small-text">{propertyData[0]?.[Object.keys(propertyData[0])[0]][0]?.count} Properties</span>
                  <h2>{formatPropertyType(propertyData[0]?.[Object.keys(propertyData[0])[0]][0]?.subtype)}</h2>
                </div>
                <div className="bottom-links">
                  <a href="#" className="more-properties">
                    More properties
                  </a>
                  <span className="play-icon">
                    <img src="/assets/play.svg" className="img-fluid" alt="play" />
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-12 col-md-6">
            <Link to="search-result">
              <div className="image_blocks imageblock2" onClick={() => handlePropertyDetailsClick(Object.keys(propertyData[1])[0], propertyData[1]?.[Object.keys(propertyData[1])[0]][0]?.subtype)}>
                <div className="property-details">
                  <span className="small-text">{propertyData[1]?.[Object.keys(propertyData[1])[0]][0]?.count} Properties</span>
                  <h2>{formatPropertyType(propertyData[1]?.[Object.keys(propertyData[1])[0]][0]?.subtype)}</h2>
                </div>
                <div className="bottom-links">
                  <a href="#" className="more-properties">
                    More properties
                  </a>
                  <span className="play-icon">
                    <img src="/assets/play.svg" className="img-fluid" alt="play" />
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-12 col-md-6">
            <Link to="/search-result">
              <div className="image_blocks imageblock3" onClick={() => handlePropertyDetailsClick(Object.keys(propertyData[2])[0], propertyData[2]?.[Object.keys(propertyData[2])[0]][0]?.subtype)}>
                <div className="property-details">
                  <span className="small-text">{propertyData[2]?.[Object.keys(propertyData[2])[0]][0]?.count} Properties</span>
                  <h2>{formatPropertyType(propertyData[2]?.[Object.keys(propertyData[2])[0]][0]?.subtype)}</h2>
                </div>
                <div className="bottom-links">
                  <a href="#" className="more-properties">
                    More properties
                  </a>
                  <span className="play-icon">
                    <img src="/assets/play.svg" className="img-fluid" alt="play" />
                  </span>
                </div>
              </div>
            </Link>

            <Link to="/search-result">
              <div className="image_blocks imageblock4" onClick={() => handlePropertyDetailsClick(Object.keys(propertyData[3])[0], propertyData[3]?.[Object.keys(propertyData[3])[0]][0]?.subtype)}>
                <div className="property-details">
                  <span className="small-text">{propertyData[3]?.[Object.keys(propertyData[3])[0]][0]?.count} Properties</span>
                  <h2>{formatPropertyType(propertyData[3]?.[Object.keys(propertyData[3])[0]][0]?.subtype)}</h2>
                </div>
                <div className="bottom-links">
                  <a href="#" className="more-properties">
                    More properties
                  </a>
                  <span className="play-icon">
                    <img src="/assets/play.svg" className="img-fluid" alt="play" />
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-12 col-md-6">
            <Link to="/search-result">
              <div className="image_blocks imageblock5" onClick={() => handlePropertyDetailsClick(Object.keys(propertyData[4])[0], propertyData[4]?.[Object.keys(propertyData[4])[0]][0]?.subtype)}>
                <div className="property-details">
                  <span className="small-text">{propertyData[4]?.[Object.keys(propertyData[4])[0]][0]?.count} Properties</span>
                  <h2>{formatPropertyType(propertyData[4]?.[Object.keys(propertyData[4])[0]][0]?.subtype)}</h2>
                </div>
                <div className="bottom-links">
                  <a href="#" className="more-properties">
                    More properties
                  </a>
                  <span className="play-icon">
                    <img src="/assets/play.svg" className="img-fluid" alt="play" />
                  </span>
                </div>
              </div>
            </Link>

            <Link to="/search-result">
              <div className="image_blocks imageblock6" onClick={() => handlePropertyDetailsClick(Object.keys(propertyData[5])[0], propertyData[5]?.[Object.keys(propertyData[5])[0]][0]?.subtype)}>
                <div className="property-details">
                  <span className="small-text">{propertyData[5]?.[Object.keys(propertyData[5])[0]][0]?.count} Properties</span>
                  <h2>{formatPropertyType(propertyData[5]?.[Object.keys(propertyData[5])[0]][0]?.subtype)}</h2>
                </div>
                <div className="bottom-links">
                  <a href="#" className="more-properties">
                    More properties
                  </a>
                  <span className="play-icon">
                    <img src="/assets/play.svg" className="img-fluid" alt="play" />
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpaceType;
