import React, { useState, useEffect } from "react";
import "./SearchProperty.css";
import axios from "axios";
import Url from "../../Element/Api";
import { useNavigate } from "react-router-dom";
import SearchImage1 from "../../../public/assets/s1.svg";
import { useSelectedOptions } from "../../SelectedOptionsContext";
import BudgetDropdown from "../../ReusableComponents/BudgetDropdown/BudgetDropdown";
import PropertyTypeDropdown from "../../ReusableComponents/PropertyTypeDropdown/PropertyTypeDropdown";
import LifeStyleSearchDropdown from "../../ReusableComponents/LifestyleSearchDropdown/LifeStyleSearchDropdown";
import PropertyFeaturesDropdown from "../../ReusableComponents/PropertyFeaturesDropdown/PropertyFeaturesDropdown";

function SearchProperty() {
  const navigate = useNavigate();
  const [budgetOpen, setBudgetOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchError, setSearchError] = useState("");
  const [lifestyleOpen, setLifestyleOpen] = useState(false);
  const { searchValue, setSearchValue } = useSelectedOptions();
  const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
  const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
  const { selectedSearchvalue, setselectedSearchvalue, isTriggeredsearch, setisTriggeredsearch, isTriggeredisochrone, setisTriggeredisochrone } = useSelectedOptions();

  const handlePropertyTypeSelectionChange = (selectedOptions) => {
    console.log(selectedOptions);
  };

  const handlePropertyFeaturesSelectionChange = (selectedData) => {
    console.log(selectedData);
  };

  //Function for fetching suggestion of places based on input
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        if (searchValue && searchValue !== selectedSearchvalue) {
          const response = await axios.get(`${Url.BASEURL}/api/user/suggestions?input=${searchValue}`);
          const responseData = response.data;
          setSuggestions(responseData);
        } else {
          setSuggestions(null);
        }
      } catch (error) {
        setSuggestions([]);
      }
    };

    const timerId = setTimeout(() => {
      fetchSuggestions();
    }, 2000);

    return () => clearTimeout(timerId);
  }, [searchValue, selectedSearchvalue]);

    //Function to save data in state
  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  useEffect(() => {
    setSearchError("");
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.trim() === "") {
      setSuggestions(null);
    }
  }, [searchValue, setSuggestions]);

    //Function for redirect to next page with value
  const handleSearchButtonClick = (e) => {
    e.preventDefault();
    if (searchValue.trim() === "") {
      setSearchError("Please enter a search value");
    } else {
      setisTriggeredsearch(true);
      setisTriggeredisochrone(true);
      setSearchError("");
      navigate("/search-result");
    }
  };

  //Function to close all the dropdowns
  const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setPropertyFeaturesOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);
  };


  const handleDocumentClick = (e) => {
    if (!e.target.closest(".select_custom") && (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)) {
      closeAllDropdowns();
    }
  };

  document.addEventListener("click", handleDocumentClick);

  return (
    <div className="container haider">
      <div className="row">
        <div className="col-lg-10 offset-lg-1 halip hula_luia">
          <div className="search-property">
            <form>
              <div className="row">
                <div className="col-lg-3">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <img src="/assets/location.svg" alt="Woovn Search" />
                    </span>
                    <input type="text" className="form-control" placeholder="Enter city or zip code" value={searchValue} onChange={handleInputChange} />
                    {suggestions && suggestions.length > 0 && (
                      <div className="suggestion-container">
                        <ul>
                          {suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                setSearchValue(suggestion.description);
                                setselectedSearchvalue(suggestion.description);
                                setSuggestions([]);
                              }}
                            >
                              {suggestion.description}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {searchError && (
                    <p className="error-message" style={{ color: "red" }}>
                      {searchError}
                    </p>
                  )}
                </div>
                <div className="col-lg-3 " id="prop_type_h_only">
                  <PropertyTypeDropdown onSelectionChange={handlePropertyTypeSelectionChange} />
                </div>
                <div className="col-lg-3  mtdn ppo set_feat_home_only">
                  <PropertyFeaturesDropdown onSelectionChange={handlePropertyFeaturesSelectionChange} />
                </div>
                <div className="col-lg-3  mtdn mtdn_btn ppo">
                  <BudgetDropdown
                    isOpen={budgetOpen}
                    toggleDropdown={() => {
                      setBudgetOpen(!budgetOpen);
                      closeAllDropdowns();
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-lg-5">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <img src={SearchImage1} alt="advance-search" />
                    </span>
                    <input type="text" className="form-control" placeholder="Advanced Search (Opional)" />
                  </div>
                </div>
                <div className="col-lg-5" id="mat_set">
                  <LifeStyleSearchDropdown
                    isOpen={lifestyleOpen}
                    toggleDropdown={() => {
                      setLifestyleOpen(!lifestyleOpen);
                      closeAllDropdowns();
                    }}
                  />
                </div>

                <div className="col-lg-2">
                  <button type="submit" className="btn btn-primary w-100 custom_search" onClick={handleSearchButtonClick}>
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchProperty;
