// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { rootReducer } from '../Reducer/reducer';

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  // Add any configuration options here
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Add middleware, devTools, etc. as needed
});

const persistor = persistStore(store);

export { store, persistor };

