import React, { useState, useEffect, useRef } from 'react';
import './PropertyTypeDropdown.css';
import Building from '../../../public/assets/Building.svg';
import Chevron from '../../../public/assets/chevron.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updatePropertyTypes } from '../../Redux/Action/action'; 
import Url from '../../Element/Api'
import { useSelectedOptions } from '../../SelectedOptionsContext';

function PropertyTypeDropdown() {
      const dispatch = useDispatch();
  const propertyTypes = useSelector((state) => state.propertyTypes);
   const Domain = window.location.hostname;
    const { selectedType, setselectedType } = useSelectedOptions();
     const [selectedPropertyType, setSelectedPropertyType] = useState('Property Type'); //State to show the selected value
    const [isOpen, setIsOpen] = useState(false);
   
const [categoryOpenStates, setCategoryOpenStates] = useState({
    'Commercial Sale': true,
    
});

    // Ref to the dropdown for detecting clicks outside
    const dropdownRef = useRef(null); 
useEffect(()=>{
    console.log("propertyTypes",propertyTypes)
},propertyTypes)
    useEffect(() => {
        fetchPropertyTypes();
    }, []);

    useEffect(()=>{
        console.log("selectedType",selectedType)
    },[selectedType])

    const fetchPropertyTypes = async () => {
        try {
            const response = await fetch(`${Url.BASEURL}/api/mls/reso/property-type?domain=${Domain}`);
            const data = await response.json();
            // setPropertyTypes(data);
            dispatch(updatePropertyTypes(data)); 
               const initialOpenStates = {};
            Object.keys(data).forEach(category => {
      
                initialOpenStates[category] = category === 'CommercialSale';
            });
            setCategoryOpenStates(initialOpenStates);
        } catch (error) {
            console.error('Error fetching property types:', error);
        }
    };

    const toggleSelection = (event, category, option) => {
        event.stopPropagation();
        const selectedCategory = selectedType[category] || [];
        const updatedSelectedCategory = selectedCategory.includes(option) 
            ? selectedCategory.filter(item => item !== option)
            : [...selectedCategory, option];

        setselectedType(prevSelectedType => ({
            ...prevSelectedType,
            [category]: updatedSelectedCategory
        }));
    };

const toggleAllSubtypes = (event, category) => {
    event.stopPropagation();
    const allSubtypes = propertyTypes[category];
    const isChecked = event.target.checked;

    if (isChecked) {
        // If checkbox is checked, select all subtypes
        setselectedType(prevSelectedType => ({
            ...prevSelectedType,
            [category]: allSubtypes.filter(option => option !== null)
        }));
    } else {
        // If checkbox is not checked, clear the selection
        setselectedType(prevSelectedType => ({
            ...prevSelectedType,
            [category]: []
        }));
    }
};



 const renderOptions = (options, category) => {
    // Filter out null values from the options
    const filteredOptions = options.filter(option => option !== null);
    
    return filteredOptions.map((option) => {
        // Convert camel case to separate words
        const formattedOption = option.replace(/([A-Z])/g, ' $1').trim();
        return (
            <div 
                key={option}
                className={`option ${selectedType[category] && selectedType[category].includes(option) ? 'selected' : ''}`} 
                onClick={(event) => toggleSelection(event, category, option)}
            >
                {formattedOption}
            </div>
        );
    });
};


const areAllSubtypesSelected = (category) => {
    const allSubtypes = propertyTypes[category].filter(option => option !== null);
    const selectedSubtypes = (selectedType[category] || []).filter(option => option !== null);
    return allSubtypes.length > 0 && allSubtypes.every(subtype => selectedSubtypes.includes(subtype));
};


const toggleCategoryVisibility = (category) => {
    setCategoryOpenStates(prevStates => {
        const updatedStates = {};
        
 
        Object.keys(prevStates).forEach(cat => {
            updatedStates[cat] = cat === category ? !prevStates[cat] : false;
        });
        
        return updatedStates;
    });
};

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleOutsideClick);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);

  useEffect(() => {
        const selected = Object.values(selectedType).flat().join(', ');
        setSelectedPropertyType(selected || 'Property Type');
    }, [selectedType]);

    return (
        <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
            <div className="sel_icon"><img src={Building} alt="propertyType" /></div>
            {/* <div className="sel_text">Property Type</div> */}
                <div className="sel_text">{selectedPropertyType}</div>
            {isOpen && (
                <div className="property_drop">
                    {Object.keys(propertyTypes).map((category) => (
                        <div key={category}>
                            <div className='comercial_block d-flex' onClick={(e) => { 
                                e.stopPropagation(); 
                                toggleCategoryVisibility(category);
                            }}>
                                <div className="left_commerc">
                                     <input className="boxx" type="checkbox"  checked={areAllSubtypesSelected(category)} onClick={(e) => toggleAllSubtypes(e, category)} />
                                    <h3>{category}</h3>
                                   
                                </div>
                                <div className="chevron">
                                    <img src={Chevron} alt="chevron" style={categoryOpenStates[category] ? {} : {transform: 'rotate(180deg)'}} /> 
                                </div>
                            </div>
                            {categoryOpenStates[category] && (
                                <div className="options">
                                    {renderOptions(propertyTypes[category], category)}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default PropertyTypeDropdown;
