import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import "../../AboutusPages/AboutUsPages.css";
import Back from "../../../public/assets/back.svg";
import AgentIc from "../../../public/assets/agent-phone.svg";
import AgentIc1 from "../../../public/assets/agent-message.svg";
import Singleagentmap from "./SingleAgentMap";
import C1 from "../../../public/assets/c1.svg";
import C2 from "../../../public/assets/c2.svg";
import C3 from "../../../public/assets/c3.svg";
import Sliders from "../../../public/assets/s3.svg";
import Url from "../../Element/Api";
import axios from "axios";
import notliked from "../../../public/assets/unlike.svg";
import ClipLoader from "react-spinners/ClipLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import alternative1 from "../../../public/alternative1.jpg";
import liked from "../../../public/assets/like.svg";
import { useSelector } from "react-redux";
import WishListWithoutLogin from "../../HomePage/PropertyListing/WishListWithoutLogin";
import { Helmet } from "react-helmet";

const AgentdropdownOptions = ["Price (Low to High)", "Price (High to Low)"];

function SingleAgent() {
  const observer = useRef();
  const Domain = window.location.hostname;
  const UserIdtoken = useSelector((state) => state.jwtuser);
  const regdtoken = UserIdtoken.jwtToken;
  const realtorInfo = useSelector((state) => state.realtorInfo);
  const [agentData, SetAgentData] = useState();
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [properties, setProperties] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [divRendered, setdivRendered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("activeListings");
  const [totalactivelisting, setTotalactivelisting] = useState();
  const [selectedOption, setSelectedOption] = useState("Sort By");
  const [wishlistedProperties, setwishlistedProperties] = useState([]);
  const [isServiceAreaDropdownOpen, setIsServiceAreaDropdownOpen] = useState(false);
  const [selectedServiceAreaOption, setSelectedServiceAreaOption] = useState("");

  useEffect(() => {
    if (agentData && agentData.serviceArea && agentData.serviceArea.length > 0) {
      setSelectedServiceAreaOption(agentData.serviceArea[0]);
    }
  }, [agentData]);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setProperties([]);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsServiceAreaDropdownOpen(false);
  };

  const toggleServiceAreaDropdown = () => {
    setIsServiceAreaDropdownOpen(!isServiceAreaDropdownOpen);
    setIsDropdownOpen(false);
  };

  const handleServiceAreaOptionSelect = (option) => {
    setSelectedServiceAreaOption(option);
    setIsServiceAreaDropdownOpen(false);
  };

    //Function for fetching agent data based on id
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/realtor-id?id=${realtorInfo}`);
        const data = await response.json();
        SetAgentData(data.realtor);
      } catch (error) {
        console.error("Error fetching realtors:", error);
      }
    };
    fetchAgents();
  }, []);

//Function for getting property details based on options
  const fetchData = async () => {
    try {
      let sortBy = "";
      if (selectedOption === "Price (High to Low)") {
        sortBy = "price_desc";
      } else if (selectedOption === "Price (Low to High)") {
        sortBy = "price_asc";
      }
      const serviceAreaCities = agentData.serviceArea || [];
      const cities = serviceAreaCities.join(",");
      const response = await axios.get(`${Url.BASEURL}/api/mls/reso/search-result/`, {
        params: {
          domain: Domain,
          page: pageNumber,
          size: "12",
          sortBy: sortBy,
          city: cities,
        },
      });
      setTotalactivelisting(response.data.properties.total);
      const { docs } = response.data.properties;
      if (docs && Array.isArray(docs)) {
        setProperties((prevProperties) => [...prevProperties, ...docs]);
        setHasMore(docs.length > 0);
        setLoading(false);
      } else {
        console.error("API response docs is not an array:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if ((pageNumber && agentData) || selectedOption) {
      fetchData();
    }
  }, [pageNumber, agentData, selectedOption]);

  const handleEndOfListRender = () => {
    setdivRendered(true);
  };

  //Observer function for updating pagenumber when scroll ends
  useEffect(() => {
    const setupObserver = () => {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };
      const endOfListElement = document.querySelector(".end-of-list");
      if (endOfListElement) {
        observer.current = new IntersectionObserver(handleObserver, options);
        observer.current.observe(endOfListElement);
      }
    };

    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    };

    if (hasMore && divRendered) {
      setupObserver();
    }
  }, [hasMore, divRendered]);

   //Function for saving property
  const handleLocalHeartClick = (property, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (regdtoken) {
      const updatedProperties = properties.map((p) => (p.ListingKey === property.ListingKey ? { ...p, isWishlisted: !p.isWishlisted } : p));
      setProperties(updatedProperties);
    } else {
      console.log("No registration token, cannot like property locally.");
    }
  };

  //Function for saving property
  const handleHeartClick = async (property, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (regdtoken) {
      try {
        const response = await axios.post(
          `${Url.BASEURL}/api/user/create-wishlist`,
          {
            ListingKey: property.ListingKey,
          },
          {
            headers: {
              Authorization: `Bearer ${regdtoken}`,
              "Content-Type": "application/json",
            },
          }
        );

        setwishlistedProperties([...wishlistedProperties, { userId: UserIdtoken._id, ListingKey: property.ListingKey }]);
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    } else {
      console.log("No registration token, cannot like property.");
    }
  };

  const handleModalDisplay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowModal(true);
  };

  //Loader
  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    );
  }
  return (
    <div className="single-agent-full">
      {agentData && (
        <div className="container">
          <Helmet>
            <title>{agentData.metaTitle}</title>
            <meta name="description" content={agentData.metaDescription} />
          </Helmet>
          <div className="row">
            <div className="col-lg-12">
              <div className="back_page">
                <Link to="/agents" className="breadcrumb-link">
                  <img src={Back} alt="Back Arrow" />
                  back to page
                </Link>
              </div>

              <div className="single_agent_info">
                <img src={agentData.imageUrl} className="img-fluid" alt="" />
                <h1>
                  {agentData.firstname} {agentData.lastname}
                </h1>

                <div className="flex_infomation d-flex">
                  <div className="lest_agt_info">{agentData.profileTagline}</div>
                  <div className="rt_agt_info d-flex">
                    <div className="rt_icons">
                      <img src={AgentIc} className="img-fluid" alt="" />
                      {agentData.phone}
                    </div>
                    <div className="rt_icons">
                      <img src={AgentIc1} className="img-fluid" alt="" />
                      {agentData.email}
                    </div>
                  </div>
                </div>
                <p>{agentData.bio}</p>
              </div>

              <div className="agent_counter d-flex">
                <div className="single_count">
                  <img src={C1} className="img-fluid" alt="Total Deals" />
                  <h2>
                    <CountUp end={193} duration={2} />
                  </h2>
                  <h3>TOTAL DEALS AT WOVNN</h3>
                </div>
                <div className="single_count">
                  <img src={C2} className="img-fluid" alt="Deal Volume" />
                  <h2>
                    $<CountUp end={113} duration={2} />M
                  </h2>
                  <h3>DEAL VOLUME</h3>
                </div>
                <div className="single_count">
                  <img src={C3} className="img-fluid" alt="Highest Deal Price" />
                  <h2>
                    $<CountUp end={1.8} decimals={1} duration={2} />M
                  </h2>
                  <h3>HIGHEST DEAL PRICE</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="overall_property_tabs">
                <div className="property_tab d-flex">
                  <div className={`left_prop_tab ${activeTab === "activeListings" ? "active" : ""}`} onClick={() => handleTabChange("activeListings")}>
                    Active Listing ({totalactivelisting})
                  </div>
                  {/* <div
                    className={`left_prop_tab right_prop_tab ${activeTab === 'soldProperties' ? 'active' : ''}`}
                    onClick={() => handleTabChange('soldProperties')}
                  >
                    Sold Properties (51)
                  </div> */}
                </div>

                {activeTab === "activeListings" && (
                  <div className="prop_tabs_data1">
                    {properties.map((property, index) => (
                      <Link to={`/property-detail/${property.ListingKey}`}>
                        <div className="property-card">
                          <div className="property-overlays d-flex">
                            <div className="left-overlays">
                              <button className="featured">Featured</button>
                              <button className="featured hot">Hot</button>
                            </div>
                            <div className="right-overlays d-flex">
                              <div className="right_single_block d-flex">
                                <div className="left_single_icon">
                                  <img src="/assets/camera.svg" alt="Camera icon" />
                                </div>
                                <div className="right_single_text"></div>
                              </div>
                              <div className="right_single_block d-flex">
                                <div className="left_single_icon">
                                  <img src="/assets/video.svg" alt="Video icon" />
                                </div>
                                <div className="right_single_text"></div>
                              </div>
                              <div className="circle_heart d-flex">
                                <div className="left_single_icon heart_set">
                                  <img
                                    src={property.isWishlisted ? liked : notliked}
                                    alt="Heart icon"
                                    onClick={(event) => {
                                      if (regdtoken) {
                                        handleHeartClick(property, event);
                                        handleLocalHeartClick(property, event);
                                      } else {
                                        handleModalDisplay(event);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="property_main_image">
                            <LazyLoadImage src={property.Photo1URL} effect="blur" placeholderSrc={alternative1} />
                          </div>
                          <div className="bottom_property">
                            <div className="location_flex d-flex">
                              <div className="location_icon">
                                <img src="/assets/blue-location.png" alt="Location icon" />
                              </div>
                              <div className="location_name">{String(property.UnparsedAddress)}</div>
                            </div>
                            <div className="price-amenities d-flex">
                              <div className="price">
                                <span className="price-sign">$</span> {property.CurrentPriceForStatus && property.CurrentPriceForStatus.$numberDecimal ? Number(property.CurrentPriceForStatus.$numberDecimal).toLocaleString("en-US") : "N/A"}
                              </div>
                              <div className="amenities d-flex">
                                {property.BedroomsTotal && (
                                  <div className="single_amenities d-flex">
                                    <div className="left_amenities">
                                      <img src="/assets/amenties2.png" alt="Amenities icon" />
                                    </div>
                                    <div className="right_amenities"> {property.BedroomsTotal !== null && String(property.BedroomsTotal)}</div>
                                  </div>
                                )}

                                {property.BathroomsTotalInteger && (
                                  <div className="single_amenities d-flex">
                                    <div className="left_amenities">
                                      <img src="/assets/amenties3.png" alt="Amenities icon" />
                                    </div>
                                    <div className="right_amenities"> {property.BathroomsTotalInteger !== null && String(property.BathroomsTotalInteger)}</div>
                                  </div>
                                )}

                                <div className="single_amenities d-flex">
                                  <div className="left_amenities">
                                    <img src="/assets/amenties1.png" alt="Amenities icon" />
                                  </div>
                                  <div className="right_amenities">
                                    {property.LotSizeSquareFeet} <span className="area">sq ft</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                    {loading && <p>Loading...</p>}
                    {!loading && !hasMore && <p>End of Properties</p>}
                    <div className="end-of-list" style={{ height: "10px" }} ref={handleEndOfListRender}></div>
                  </div>
                )}

                {activeTab === "soldProperties" && (
                  <div className="prop_tabs_data2">
                    {properties.map((property, index) => (
                      <div className="property-card" key={index}>
                        {/* Property card content */}
                      </div>
                    ))}
                    {loading && <p>Loading...</p>}
                    {!loading && !hasMore && <p>End of Properties</p>}
                    {properties.length > 0 && (
                      <div className="end-of-list" style={{ height: "10px" }}>
                        Hello
                      </div>
                    )}
                  </div>
                )}

                {/* {activeTab === 'soldProperties' && (
                  <div className="prop_tabs_data2">
                    <div className="property-card">
                      <div className="property-overlays d-flex">
                        <div className="left-overlays">
                          <button className="featured">Featured</button>
                          <button className="featured hot">Hot</button>
                        </div>
                        <div className="right-overlays d-flex">

                          <div className="right_single_block d-flex">
                            <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                            <div className="right_single_text">6</div>
                          </div>

                          <div className="right_single_block d-flex">
                            <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                            <div className="right_single_text">1</div>
                          </div>

                          <div className="circle_heart d-flex">
                            <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>

                          </div>
                        </div>
                      </div>
                      <div className="flex_overal">
                        <div className="first_flex_overaly">   Single Family</div>
                        <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid" /></div>

                      </div>
                      <img src="/assets/properties/property2.png" alt="Wovnn propertyies" />
                      <div className="bottom_property">
                        <h2>Home In Merric Way</h2>
                        <div className="location_flex d-flex">
                          <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                          <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                        </div>

                        <div className="price-amenities d-flex">
                          <div className="price"><span className="price-sign">$</span> 540,000</div>
                          <div className="amenities d-flex">
                            <div className="single_amenities d-flex">
                              <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                              <div className="right_amenities">3</div>
                            </div>
                            <div className="single_amenities d-flex">
                              <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                              <div className="right_amenities">3</div>
                            </div>
                            <div className="single_amenities d-flex">
                              <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                              <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="overall_right_side">
                <div className="filtration d-flex">
                  <div className="pset">
                    {agentData.serviceArea && (
                      <div className={`drop_data ${isServiceAreaDropdownOpen ? "open" : ""}`}>
                        <div className="selected_option" onClick={toggleServiceAreaDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                          {selectedServiceAreaOption}
                        </div>
                        {isServiceAreaDropdownOpen && (
                          <div className="drop_selection">
                            {agentData.serviceArea.map((option, index) => (
                              <p key={index} onClick={() => handleServiceAreaOptionSelect(option)}>
                                {option}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="d_set">
                    <div className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                      <div className="selected_option" onClick={toggleDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                        {selectedOption}
                      </div>
                      {isDropdownOpen && (
                        <div className="drop_selection">
                          {AgentdropdownOptions.map((option, index) => (
                            <p key={index} onClick={() => handleOptionSelect(option)}>
                              {option}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="agent_map">
                  <Singleagentmap selectedServiceAreaOption={selectedServiceAreaOption} properties={properties} />
                </div>
              </div>
            </div>
          </div>
          <WishListWithoutLogin showModal={showModal} setShowModal={setShowModal} />
        </div>
      )}
    </div>
  );
}

export default SingleAgent;
