import React from "react";
import "./Welcome.css";
import { useSelector } from "react-redux";

function Welcome() {
  const brokerInfo = useSelector((state) => state.brokerInfo);

  return (
    <div className="welcome">
      <div className="container">
        <div className="row row_man">
          <div className="col-lg-5">
            <div className="welcome_image">
              <h2>Welcome to {brokerInfo.companyName}</h2>
              <img src="/assets/welcome.png" className="img-fluid" alt="cta" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="welcome_text">
              <p>{brokerInfo.bio}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
