import React, { useEffect, useState } from 'react';
import PropertyTypeDropdown from '../PropertySidebarForm/PropertyTypeDropdown/PropertyTypeDropdown';
import BudgetDropdown from '../PropertySidebarForm/BudgetDropdown/BudgetDropdown';
import usStateCodes from 'us-state-codes';
import './PropertySidebar.css'
import Url from '../Element/Api'


function PropertySidebarForm() {
    const [formData, setFormData] = useState({
        person: '',
        firstname: '',
        lastname: '',
        email: '',
        mobileNumber: '',
        location: 'Oregon',
        zipCode: '',
        propertyType: {},
        selectedBudget: {
            maxMonthly: null,
            maxPrice: null,
            minMonthly: null,
            minPrice: null
        },
        gdprAgreement: false,
    });
    const [errors, setErrors] = useState({});
    const [isDataSent, setIsDataSent] = useState(false);

    const usStates = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
        'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
        'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
        'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
        'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    //Getting domain from url
    const Domain = window.location.hostname;

    //Function to update the input values on state
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;


        const alphabetRegex =  /^[a-zA-Z\s]*$/;
        const zipCodeRegex = /^[0-9]{0,5}$/;

        if (name === 'zipCode' && !zipCodeRegex.test(value)) {
            return;
        }
        if ((name === 'person' || name === 'firstname' || name === 'lastname') && !alphabetRegex.test(value)) {
            return;
        }
        let newValue = value;
        if (name === 'mobileNumber') {
            if (/^-/.test(value)) {
                return;
            }
            newValue = value
                .replace(/[^\d]/g, '')
                // Limit the length to 10 characters
                .slice(0, 15);
        }



        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : newValue,
        }));
        // Clear error when the user starts typing again
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setIsDataSent(false);
        if (name === 'location') {
            setFormData((prevState) => ({
                ...prevState,
                location: value,
            }));
        }
    };



  //Function to submit the inquiry form
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Extract selected property types from the propertyType object
        const selectedPropertyTypes = Object.keys(formData.propertyType);
        const formattedPropertyType = selectedPropertyTypes.reduce((acc, type) => {
            return [...acc, ...formData.propertyType[type]];
        }, []);

        const formattedFormData = {
            ...formData,
            propertyType: formattedPropertyType,
            domain: Domain
        };

        try {
            const response = await fetch(`${Url.BASEURL}/api/user/create-inquiry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formattedFormData),
            });
            if (response.status === 400) {
                const errorData = await response.json();
                console.error('Bad Request Error:', errorData);
                // Set errors state with the validation errors received from the server
                setErrors(errorData.errors || {});
                return;
            }
            const data = await response.json();
            if (response.ok) {
                console.log('Data sent successfully:', data);
                // Reset form data after successful submission
                setFormData({
                    person: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    mobileNumber: '',
                    location: 'Oregon',
                    zipCode: '',
                    propertyType: {
                        
                    },

                    selectedBudget: {
                        maxMonthly: null,
                        maxPrice: null,
                        minMonthly: null,
                        minPrice: null,
                    },

                    gdprAgreement: false,
                    domain: Domain
                });
                setIsDataSent(true);
            }
        } catch (error) {
            console.log("error")
            console.error('Error sending data:', error);

        }
    };

    const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
    const [budgetOpen, setBudgetOpen] = useState(false);
    const closeAllDropdowns = () => {
        setPropertyTypeOpen(false);
        setBudgetOpen(false);
    };
    const handleDocumentClick = (e) => {
        if (!e.target.closest('.select_custom') && (propertyTypeOpen || budgetOpen)) {
            closeAllDropdowns();
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [propertyTypeOpen, budgetOpen]);

    
  //Function to update the propertytypes on state
    const handlePropertyTypeSelect = (selectedPropertyType) => {
        // Update the form data with the selected property types
        setFormData((prevState) => ({
            ...prevState,
            propertyType: selectedPropertyType,
        }));

        // Clear any previous errors related to property type
        setErrors((prevErrors) => ({
            ...prevErrors,
            propertyType: '',
        }));
    };


  //Function to update the selected budget on state
    const handleBudgetSelect = (selectedBudget) => {
        console.log('Selected Budget in parent:', selectedBudget);

        const { minPrice, maxPrice, minMonthly, maxMonthly } = selectedBudget;

        const updatedFormData = {
            ...formData,
            selectedBudget: {
                minMonthly: parseFloat(minMonthly) || null,
                maxMonthly: parseFloat(maxMonthly) || null,
                minPrice: parseFloat(minPrice) || null,
                maxPrice: parseFloat(maxPrice) || null,
            },
        };

        // Update the form data
        setFormData(updatedFormData);
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedBudget: '',
        }));
    };



    return (
        <div className="inquery property-detail-form pppp" >
            <h3>Real Estate Inquiry Form</h3>
            <form className="inq_form" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="information" className="form-label">Information</label>
                    <input
                        type="text"
                        className="form-control"
                        id="information"
                        name="person"
                        placeholder="I’m a"
                        value={formData.person}
                        onChange={handleChange}
                    // onBlur={handleBlur}
                    />
                    <span className="error" style={{ color: 'red' }}>{errors.person}</span>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            name="firstname"
                            placeholder="First Name"
                            value={formData.firstname}
                            onChange={handleChange}
                        // onBlur={handleBlur}
                        />
                        <span className="error" style={{ color: 'red' }}>{errors.firstname}</span>
                    </div>
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            name="lastname"
                            placeholder="Last Name"
                            value={formData.lastname}
                            onChange={handleChange}
                        // onBlur={handleBlur}
                        />
                        <span className="error" style={{ color: 'red' }}>{errors.lastname}</span>
                    </div>
                </div>
                <div className="mb-3">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                    // onBlur={handleBlur}
                    />
                    {errors.email && <span className="error" style={{ color: 'red' }}>{errors.email}</span>}
                </div>
                <div className="mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="Mobile"
                        name="mobileNumber"
                        placeholder="Mobile No."
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        maxLength="15"
                    // onBlur={handleBlur}
                    />
                    {errors.mobileNumber && <span className="error" style={{ color: 'red' }}>{errors.mobileNumber}</span>}
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">Location</label>
                            <select
                                id="location"
                                className="form-select"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                            // onBlur={handleBlur}
                            >
                                {usStates.map((state, index) => (
                                    <option key={index} value={state}>
                                        {state}
                                    </option>
                                ))}
                            </select>
                            <span className="error" style={{ color: '#ED4949' }}>{errors.location}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3 marg_top">
                            <input
                                type="text"
                                className="form-control"
                                id="zipCode"
                                name="zipCode"
                                placeholder="Zip Code"
                                value={formData.zipCode}
                                onChange={handleChange}
                            // onBlur={handleBlur}
                            />
                            <span className="error" style={{ color: 'red' }}>{errors.zipCode}</span>
                        </div>
                    </div>
                </div>
                <div className="row mb-3" >
                    <div className="col-md-6">
                        <div className="mb-3 ">
                            <label htmlFor="propertyType" className="form-label">Property</label>
                            <PropertyTypeDropdown
                                isOpen={propertyTypeOpen}
                                toggleDropdown={() => {
                                    setPropertyTypeOpen(!propertyTypeOpen);
                                    closeAllDropdowns();
                                }}
                                onSelectPropertyType={handlePropertyTypeSelect}
                                isDataSent={isDataSent}
                            />
                            <span className="error" style={{ color: 'red' }}>{errors.propertyType}</span>
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className="mb-3 marg_top " id="handler_only">
                            <BudgetDropdown
                                isOpen={budgetOpen}
                                toggleDropdown={() => {
                                    setBudgetOpen(!budgetOpen);
                                    closeAllDropdowns();
                                }}
                                onSelectBudget={handleBudgetSelect}
                                isDataSent={isDataSent}
                            />
                            <span className="error" style={{ color: 'red' }}>{errors.selectedBudget}</span>
                        </div>
                    </div>
                </div>
                <label htmlFor="location" className="form-label dnone">GDPR Agreement</label>
                <div className="form-check mb-3 dnone">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="gdprAgreement"
                        name="gdprAgreement"
                        checked={formData.gdprAgreement}
                        onChange={handleChange}
                    // onBlur={handleBlur}
                    />
                    <label className="form-check-label custom_agreement" htmlFor="gdprAgreement">I consent to having this website store my submitted information</label>

                </div>
                {isDataSent && (
                    <span className="error" style={{ color: '#22A9E0' }}>Thank you for submitting your information! We'll get back to you shortly</span>

                )

                }
                <div className="d-flex ">
                    <button type="submit" className="custom_btn">Submit</button>
                </div>
            </form>
        </div>
    );
}
export default PropertySidebarForm;
