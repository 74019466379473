import React, { useState, useEffect } from "react";
import axios from "axios";
import Url from "../../../Element/Api";
import usStateCodes from "us-state-codes";
import { useSelectedOptions } from "../../../SelectedOptionsContext";
import ReactDOMServer from "react-dom/server";
import PropertyInfoWindow from "./PropertyInfoWindow";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./Map.css";

const colors = ["#DF41D3", "#00FF00", "#0000FF"];

function Map() {
  const location = useLocation();
  const { pathname } = location;
  const [, type] = pathname.split("/");
  const UserIdtoken = useSelector((state) => state.jwtuser);
  const userId = UserIdtoken._id;
  const Domain = window.location.hostname;

  //States
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 45.4869, lng: -122.804 });
  const [mapInitialized, setMapInitialized] = useState(false);
  const [placeIsochrones, setPlaceIsochrones] = useState([]);
  const [isIsochroneActive, setIsIsochroneActive] = useState(false);
  const [properties, setProperties] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [markers, setMarkers] = useState("");
  const { selectedGarage, selectedStories, selectedParkingSpace, selectedTimeOnSite, yearBuilt } = useSelectedOptions();
  const { selectedType, selectedBudget, selectedFeatures } = useSelectedOptions({ minPrice: "", maxPrice: "", minMonthly: "", maxMonthly: "" });
  const { searchValue, selectedSearchvalue, selectedSuggestion1, selectedSuggestion2, selectedSuggestion3, selectednearsearchresult1, selectednearsearchresult2, selectednearsearchresult3 } = useSelectedOptions();
  const { sliderValuesearch1, sliderValuesearch2, sliderValuesearch3, isTriggeredsearch, setisTriggeredsearch, isTriggeredisochrone, setisTriggeredisochrone } = useSelectedOptions();
  const [fetchedData, setFetchedData] = useState({
    places: new Set(),
    sliderValues: {},
  });

  const [isochroneOptions, setIsochroneOptions] = useState([
    { suggestion: selectedSuggestion1, nearSearchResult: selectednearsearchresult1, sliderValue: sliderValuesearch1 },
    { suggestion: selectedSuggestion2, nearSearchResult: selectednearsearchresult2, sliderValue: sliderValuesearch2 },
    { suggestion: selectedSuggestion3, nearSearchResult: selectednearsearchresult3, sliderValue: sliderValuesearch3 },
  ]);

  const clearMarkers = () => {
    if (markers.length > 0) {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      setMarkers([]);
    }
  };

  //Function to fetch properties
  const fetchProperties = async () => {
    try {
      const searchValues = searchValue ? searchValue.split(",").map((value) => value.trim()) : [];
      let cityFilter = "";
      let stateFilter = "";
      if (searchValues.length === 3 || searchValues.length === 1) {
        const cityName = encodeURIComponent(searchValues[0].replace(/\s/g, ""));
        cityFilter = cityName ? `&city=${cityName}` : "";
      } else if (searchValues.length === 2) {
        const stateName = searchValues[0].trim();
        const stateAbbreviation = usStateCodes.getStateCodeByStateName(stateName) || stateName;
        stateFilter = stateAbbreviation ? `&state=${stateAbbreviation}` : "";
      }
      //type filter
      const propertyTypes = Object.values(selectedType).flat();
      const typeFilter = propertyTypes.length > 0 ? `&propertyType=${propertyTypes.join(",")}` : "";

      //Price filter
      const minPrice = selectedBudget.minPrice || "";
      const maxPrice = selectedBudget.maxPrice || "";
      const priceFilter = minPrice && maxPrice ? `&minPrice=${minPrice}&maxPrice=${maxPrice}` : minPrice ? `&minPrice=${minPrice}` : maxPrice ? `&maxPrice=${maxPrice}` : "";

      const bedroomsFilter = selectedFeatures.bedrooms ? `&minBedRoom=${selectedFeatures.bedrooms}` : "";
      const bathroomsFilter = selectedFeatures.bathrooms ? `&minBathRoom=${selectedFeatures.bathrooms}` : "";
      const sqftFilter = selectedFeatures.minSqft || selectedFeatures.maxSqft ? `&minArea=${selectedFeatures.minSqft || ""}&maxArea=${selectedFeatures.maxSqft || ""}` : "";
      //Lot size filter
      let lotSizeFilter = "";

      if ((selectedFeatures.minLotSizeAcres || selectedFeatures.maxLotSizeAcres) && (selectedFeatures.minLotSizeSqft || selectedFeatures.maxLotSizeSqft)) {
        const minLotSizeAcres = selectedFeatures.minLotSizeAcres || 0;
        const maxLotSizeAcres = selectedFeatures.maxLotSizeAcres;

        const minLotSizeSqft = selectedFeatures.minLotSizeSqft || 0;
        const maxLotSizeSqft = selectedFeatures.maxLotSizeSqft;

        lotSizeFilter = `&minTotalAreaAcres=${minLotSizeAcres}&maxTotalAreaAcres=${maxLotSizeAcres}&minTotalAreaSqft=${minLotSizeSqft}&maxTotalAreaSqft=${maxLotSizeSqft}`;
      } else if (selectedFeatures.minLotSizeAcres || selectedFeatures.maxLotSizeAcres) {
        const minLotSizeAcres = selectedFeatures.minLotSizeAcres || 0;
        const maxLotSizeAcres = selectedFeatures.maxLotSizeAcres;

        lotSizeFilter = `&minTotalAreaAcres=${minLotSizeAcres}&maxTotalAreaAcres=${maxLotSizeAcres}`;
      } else if (selectedFeatures.minLotSizeSqft || selectedFeatures.maxLotSizeSqft) {
        const minLotSizeSqft = selectedFeatures.minLotSizeSqft || 0;
        const maxLotSizeSqft = selectedFeatures.maxLotSizeSqft;
        lotSizeFilter = `&minTotalAreaSqft=${minLotSizeSqft}&maxTotalAreaSqft=${maxLotSizeSqft}`;
      }

      const garageSpacesFilter = selectedGarage ? `&GarageSpaces=${selectedGarage}` : "";
      const storiesFilter = selectedStories ? `&Stories=${selectedStories}` : "";
      const parkingTotalFilter = selectedParkingSpace ? `&ParkingTotal=${selectedParkingSpace}` : "";
      const yearBuiltFilter = yearBuilt.minYear || yearBuilt.maxYear ? `&minYear=${yearBuilt.minYear || ""}&maxYear=${yearBuilt.maxYear || ""}` : "";
      // Combine all filters
      const filterString = `${cityFilter}${stateFilter}${typeFilter}${priceFilter}${bedroomsFilter}${bathroomsFilter}${sqftFilter}${lotSizeFilter}${garageSpacesFilter}${storiesFilter}${parkingTotalFilter}${yearBuiltFilter}`;
      const apiUrl = `${Url.BASEURL}/api/mls/reso/${type}?domain=${Domain}&${filterString}&userId=${userId}`;
      // Fetch data from the API
      const response = await axios.get(apiUrl);
      if (response.data) {
        // Extract properties from the API response
        const properties = Array.isArray(response.data.properties.docs) ? response.data.properties.docs : [];
        setProperties(properties);
        clearMarkers();
      } else {
        console.error("API response does not have the expected structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Useeffect to show all properties on initial load id clicked on search button is triggered is active and filter data
  useEffect(() => {
    const fetchData = () => {
      if ((isTriggeredsearch || initialLoad) && !(isTriggeredsearch && initialLoad)) {
        clearMarkers();
        fetchProperties()
          .then(() => {
            setInitialLoad(false);
            setisTriggeredsearch(false);
            setisTriggeredisochrone(false);
          })

          .catch((error) => {
            console.error("Error fetching properties:", error);
          });
      }
    };

    fetchData();
  }, [isTriggeredsearch, initialLoad]);

  //Function for clearing properties
  useEffect(() => {
    const fetchData = () => {
      if (selectedSearchvalue) {
        clearMarkers();
        fetchProperties();
      } else if (type) {
        clearMarkers();
        fetchProperties();
      }
    };
    fetchData();
  }, [selectedSearchvalue, type]);



  let isPropertyInAnyIsochroneCallCount = 0;

  //Function for single isochrone
  const isPropertyInAnyIsochrone = (propertyLatLng) => {
    isPropertyInAnyIsochroneCallCount++;

    if (placeIsochrones && placeIsochrones.length === 1) {
      const isochroneData = placeIsochrones[0]?.data;
      if (isochroneData && isochroneData.features && isochroneData.features.length > 0) {
        const isochroneCoordinates = isochroneData.features[0]?.geometry?.coordinates;
        if (isochroneCoordinates) {
          const latLngCoordinates = isochroneCoordinates[0].map(([lng, lat]) => ({ lat, lng }));
          const isochronePolygon = new window.google.maps.Polygon({
            paths: latLngCoordinates,
          });
          if (window.google.maps.geometry && window.google.maps.geometry.poly.containsLocation(propertyLatLng, isochronePolygon)) {
            console.log("Property is within isochrone:", propertyLatLng);
            return true;
          }
        } else {
          console.error("Isochrone data format is incorrect:", isochroneData);
        }
      }
    }
    return false;
  };

  //Function for two isochrones
  const isPropertyInIntersectedIsochrone = (propertyLatLng) => {
    if (placeIsochrones && placeIsochrones.length === 2) {
      const isochronePolygons = placeIsochrones.map((isochrone) => {
        const isochroneCoordinates = isochrone?.data?.features[0]?.geometry?.coordinates;
        if (isochroneCoordinates) {
          const latLngCoordinates = isochroneCoordinates[0].map(([lng, lat]) => ({ lat, lng }));
          return new window.google.maps.Polygon({
            paths: latLngCoordinates,
          });
        } else {
          return null;
        }
      });

      let isochroneCount = 0;

      for (const [index, isochronePolygon] of isochronePolygons.entries()) {
        if (isochronePolygon && window.google.maps.geometry && window.google.maps.geometry.poly.containsLocation(propertyLatLng, isochronePolygon)) {
          isochroneCount++;
          if (isochroneCount > 1) {
            break;
          }
        }
      }
      return isochroneCount >= 2;
    } else if (placeIsochrones && placeIsochrones.length > 2) {
      const isochronePolygons = placeIsochrones.map((isochrone) => {
        const isochroneCoordinates = isochrone?.data?.features[0]?.geometry?.coordinates;
        if (isochroneCoordinates) {
          const latLngCoordinates = isochroneCoordinates[0].map(([lng, lat]) => ({ lat, lng }));
          return new window.google.maps.Polygon({
            paths: latLngCoordinates,
          });
        } else {
          return null;
        }
      });
      let isochroneCount = 0;
      for (const [index, isochronePolygon] of isochronePolygons.entries()) {
        if (isochronePolygon && window.google.maps.geometry && window.google.maps.geometry.poly.containsLocation(propertyLatLng, isochronePolygon)) {
          isochroneCount++;
        }
      }
      return isochroneCount > 2;
    }
    return false;
  };


  //Function for three isochrone
  const areIsochronesIntersecting = () => {
    if (window.google && window.google.maps && window.google.maps.geometry && placeIsochrones && placeIsochrones.length > 1) {
      const isochronePolygons = placeIsochrones.map((isochrone) => {
        const isochroneData = isochrone?.data;
        if (isochroneData && isochroneData.features && isochroneData.features.length > 0) {
          const isochroneCoordinates = isochroneData.features[0]?.geometry?.coordinates;
          if (isochroneCoordinates) {
            const latLngCoordinates = isochroneCoordinates[0].map(([lng, lat]) => ({ lat, lng }));
            return new window.google.maps.Polygon({
              paths: latLngCoordinates,
            });
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
      for (let i = 0; i < isochronePolygons.length - 1; i++) {
        for (let j = i + 1; j < isochronePolygons.length; j++) {
          const path1 = isochronePolygons[i].getPath();
          const path2 = isochronePolygons[j].getPath();
          for (let k = 0; k < path1.getLength(); k++) {
            if (window.google.maps.geometry.poly.containsLocation(path1.getAt(k), isochronePolygons[j])) {
              return true;
            }
          }
          for (let k = 0; k < path2.getLength(); k++) {
            if (window.google.maps.geometry.poly.containsLocation(path2.getAt(k), isochronePolygons[i])) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  //Function to show property markers
  const displayPropertyMarkers = () => {
    const isochronesIntersecting = areIsochronesIntersecting();

    if (map && properties && properties.length > 0) {
      clearMarkers();
      const newMarkers = [];

      properties.forEach((property) => {
        const { Latitude, Longitude, UnparsedAddress, CurrentPriceForStatus } = property;

        if (!isNaN(parseFloat(Latitude)) && !isNaN(parseFloat(Longitude))) {
          const propertyLatLng = new window.google.maps.LatLng(parseFloat(Latitude), parseFloat(Longitude));
          let isInIsochrone = true;

          if (isIsochroneActive) {
            if (Array.isArray(placeIsochrones) && placeIsochrones.length === 1) {
              isInIsochrone = isPropertyInAnyIsochrone(propertyLatLng);
            } else {
              if (isochronesIntersecting) {
                isInIsochrone = isPropertyInIntersectedIsochrone(propertyLatLng);
              } else {
                isInIsochrone = false;
              }
            }
          }

          if (isInIsochrone) {
            const marker = new window.google.maps.Marker({
              position: propertyLatLng,
              map,
              title: `${UnparsedAddress}\nPrice: $${CurrentPriceForStatus}`,
            });

            const infoWindowContent = ReactDOMServer.renderToString(<PropertyInfoWindow property={property} />);
            const infoWindow = new window.google.maps.InfoWindow({
              content: infoWindowContent,
            });

            marker.addListener("click", () => {
              infoWindow.open(map, marker);
            });
            newMarkers.push(marker);
          }
        }
      });

      setMarkers(newMarkers);
    }
  };

  // Function for Calculating isochrone
  const calculateIsochrones = async (location, setIsochrones, options) => {
    if (!location) return;
    const apiUrl = "https://api.openrouteservice.org/v2/isochrones/driving-car";
    const rangeValue = options.mode === "time" ? options.value * 60 : options.value;
    const requestBody = {
      locations: [[location.lng, location.lat]],
      range: [rangeValue],
      range_type: options.mode,
      units: "mi",
    };

    try {
      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          Accept: "application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8",
          "Content-Type": "application/json",
          Authorization: "5b3ce3597851110001cf62484c151d301a9e478cae95a739c2508dd4",
        },
      });

      const data = response.data;

      if (response.status === 200) {
        setIsIsochroneActive(true);
        setIsochrones((prevIsochrones) => [...prevIsochrones, { index: options.index, data }]);
      } else {
        console.error("Error response from API:", data.error?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching isochrones:", error.message);
    }
  };

  //Adding isochrones on map
  const addIsochronePolygons = () => {
    if (map && placeIsochrones && placeIsochrones.length > 0) {
      placeIsochrones.forEach((isochrone, index) => {
        const isochroneCoordinates = isochrone?.data?.features[0]?.geometry?.coordinates;
        if (isochroneCoordinates) {
          const latLngCoordinates = isochroneCoordinates[0].map(([lng, lat]) => ({ lat, lng }));
          const colorIndex = index % 3; // Use 3 instead of colors.length
          const isochronePolygon = new window.google.maps.Polygon({
            paths: latLngCoordinates,
            strokeColor: colors[colorIndex], // Set stroke color
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: colors[colorIndex], // Set fill color
            fillOpacity: 0.35,
          });
          isochronePolygon.setMap(map);
        } else {
          console.error("Isochrone data format is incorrect:", isochrone);
        }
      });
    }
  };


  //Function to initialise map
  const initMap = async () => {
    try {
      const { Map } = await window.google.maps.importLibrary('maps');

      let centerLocation = center;

      // If selectedSearchvalue is provided
      if (selectedSearchvalue) {
        const detailsResponse = await axios.get(`${Url.BASEURL}/api/user/getplacedetails`, {
          params: {
            input: selectedSearchvalue,
          },
        });
        if (detailsResponse && detailsResponse.data && detailsResponse.data.result) {
          const { geometry } = detailsResponse.data.result;
          if (geometry && geometry.location) {
            const { lat, lng } = geometry.location;
            centerLocation = { lat, lng };
          }
          console.log('Map redirected to selected location successfully.');
        } else {
          console.error('Error getting place details:', detailsResponse);
        }
      }

      // If geolocation is supported
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // If selectedSearchvalue is not provided, use current location
          if (!selectedSearchvalue) {
            centerLocation = currentLocation;
          }

          const newMap = new Map(document.getElementById('map'), {
            center: centerLocation,
            zoom: 12,
          });

          setMap(newMap);

          console.log('Map initialized successfully.');
          setMapInitialized(true);
        }, (error) => {
          console.error('Error getting current location:', error);
          // Initialize map with default center if geolocation is denied or unavailable
          const newMap = new Map(document.getElementById('map'), {
            center: centerLocation,
            zoom: 12,
          });
          setMap(newMap);

          console.log('Map initialized with default center.');
          setMapInitialized(true);
        });
      } else {


        // Initialize map with center based on selectedSearchvalue or default center
        const newMap = new Map(document.getElementById('map'), {
          center: centerLocation,
          zoom: 12,
        });

        setMap(newMap);

        console.log('Map initialized successfully.');
        setMapInitialized(true);
      }
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  };

  useEffect(() => {
    console.log("placesiso", placeIsochrones)
  }, [placeIsochrones])


  // Usage in getPlaceDetailsAndIsochrones
  const getPlaceDetailsAndIsochrones = async (input, sliderValue, index) => {
    console.log("getPlaceDetailsAndIsochrones called with:", input, sliderValue);

    // Add a check for a non-blank input
    if (typeof input === 'string' && input.trim() !== '') {
      try {
        setPlaceIsochrones((prevIsochrones) => prevIsochrones.filter((isochrone) => isochrone.index !== index));
        const detailsResponse = await axios.get(`${Url.BASEURL}/api/user/getplacedetails`, {
          params: {
            input,
          },
        });

        if (detailsResponse && detailsResponse.data && detailsResponse.data.result) {
          const { geometry } = detailsResponse.data.result;
          if (geometry && geometry.location) {
            const { lat, lng } = geometry.location;
            await calculateIsochrones({ lat, lng }, setPlaceIsochrones, {
              value: sliderValue.value,
              mode: sliderValue.mode,
              index, // Add the index
            });
          } else {
            console.error("Error getting place details:", detailsResponse);
          }
        } else {
          console.error("Error getting or invalid place details:", detailsResponse);
        }
      } catch (error) {
        console.error("Error getting place details:", error.message);
      }
    } else {
      console.log("Input value is blank. Skipping getPlaceDetailsAndIsochrones API call.");
    }
  };

  // Function to update a specific isochrone option
  const updateIsochroneOption = (index, newOption) => {
    setIsochroneOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      if (index !== undefined && index >= 0 && index < updatedOptions.length) {
        updatedOptions[index] = newOption;
      }
      return updatedOptions;
    });
  };

  //Function to pass the selected values
  useEffect(() => {
    const fetchData = async (option, index) => {
      const { suggestion, nearSearchResult, sliderValue } = option;
      if (!(suggestion || nearSearchResult)) {
        return;
      }

      if ((fetchedData.places.has(suggestion) || fetchedData.places.has(nearSearchResult)) && fetchedData.sliderValues[suggestion || nearSearchResult]?.mode === sliderValue.mode && fetchedData.sliderValues[suggestion || nearSearchResult]?.value === sliderValue.value) {
        return;
      }

      if (isTriggeredisochrone && (suggestion || nearSearchResult)) {
        const inputToUse = suggestion || nearSearchResult;
        await getPlaceDetailsAndIsochrones(inputToUse, sliderValue, index);
        setFetchedData((prevData) => ({
          places: new Set([...prevData.places, inputToUse]),
          sliderValues: { ...prevData.sliderValues, [inputToUse]: sliderValue },
        }));
        updateIsochroneOption(index, { suggestion, nearSearchResult, sliderValue });
      }
    };

    const options = [
      { suggestion: selectedSuggestion1, nearSearchResult: selectednearsearchresult1, sliderValue: sliderValuesearch1 },
      { suggestion: selectedSuggestion2, nearSearchResult: selectednearsearchresult2, sliderValue: sliderValuesearch2 },
      { suggestion: selectedSuggestion3, nearSearchResult: selectednearsearchresult3, sliderValue: sliderValuesearch3 },
    ];

    options.forEach(async (option, index) => {
      const { suggestion, nearSearchResult, sliderValue } = option;
      if (isTriggeredisochrone && (suggestion || nearSearchResult) && (sliderValue.mode !== fetchedData.sliderValues[suggestion || nearSearchResult]?.mode || sliderValue.value !== fetchedData.sliderValues[suggestion || nearSearchResult]?.value)) {
        console.log("Removing isochrones for:", { suggestion, nearSearchResult, sliderValue });
      }
      await fetchData(option, index); // Pass the index to fetchData
    });
  }, [isTriggeredisochrone]);

  // Map initialization
  useEffect(() => {
    if (!mapInitialized) {
      initMap();
    } else if (initialLoad && !mapInitialized) {
      initMap();
    } else if (isTriggeredsearch && placeIsochrones) {
      initMap();
    } else if (selectedSearchvalue) {
      initMap();
    }
  }, [isTriggeredsearch, placeIsochrones, selectedSearchvalue, initialLoad]);

  useEffect(() => {
    if (mapInitialized && map && placeIsochrones && properties) {
      console.log("display marker called");
      displayPropertyMarkers();
      addIsochronePolygons();
    }
  }, [mapInitialized, map, placeIsochrones, properties]);

  return (
    <div className="gmap">
      <div id="map" style={{ height: "540px", width: "100%" }}></div>
    </div>
  );
}

export default Map;
