import React, { useState, useEffect, useRef } from "react";
import "./PropertySearchResult.css";
import liked from "../../../../public/liked.svg";
import notliked from "../../../../public/assets/unlike.svg";
import { useSelectedOptions } from "../../../SelectedOptionsContext";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import usStateCodes from "us-state-codes";
import { useSelector } from "react-redux";
import Sliders from "../../../../public/s3.svg";
import Url from "../../../Element/Api";
import WishListWithoutLogin from "../../../HomePage/PropertyListing/WishListWithoutLogin";
import alternative1 from "../../../../public/alternative1.jpg";
import "react-lazy-load-image-component/src/effects/blur.css";

function PropertySearchResult() {
  const UserIdtoken = useSelector((state) => state.jwtuser);
  const userId = UserIdtoken._id;
  const regdtoken = UserIdtoken.jwtToken;
  const location = useLocation();
  const { pathname } = location;
  const [, type] = pathname.split("/");
  const Domain = window.location.hostname;
  const [pageSize] = useState(24);
  const rowRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("Sort By");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const { searchValue } = useSelectedOptions();
  const [blockCount, setBlockCount] = useState(12);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#22A9E0");
  const [initialLoad, setInitialLoad] = useState(true);
  const [wishlistedProperties, setwishlistedProperties] = useState([]);
  const { selectedGarage, selectedStories, selectedParkingSpace, selectedTimeOnSite, yearBuilt, isNewConstructionSelected } = useSelectedOptions();
  const { selectedSearchvalue, selectedType, isTriggeredsearch, setisTriggeredsearch, selectedBudget, selectedFeatures } = useSelectedOptions();
  const totalPages = Math.ceil(totalResults / pageSize);
  const Sortoption = ["Price(High to Low)", "Price(Low to High)"];

  //Function to fetch properties
  const fetchData = async (page) => {
    try {
      setLoading(true);

      //Sort filters
      let sortFilter = "";
      if (selectedOption === "Price(High to Low)") {
        sortFilter = "&sortBy=price_desc";
      } else if (selectedOption === "Price(Low to High)") {
        sortFilter = "&sortBy=price_asc";
      }

      //Search filters
      const searchValues = searchValue ? searchValue.split(",").map((value) => value.trim()) : [];
      let cityFilter = "";
      let stateFilter = "";
      if (searchValues.length === 3 || searchValues.length === 1) {
        const cityName = encodeURIComponent(searchValues[0].replace(/\s/g, ""));
        cityFilter = cityName ? `&city=${cityName}` : "";
      } else if (searchValues.length === 2) {
        const stateName = searchValues[0].trim();
        const stateAbbreviation = usStateCodes.getStateCodeByStateName(stateName) || stateName;
        stateFilter = stateAbbreviation ? `&state=${stateAbbreviation}` : "";
      }
      const propertyTypes = Object.values(selectedType).flat();
      const typeFilter = propertyTypes.length > 0 ? `&propertyType=${propertyTypes.join(",")}` : "";
      const minPrice = selectedBudget.minPrice || "";
      const maxPrice = selectedBudget.maxPrice || "";
      const priceFilter = minPrice && maxPrice ? `&minPrice=${minPrice}&maxPrice=${maxPrice}` : minPrice ? `&minPrice=${minPrice}` : maxPrice ? `&maxPrice=${maxPrice}` : "";
      const newConstructionFilter = isNewConstructionSelected ? "&newConstruction=true" : "";
      const bedroomsFilter = selectedFeatures.bedrooms ? `&minBedRoom=${selectedFeatures.bedrooms}` : "";
      const bathroomsFilter = selectedFeatures.bathrooms ? `&minBathRoom=${selectedFeatures.bathrooms}` : "";
      const sqftFilter = selectedFeatures.minSqft || selectedFeatures.maxSqft ? `&minArea=${selectedFeatures.minSqft || ""}&maxArea=${selectedFeatures.maxSqft || ""}` : "";
      let lotSizeFilter = "";
      if ((selectedFeatures.minLotSizeAcres || selectedFeatures.maxLotSizeAcres) && (selectedFeatures.minLotSizeSqft || selectedFeatures.maxLotSizeSqft)) {
        const minLotSizeAcres = selectedFeatures.minLotSizeAcres || 0;
        const maxLotSizeAcres = selectedFeatures.maxLotSizeAcres;
        const minLotSizeSqft = selectedFeatures.minLotSizeSqft || 0;
        const maxLotSizeSqft = selectedFeatures.maxLotSizeSqft;
        lotSizeFilter = `&minTotalAreaAcres=${minLotSizeAcres}&maxTotalAreaAcres=${maxLotSizeAcres}&minTotalAreaSqft=${minLotSizeSqft}&maxTotalAreaSqft=${maxLotSizeSqft}`;
      } else if (selectedFeatures.minLotSizeAcres || selectedFeatures.maxLotSizeAcres) {
        const minLotSizeAcres = selectedFeatures.minLotSizeAcres || 0;
        const maxLotSizeAcres = selectedFeatures.maxLotSizeAcres;
        lotSizeFilter = `&minTotalAreaAcres=${minLotSizeAcres}&maxTotalAreaAcres=${maxLotSizeAcres}`;
      } else if (selectedFeatures.minLotSizeSqft || selectedFeatures.maxLotSizeSqft) {
        const minLotSizeSqft = selectedFeatures.minLotSizeSqft || 0;
        const maxLotSizeSqft = selectedFeatures.maxLotSizeSqft;
        lotSizeFilter = `&minTotalAreaSqft=${minLotSizeSqft}&maxTotalAreaSqft=${maxLotSizeSqft}`;
      }
      const garageSpacesFilter = selectedGarage ? `&GarageSpaces=${selectedGarage}` : "";
      const storiesFilter = selectedStories ? `&Stories=${selectedStories}` : "";
      const parkingTotalFilter = selectedParkingSpace ? `&ParkingTotal=${selectedParkingSpace}` : "";
      const yearBuiltFilter = yearBuilt.minYear || yearBuilt.maxYear ? `&minYear=${yearBuilt.minYear || ""}&maxYear=${yearBuilt.maxYear || ""}` : "";
      const userIdParam = userId ? `&userId=${userId}` : "";

      //Api url
      const filterString = `${sortFilter}${cityFilter}${stateFilter}${typeFilter}${priceFilter}${bedroomsFilter}${bathroomsFilter}${sqftFilter}${lotSizeFilter}${garageSpacesFilter}${storiesFilter}${parkingTotalFilter}${yearBuiltFilter}${userIdParam}${newConstructionFilter}`;
      const apiUrl = `${Url.BASEURL}/api/mls/reso/${type}?domain=${Domain}&page=${page + 1}&size=${pageSize}${filterString}`;
      const response = await axios.get(apiUrl);


      if (response.data) {
        const properties = Array.isArray(response.data.properties.docs) ? response.data.properties.docs : [];
        const totalResults = response.data.properties.total || 0;
        setPropertyData(properties);
        setTotalResults(totalResults);
      } else {
        console.error("API response does not have the expected structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //Function for showing properties
  useEffect(() => {
    if (isTriggeredsearch && initialLoad) {
      fetchData(pageNumber, false);
      setisTriggeredsearch(false);
    } else if (type && initialLoad) {
      fetchData(pageNumber, false);
      setisTriggeredsearch(false);
    } else if (isTriggeredsearch && !initialLoad) {
      fetchData(pageNumber, false);
      setisTriggeredsearch(false);
    } else if (initialLoad && !isTriggeredsearch) {
      fetchData(pageNumber, true);
      setInitialLoad(false);
    } else if (selectedOption) {
      fetchData(pageNumber, true);
    } else if (selectedSearchvalue) {
      fetchData();
    }
  }, [pageNumber, isTriggeredsearch, initialLoad, type, selectedOption, selectedSearchvalue]);

  //Scroller function
  const handleScroll = () => {
    const element = document.documentElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //Function to changes page
  useEffect(() => {
    if (isAtBottom && blockCount < totalResults) {
      setPageNumber((prevPage) => prevPage + 1);
      setIsAtBottom(false);
    }
  }, [isAtBottom, blockCount, totalResults]);

  //Function to changes page
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setPageNumber(selectedPage);
    setIsAtBottom(false);
    fetchData(selectedPage);
  };

  //Function to save properties
  const handleLocalHeartClick = (property, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (regdtoken) {
      const updatedProperties = propertyData.map((p) => (p.ListingKey === property.ListingKey ? { ...p, isWishlisted: !p.isWishlisted } : p));
      setPropertyData(updatedProperties);
    } else {
      console.log("No registration token, cannot like property locally.");
    }
  };

  //Function to save properties
  const handleHeartClick = async (property, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (regdtoken) {
      try {
        const response = await axios.post(
          `${Url.BASEURL}/api/user/create-wishlist`,
          {
            ListingKey: property.ListingKey,
          },
          {
            headers: {
              Authorization: `Bearer ${regdtoken}`, // Make sure to use regdtoken here
              "Content-Type": "application/json", // Change content type if necessary
            },
          }
        );
        setwishlistedProperties([...wishlistedProperties, { userId: UserIdtoken._id, ListingKey: property.ListingKey }]);
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    } else {
      console.log("No registration token, cannot like property.");
    }
  };

  //Loader
  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", marginTop: "200px" }}>
        <ClipLoader color={color} loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    );
  }

  //Function to format open house property detail
  function formatOpenHouseSchedule(property) {
    if (property.OpenHouseStartTime && property.OpenHouseEndTime) {
      const startTime = new Date(property.OpenHouseStartTime);
      const endTime = new Date(property.OpenHouseEndTime);
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      if (startTime.toDateString() === today.toDateString()) {
        return `Open Today ${formatTimeRange(startTime, endTime)}`;
      } else if (startTime.toDateString() === tomorrow.toDateString()) {
        return `Open Tomorrow ${formatTimeRange(startTime, endTime)}`;
      } else {
        const dayOfWeek = startTime.toLocaleDateString("en-US", { weekday: "short" });
        return `Open ${dayOfWeek} ${formatTimeRange(startTime, endTime)}`;
      }
    }
    return "";
  }

  //Function to format date
  function formatTimeRange(startTime, endTime) {
    return `${formatTime(startTime)} - ${formatTime(endTime)}`;
  }

  //Function to format date
  function formatTime(date) {
    return date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  }

  const handleModalDisplay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowModal(true);
  };

  const PostsDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  //Function to filter properties
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const formatPropertyType = (propertyType) => {
    if (propertyType === null || propertyType === undefined) {
      return "";
    }
    return propertyType.replace(/([A-Z])/g, " $1").trim();
  };

  return (
    <div className="searched_properties">
      <div className="container">
        <div className="row crow">
          <div className="col-lg-12 ">
            {propertyData.length > 0 && (
              <div className="property_drop_felxing d-flex fff">
                <div className="blocks_counting">{totalResults > 0 && <span>{` ${pageNumber * pageSize + 1}-${Math.min((pageNumber + 1) * pageSize, totalResults)} of ${totalResults} Results`}</span>}</div>
                <div className="filter_area">
                  <div className="">
                    <div className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                      <div className="selected_option" onClick={PostsDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                        {selectedOption}
                      </div>
                      {isDropdownOpen && (
                        <div className="drop_selection">
                          {Sortoption.map((option, index) => (
                            <p key={index} onClick={() => handleOptionSelect(option)}>
                              {option}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row set_block clock" ref={rowRef}>
          {propertyData.length === 0 ? (
            <div className="no_propertor"> No properties available</div>
          ) : (
            propertyData.map((property, idx) => (
              <div className="col-lg-4 col-md-4" key={property.ListingKey}>
                <Link to={`/property-detail/${property.ListingKey}`}>
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                      <div className="left-overlays">
                        {property.OpenHouseStartTime && property.OpenHouseEndTime && <button className="featured">{formatOpenHouseSchedule(property)}</button>}
                        {property.BankOwnedYn && <button className="featured"> REO / Bank Owned</button>}
                        {/* <button className="featured">Featured</button> */}
                        {/* <button className="featured hot">Hot</button> */}
                      </div>
                      <div className="right-overlays d-flex">
                        <div className="right_single_block d-flex">
                          <div className="left_single_icon">
                            <img src="/assets/camera.svg" alt="Camera icon" />
                          </div>
                          <div className="right_single_text">{property.BathroomsPartial && String(property.BathroomsPartial)}</div>
                        </div>
                        <div className="right_single_block d-flex">
                          <div className="left_single_icon">
                            <img src="/assets/video.svg" alt="Video icon" />
                          </div>
                          <div className="right_single_text">{property.BathroomsTotalInteger && String(property.BathroomsTotalInteger)}</div>
                        </div>
                        <div className="circle_heart d-flex">
                          <div className="left_single_icon heart_set">
                            <img
                              src={property.isWishlisted ? liked : notliked}
                              alt="Heart icon"
                              onClick={(event) => {
                                if (regdtoken) {
                                  handleHeartClick(property, event);
                                  handleLocalHeartClick(property, event);
                                } else {
                                  handleModalDisplay(event);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <LazyLoadImage src={property.Photo1URL} alt="Property" className="img" effect="blur" placeholderSrc={alternative1} />

                    {/* <img src={property.Photo1URL} alt="Property" /> */}
                    <div className="bottom_property">
                      <h2>{property.PropertyName}</h2>
                      <div className="location_flex d-flex">
                        <div className="location_icon">
                          <img src="/assets/blue-location.png" alt="Location icon" />
                        </div>
                        <div className="location_name">{String(property.UnparsedAddress)}</div>
                      </div>
                      <div className="price-amenities d-flex">
                        <div className="price">
                          <span className="price-sign">$</span> {property.CurrentPriceForStatus && property.CurrentPriceForStatus.$numberDecimal ? Number(property.CurrentPriceForStatus.$numberDecimal).toLocaleString("en-US") : "N/A"}
                        </div>
                        <div className="amenities d-flex">
                          {property.BedroomsTotal && (
                            <div className="single_amenities d-flex">
                              <div className="left_amenities">
                                <img src="/assets/amenties2.png" alt="Amenities icon" />
                              </div>
                              <div className="right_amenities"> {property.BedroomsTotal !== null && String(property.BedroomsTotal)}</div>
                            </div>
                          )}

                          {property.BathroomsTotalInteger && (
                            <div className="single_amenities d-flex">
                              <div className="left_amenities">
                                <img src="/assets/amenties3.png" alt="Amenities icon" />
                              </div>
                              <div className="right_amenities"> {property.BathroomsTotalInteger !== null && String(property.BathroomsTotalInteger)}</div>
                            </div>
                          )}
                          <div className="single_amenities d-flex">
                            <div className="left_amenities">
                              <img src="/assets/amenties1.png" alt="Amenities icon" />
                            </div>
                            <div className="right_amenities">
                              {property.LotSizeSquareFeet} <span className="area">sq ft</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex_overal">
                      <div className="first_flex_overaly">{formatPropertyType(property.PropertySubType)}</div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
          <WishListWithoutLogin showModal={showModal} setShowModal={setShowModal} />
        </div>

        {propertyData.length > 0 && <ReactPaginate pageCount={totalPages} pageRangeDisplayed={5} marginPagesDisplayed={1} onPageChange={handlePageClick} breakClassName="page-item" breakLinkClassName="page-link" containerClassName="paginationn" forcePage={pageNumber} activeClassName="active" breakLabel="..." previousLabel={<GoChevronLeft />} nextLabel={<GoChevronRight />} previousLinkClassName="custom-button" nextLinkClassName="custom-button" />}
      </div>
    </div>
  );
}

export default PropertySearchResult;
