import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import "./PropertySearchResult.css";
import ContactForm from "../../../ContactUs/ContactForm";
import { Link } from "react-router-dom";
import { useSelectedOptions } from "../../../SelectedOptionsContext"; // Import the context
import Url from '../../../Element/Api'

function PropertySearchResult() {
  const [itemsPerPage] = useState(20);
  const [cityList, setCityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { setSelectedBudget } = useSelectedOptions({ minPrice: "", maxPrice: "" });
  const { selectedSearchvalue, setselectedSearchvalue, setSearchValue } = useSelectedOptions();

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cityList.slice(indexOfFirstItem, indexOfLastItem);
  const Domain = window.location.hostname;

  //Function for fetching city list
  useEffect(() => {
    const fetchCityList = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/api/mls/reso/cities-list?domain=${Domain}`);
        setCityList(response.data.uniqueValues);
      } catch (error) {
        console.error("Error fetching city list:", error);
      }
    };

    fetchCityList();
  }, []);

  //Function for change page
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  //Function for saving search value
  const handleLinkClick = (minPrice, maxPrice, city) => {
    setSearchValue(city);
    setselectedSearchvalue(city);
    setSelectedBudget({ minPrice, maxPrice });
  };

  return (
    <div className="searchbyarea">
      <div className="overall_location">
        <div className="container">
          <div className="search_area">
            <h1>View by Locations</h1>
            <div className="row">
              <div className="col-lg-12">
                <div className="location_prop_listing all_location_only d-flex">
                  {currentItems.map((city, index) => (
                    <div key={index} className="single_prop_listing">
                      <img src={`/assets/properties/l${(index % 4) + 1}.png`} className="img-fluid" alt={`property list ${index}`} />
                      <button type="button" className="prop_btn" onClick={() => handlePageClick({ selected: currentPage }, city)}>
                        {city}
                      </button>
                      <div className="overlay">
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(1, 300000, city)}>
                            Under $ 300,000
                          </Link>
                        </p>
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(200000, 300000, city)}>
                            $ 200,000 to 300,000
                          </Link>
                        </p>
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(300000, 400000, city)}>
                            $ 300,000 to 400,000
                          </Link>
                        </p>
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(400000, 500000, city)}>
                            $ 400,000 to 500,000
                          </Link>
                        </p>
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(500000, 600000, city)}>
                            $ 500,000 to 600,000
                          </Link>
                        </p>
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(700000, 800000, city)}>
                            $ 700,000 to 800,000
                          </Link>
                        </p>
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(900000, 1000000, city)}>
                            $ 900,000 to 100,000
                          </Link>
                        </p>
                        <p>
                          <Link to="/search-result" onClick={() => handleLinkClick(1000000, Infinity, city)}>
                            Luxury Homes
                          </Link>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate breakLabel={"..."} breakClassName={"break-me"} pageCount={Math.ceil(cityList.length / itemsPerPage)} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={(data) => handlePageClick(data, selectedSearchvalue)} containerClassName="paginationn" activeClassName={"active"} previousLabel={<GoChevronLeft />} nextLabel={<GoChevronRight />} previousLinkClassName="custom-button" nextLinkClassName="custom-button" />
      <ContactForm />
    </div>
  );
}

export default PropertySearchResult;
