import React from "react";
import "./Map.css";
import liked from "../../../../public/assets/like.svg";

const PropertyInfoWindow = ({ property }) => {
  return (
    <div className="property-cards">
      <a href={`/property-detail/${property.ListingKey}`}>
        <div className="property-overlays d-flex">
          <div className="right-overlays d-flex">
            <div className="circle_heart d-flex">
              <div className="left_single_icon heart_set">
                <img src={liked} alt="Wovnn heart-icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex_overal"></div>
        <img className="property-image" src={property.Photo1URL} alt={property.ListingKey} />

        <div className="bottom_property">
          <div className="location_flex d-flex">
            <div className="location_icon">
              <img src="/assets/blue-location.png" alt="Wovnn location-icon" />
            </div>
            <div className="location_name">{property.UnparsedAddress}</div>
          </div>
          <div className="price-amenities d-flex">
            <div className="price">
              <span className="price-sign">$</span>
              {property.CurrentPriceForStatus && property.CurrentPriceForStatus.$numberDecimal}
            </div>

            <div className="amenities d-flex">
              <div className="single_amenities d-flex">
                <div className="left_amenities">
                  <img src="/assets/amenties2.png" alt="Wovnn amenities-icon" />
                </div>
                <div className="right_amenities">{property.BathroomsTotalInteger}</div>
              </div>
              <div className="single_amenities d-flex">
                <div className="left_amenities">
                  <img src="/assets/amenties1.png" alt="Wovnn amenities-icon" />
                </div>
                <div className="right_amenities">{property.BedroomsTotal}</div>
              </div>
              <div className="single_amenities d-flex">
                <div className="left_amenities">
                  <img src="/assets/amenties3.png" alt="Wovnn amenities-icon" />
                </div>
                <div className="right_amenities">
                  {property.BuildingAreaCalculated?.$numberDecimal || ""}
                  <span className="area">sq ft</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default PropertyInfoWindow;
