import React, { useState, useEffect, useRef } from "react";
import "./PropertyFeaturesDropdown.css";
import Sliders from "../../../public/assets/s3.svg";
import Bedrooms from "../../../public/assets/amenties2.png";
import Shower from "../../../public/assets/amenties3.png";
import { useSelectedOptions } from "../../SelectedOptionsContext";

function PropertyFeaturesDropdown() {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [lotSizeUnit, setLotSizeUnit] = useState("acres");
  const { selectedFeatures, setSelectedFeatures } = useSelectedOptions();
  const [displayedFeatures, setDisplayedFeatures] = useState("Property Features");

  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);
    useEffect(() => {
        console.log("selectedfeatures", selectedFeatures)
    }, [selectedFeatures])
 const handleInputChange = (e) => {
    const { name, value } = e.target;

    const isLotSizeFieldWithDecimal = name.includes('LotSize') && value !== '' && /^\d*\.?\d*$/.test(value);

    // Check if the input value is empty or a positive integer or a positive number with a decimal point (for Lot Size)
    if (value === '' ||/^[0-9]+$/.test(value) || isLotSizeFieldWithDecimal  ) {
        setSelectedFeatures({
            ...selectedFeatures,
            [name]: value
        });
    }
};



 useEffect(() => {
    let displayedFeatures = 'Property Features';

    if (selectedFeatures.bedrooms || selectedFeatures.bathrooms ||
        selectedFeatures.minSqft || selectedFeatures.maxSqft ||
        selectedFeatures.minLotSizeAcres || selectedFeatures.maxLotSizeAcres ||
        selectedFeatures.minLotSizeSqft || selectedFeatures.maxLotSizeSqft) {
        
        displayedFeatures = '';

      if (selectedFeatures.bedrooms) {
        displayedFeatures += `${selectedFeatures.bedrooms} Bedrooms, `;
      }
      if (selectedFeatures.bathrooms) {
        displayedFeatures += `${selectedFeatures.bathrooms} Bathrooms, `;
      }
      if (selectedFeatures.minSqft || selectedFeatures.maxSqft) {
        displayedFeatures += `Square Feet: ${selectedFeatures.minSqft || "0"} - ${selectedFeatures.maxSqft || "Any"}, `;
      }
      if (selectedFeatures.minLotSizeAcres || selectedFeatures.maxLotSizeAcres) {
        displayedFeatures += `Lot Size (acres): ${selectedFeatures.minLotSizeAcres || "0"} - ${selectedFeatures.maxLotSizeAcres || "Any"}, `;
      }
      if (selectedFeatures.minLotSizeSqft || selectedFeatures.maxLotSizeSqft) {
        displayedFeatures += `Lot Size (sqft): ${selectedFeatures.minLotSizeSqft || "0"} - ${selectedFeatures.maxLotSizeSqft || "Any"}, `;
      }

      displayedFeatures = displayedFeatures.replace(/, $/, "");
    }

    setDisplayedFeatures(displayedFeatures);
  }, [selectedFeatures]);

  return (
    <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
      <div className="sel_icon">
        <img src={Sliders} alt="propertyType" />
      </div>
      <div className="sel_text">{displayedFeatures}</div>

            {isOpen && (
                <div className="property_drop features_list" onClick={handleDropdownClick}>
                    <div className="two_input_blocks d-flex">
                        <div className="left_input_blocks">
                            <div className="icon_feature"><img src={Bedrooms} alt="bedrooms" /></div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="No. of Bedrooms"
                                name="bedrooms"
                                value={selectedFeatures.bedrooms || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="right_input_blocks">
                            <div className="icon_feature"><img src={Shower} alt="shower" /></div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="No. of Bathrooms"
                                name="bathrooms"
                                value={selectedFeatures.bathrooms || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <h3>Square Feet</h3>
                    <div className="two_input_blocks d-flex square_ft">
                        <div className="left_input_blocks">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Min sqft"
                                name="minSqft"
                                value={selectedFeatures.minSqft || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="right_input_blocks">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Max sqft"
                                name="maxSqft"
                                value={selectedFeatures.maxSqft || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex acre_swap">
                        <div className="left_swap"><h3>Lot Size</h3></div>
                        <div className="right_swap">
                            <div className="unique-radio-wrapper">
                                <input
                                    type="radio"
                                    id="uniqueRadio1"
                                    name="lotSizeUnit"
                                    value="acres"
                                    checked={lotSizeUnit === 'acres'}
                                    onChange={(e) => setLotSizeUnit(e.target.value)}
                                />
                                <label htmlFor="uniqueRadio1" className="unique-radio-label"><span>acres</span></label>
                                <input
                                    type="radio"
                                    id="uniqueRadio2"
                                    name="lotSizeUnit"
                                    value="sqft"
                                    checked={lotSizeUnit === 'sqft'}
                                    onChange={(e) => setLotSizeUnit(e.target.value)}
                                />
                                <label htmlFor="uniqueRadio2" className="unique-radio-label"><span>sqft</span></label>
                            </div>
                        </div>
                    </div>
                    <div className="two_input_blocks d-flex square_ft">
                        <div className="left_input_blocks">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={`Min ${lotSizeUnit === 'acres' ? 'acres' : 'sqft'}`}
                                name={`minLotSize${lotSizeUnit === 'acres' ? 'Acres' : 'Sqft'}`}
                                value={selectedFeatures[`minLotSize${lotSizeUnit === 'acres' ? 'Acres' : 'Sqft'}`] || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="right_input_blocks">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={`Max ${lotSizeUnit === 'acres' ? 'acres' : 'sqft'}`}
                                name={`maxLotSize${lotSizeUnit === 'acres' ? 'Acres' : 'Sqft'}`}
                                value={selectedFeatures[`maxLotSize${lotSizeUnit === 'acres' ? 'Acres' : 'Sqft'}`] || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default PropertyFeaturesDropdown;
