import React, { useState, useEffect } from "react";
import "./PropertyDetail.css";
import { faMapMarkerAlt, faChevronRight, faChevronLeft, faTimes, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { faClock, faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListingCompany from "../../public/listing-company.png";
import PostedBy from "../../public/posted-by.png";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import MonthlyPaymentCalculator from "../MonthlyPaymentCalculator/MonthlyPaymentCalculator";
import PropertySidebarForm from "../PropertySidebarForm/PropertySidebarForm";
import { useLocation } from "react-router-dom";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { useParams } from "react-router-dom";
import Breadcrumb from "../breadcrum/breadcrum";
import Directionservice from "./Direction.js";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import alternative1 from "../../public/alternative1.jpg";
import WishListWithoutLogin from "../HomePage/PropertyListing/WishListWithoutLogin.js";
import Url from "../Element/Api";

const items = [
  { name: "United States", path: "/usa" },
  { name: "Oregon", path: "/usa/oregon" },
  { name: "Beaverton", path: "/usa/oregon/beaverton" },
];

const data = [
  { year: 2020, month: "Jan", value: 20000 },
  { year: 2021, month: "Jan", value: 100000 },
  { year: 2022, month: "Jan", value: 350000 },
  { year: 2023, month: "Aug", value: 350000 },
];

function PropertyDetail() {
  const itemsPerPage = 5;
  const { id } = useParams();
  const location = useLocation();
  const UserIdtoken = useSelector((state) => state.jwtuser);
  const regdtoken = UserIdtoken.jwtToken;
  const userId = UserIdtoken._id;
  const routeItems = location.pathname.split("/").filter((item) => item);
  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showmodal2, setshowmodal2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [color, setColor] = useState("#22A9E0");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("brookhaven");
  const [propertyData, setPropertyData] = useState();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const actualRating = 4.5;
  const fullStars = Math.floor(actualRating);
  const halfStars = actualRating % 1 > 0 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/mls/reso/property/${id}??expand=Media,Member&select=ListingKey,ModificationTimestamp,Latitude,Longitude,StandardStatus,BedroomsTotal,BathroomsTotalInteger,Country,City,PostalCode,CurrentPriceForStatus,Photo1URL,BuildingAreaCalculated,UnparsedAddress,PublicRemarks,ListAgentFullName,OnMarketDate,ModificationTimestamp,PropertyType,PropertySubType,YearBuilt,BuildingAreaTotal,FireplaceFeatures,Stories,Sewer,ArchitecturalStyle,TaxYear,TaxAnnualAmount,Country,Directions,ListingService,LotFeatures,LotSizeRange,MlsStatus,ListingTerms,MainLevelAreaTotal,Basement,BuildingAreaCalculated,Roof,BuildingAreaDescription,ExteriorDescription,FuelDescription,Heating,UpperLevelAreaTotal,WaterSource,ParkingFeatures,PropertyCondition,RoadSurfaceType,LotSizeAcres,LotSizeSquareFeet&userId=${userId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setPropertyData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setshowmodal2(true);
  };

  const handlePrevClick = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : propertyData.Media.length - 1));
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex < propertyData.Media.length - 1 ? prevIndex + 1 : 0));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTabClick = (event, tabName) => {
    event.preventDefault();
    setActiveTab(tabName);
  };

  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "var(--color-white-pure, #FFF)",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid var(--color-secondary-dark-light, #505564)",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <p className="label" style={{ margin: 0 }}>
            {dataPoint.month}
          </p>
          <p className="intro" style={{ margin: 0 }}>{`Value: $${dataPoint.value / 1000}k`}</p>
        </div>
      );
    }

    return null;
  }

  const renderStars = () => {
    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStar} className="star" />
        ))}
        {[...Array(halfStars)].map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStarHalfAlt} className="star" />
        ))}
        {[...Array(emptyStars)].map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStarEmpty} className="star" />
        ))}
      </>
    );
  };

  const handleSaveClick = async () => {
    setIsActive(!isActive);

    try {
      if (regdtoken) {
        const response = await axios.post(
          `${Url.BASEURL}/api/user/create-wishlist`,
          {
            ListingKey: propertyData.ListingKey,
          },
          {
            headers: {
              Authorization: `Bearer ${regdtoken}`,
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        console.log("No registration token, cannot save property to wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };

  const handleLocalHeartClick = () => {
    if (regdtoken && propertyData) {
      const updatedPropertyData = { ...propertyData, isWishlisted: !propertyData.isWishlisted };
      setPropertyData(updatedPropertyData);
    } else {
      console.log("No registration token or property data, cannot like property locally.");
    }
  };

  const handleModalDisplay = (event) => {
    setShowModal(true);
  };

  function formatDate(timestamp) {
    if (!isNaN(new Date(timestamp).getTime())) {
      return new Date(timestamp).toISOString().split("T")[0];
    } else {
      return "Invalid Date";
    }
  }

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", paddingTop: "6%" }}>
        <ClipLoader color={color} loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    );
  }
  return (
    <div className="Property-Detail-container">
      {propertyData && (
        <>
          <div className="propert-banner-bg">
            <div className="container-fluid">
              <Breadcrumb items={routeItems} />
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="btn_setting">
                    <button
                      id="saveListingButton"
                      className={`save-listing ${propertyData && propertyData.isWishlisted ? "active-button" : ""}`}
                      onClick={() => {
                        if (regdtoken) {
                          handleSaveClick();
                          handleLocalHeartClick();
                        } else {
                          handleModalDisplay();
                        }
                      }}
                    >
                      Save Listing <FontAwesomeIcon icon={faHeart} className="heart-icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 set_widthe">
                  <div className="Property_detailing">
                    <div className="bottom_property">
                      <div className="location_flex d-flex">
                        <div className="location_icon">
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </div>
                        <div className="location_name">{propertyData.UnparsedAddress}</div>
                      </div>

                      <div className="price-amenities ">
                        <div className="amenities d-flex">
                          <div className="single_amenities d-flex">
                            <div className="left_amenities">
                              <img src="/assets/amenties2.png" alt="Wovnn amenities-icon" />
                            </div>
                            <div className="right_amenities">{propertyData.BedroomsTotal}</div>
                          </div>
                          <div className="single_amenities d-flex">
                            <div className="left_amenities">
                              <img src="/assets/amenties3.png" alt="Wovnn amenities-icon" />
                            </div>
                            <div className="right_amenities">{propertyData.BathroomsTotalInteger}</div>
                          </div>
                          <div className="single_amenities d-flex">
                            <div className="left_amenities">
                              <img src="/assets/prop.svg" alt="Wovnn amenities-icon" />
                            </div>
                            <div className="right_amenities">
                              {propertyData.BuildingAreaCalculated}
                              <span className="area">sq ft</span>
                            </div>
                          </div>
                          <div className="single_amenities d-flex">
                            <div className="left_amenities">
                              <img src="/assets/amenties1.png" alt="Wovnn amenities-icon" />
                            </div>
                            <div className="right_amenities">{`${propertyData.LotSizeAcres} Acres`}</div>
                          </div>
                        </div>

                        <div className="price">
                          <span className="price-sign">$</span> {Number(propertyData.CurrentPriceForStatus).toLocaleString("en-US")}
                        </div>
                        <div className="avm">
                          AVM $<span className="avm_price">4,006</span>/month
                        </div>

                        <div className="stars_rating d-flex">
                          <div className="rate_number">{actualRating}</div>
                          <div className="stars">{renderStars()}</div>
                          <div className="total_rate">(250)</div> {/* Assuming 250 is the number of ratings */}
                        </div>

                        <div className="published d-flex mt-new">
                          <div className="detail_icon con_pun">
                            <FontAwesomeIcon icon={faClock} />
                          </div>

                          <div className="detail_icon mar_lefts"> Published on : </div>
                          <div className="detail_icon marl">{propertyData.OnMarketDate}</div>
                        </div>
                        <div className="published d-flex updated">
                          <div className="detail_icon con_pun">
                            <FontAwesomeIcon icon={faRefresh} />
                          </div>

                          <div className="detail_icon mar_lefts"> Last Updated on : </div>
                          <div className="detail_icon marl">{formatDate(propertyData.ModificationTimestamp)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 set_widthes">
                  {propertyData && (
                    <>
                      <div className="row mb-3">
                        {propertyData.Media &&
                          propertyData.Media.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((media, index) => {
                            const actualIndex = (currentPage - 1) * itemsPerPage + index;
                            return (
                              <div key={actualIndex} className={`col-lg-${index < 2 ? "6" : "4"} col-md-12 col-sm-12`}>
                                <LazyLoadImage src={media.MediaURL} alt={media.LongDescription || `Image ${actualIndex + 1}`} className="img-fluid custom-image clickable settable" effect="blur" placeholderSrc={alternative1} onClick={() => handleImageClick(actualIndex)} />
                              </div>
                            );
                          })}
                      </div>

                      {/* Pagination */}
                      {propertyData.Media && propertyData.Media.length > itemsPerPage && (
                        <div className="pagination">
                          {Array.from({ length: Math.ceil(propertyData.Media.length / itemsPerPage) }, (_, index) => (
                            <button key={index + 1} onClick={() => handlePageChange(index + 1)} className={currentPage === index + 1 ? "active" : ""}>
                              {index + 1}
                            </button>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                  {/* Modal */}
                  {showmodal2 && (
                    <div className="modal show d-block" tabIndex="-1">
                      <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                          <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setshowmodal2(false)}>
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                            {/* Navigation arrows and Image */}
                            {currentImageIndex > 0 && <FontAwesomeIcon icon={faChevronLeft} className="position-absolute start-0 mt-5 clickable" onClick={handlePrevClick} />}

                            {propertyData.Media.map((media, idx) => (
                              <img key={idx} src={media.MediaURL} alt="img" className={`img-fluid modal-image ${currentImageIndex === idx ? "active" : ""}`} />
                            ))}

                            {currentImageIndex < propertyData.Media.length - 1 && <FontAwesomeIcon icon={faChevronRight} className="position-absolute end-0 mt-5 clickable" onClick={handleNextClick} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="three_btns">
                    <button className="btn_blue">Get Pre Approved</button>
                    <button className="btn_blue">Schedule an Appointment</button>
                    <button className="btn_blue">Make an Offer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="prop_detail_form">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="overall_property_detail">
                    <h2>About this Property</h2>
                    <p>{propertyData.PublicRemarks}</p>

                    <div className="span_text_logo d-flex">
                      <div className="span_text">
                        Listed by <span className="lister_name">{propertyData.ListAgentFullName}</span> • <span className="group_name">Premiere Property Group, LLC</span>
                      </div>
                      <div className="span_text_logo">
                        <img src={ListingCompany} alt="Listing Company" />
                      </div>
                    </div>

                    <h3>Home Facts</h3>

                    <div className="overall_facts_features d-flex">
                      <div className="left_hand ">
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Listing Status</div>
                          <div className="update_detail_right">For Sale</div>
                        </div>
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Property Type</div>
                          <div className="update_detail_right">{propertyData.PropertyType}</div>
                        </div>
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Sub Type</div>
                          <div className="update_detail_right">{propertyData.PropertySubType}</div>
                        </div>
                      </div>
                      <div className="left_hand right_hand">
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Full Baths</div>
                          <div className="update_detail_right">{propertyData.BathroomsTotalInteger}</div>
                        </div>
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Year Built</div>
                          <div className="update_detail_right">{propertyData.YearBuilt}</div>
                        </div>
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Lot Size</div>
                          <div className="update_detail_right">{propertyData.BuildingAreaTotal}</div>
                        </div>
                      </div>
                    </div>

                    <h3 className="features_all">Features</h3>

                    <div className="overall_facts_features d-flex">
                      <div className="left_hand ">
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Fireplace(s)</div>
                          <div className="update_detail_right">{propertyData.FireplaceFeatures}</div>
                        </div>
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Stories</div>
                          <div className="update_detail_right">{propertyData.Stories}</div>
                        </div>
                      </div>
                      <div className="left_hand right_hand">
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Sewer</div>
                          <div className="update_detail_right">{propertyData.Sewer}</div>
                        </div>
                        <div className="full_left_hand d-flex">
                          <div className="update_detail_left">Style</div>
                          <div className="update_detail_right">{propertyData.ArchitecturalStyle}</div>
                        </div>
                      </div>
                    </div>

                    {/* **************************************Map****************************************/}
                    <Directionservice />
                    {/* <div className="map_area">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96873.55927017267!2d-74.02739794492494!3d40.64534496917392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24416947c2109%3A0x82765c7404007886!2sBrooklyn%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1695635629907!5m2!1sen!2sin" width="100%" height="350" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
                      <div className="write_location">
                        <div className="icon_locate">
                          <img src={Car} alt="location" />
                        </div>
                        <input type="text" className="form-control location_control" placeholder="Write Location Name here to Calculate Distance"></input>
                      </div>
                    </div> */}

                    {/* ****************************************Map**************************************/}

                    {/* ****************************************Taxex to--others**************************************/}
                    <div className="full-white-block mt50">
                      <h3>Taxes</h3>
                      <div className="all_points d-flex">
                        <div className="left_points">
                          <p>Tax Year : {propertyData.TaxYear}</p>
                        </div>
                        <div className="left_points right_points">
                          <p>Tax Annual Amount : ${propertyData.TaxAnnualAmount}</p>
                        </div>
                      </div>
                    </div>

                    <div className="full-white-block">
                      <h3>Location</h3>
                      <div className="all_points d-flex">
                        <div className="left_points">
                          <p>Country : {propertyData.Country}</p>
                        </div>
                        <div className="left_points right_points">
                          <p>Directions : {propertyData.Directions}</p>
                        </div>
                      </div>
                    </div>

                    <div className="full-white-block">
                      <h3>Property Detail</h3>
                      <div className="all_points d-flex">
                        <div className="left_points">
                          <p>Listing Service : {propertyData.ListingService}</p>
                          <p>Lot Features : {propertyData.LotFeatures}</p>
                          <p>Lot in Square Feet: {propertyData.LotSizeSquareFeet} sq ft</p>

                          <p>MLS Status : {propertyData.MlsStatus}</p>
                        </div>
                        <div className="left_points right_points">
                          <p>Listing Term : {propertyData.ListingTerms}</p>
                          <p>Lot Size Range : {propertyData.LotSizeRange}</p>
                          <p>Main Level Area Total : {propertyData.MainLevelAreaTotal}</p>
                          <p>Main Type : Residential</p>
                        </div>
                      </div>
                    </div>

                    <div className="full-white-block">
                      <h3>Exterior</h3>
                      <div className="all_points d-flex">
                        <div className="left_points">
                          <p>Basement : {propertyData.Basement}</p>
                          <p>Accessibility : Main Floor Bathroom Bath</p>
                          <p>Area : {propertyData.BuildingAreaTotal}</p>
                          <p>Roof : {propertyData.Roof}</p>
                        </div>
                        <div className="left_points right_points">
                          <p>Building Area Calculated : {propertyData.BuildingAreaCalculated}</p>
                          <p>Building Area Description : {propertyData.BuildingAreaDescription}</p>
                          <p>Exterior Description : {propertyData.ExteriorDescription}</p>
                          <p>Foundation Details : Concrete Perimeter</p>
                        </div>
                      </div>
                    </div>

                    <div className="full-white-block">
                      <h3>Interior</h3>
                      <div className="all_points d-flex">
                        <div className="left_points">
                          <p>Bathrooms Full Main Level : 1</p>
                          <p>Bathrooms Full Upper Level : 2</p>
                          <p>Bathrooms Total Main Level : 1.0</p>
                          <p>Bathrooms Total Upper Level : 2.0</p>
                        </div>
                        <div className="left_points right_points">
                          <p>Room 07 : 2nd Bedroom, On Upper Level</p>
                          <p>Room 08 : 3rd Bedroom, On Upper Level</p>
                          <p>Room 09 : Dining Room, On Main Level</p>
                          <p>Room 10 : Family Room, On Main Level</p>
                          <p>Room 11 : Kitchen, On Main Level</p>
                          <p>Room 12 : Living Room, On Main Level</p>
                          <p>Room 13 : Primary Bedroom, On Upper Level</p>
                        </div>
                      </div>
                    </div>

                    <div className="full-white-block">
                      <h3>Utilities </h3>
                      <div className="all_points d-flex">
                        <div className="left_points">
                          <p>Fuel Description : {propertyData.FuelDescription}</p>
                          <p>Heating : {propertyData.Heating}</p>
                        </div>
                        <div className="left_points right_points">
                          <p>Upper Level Area Total : {propertyData.UpperLevelAreaTotal}</p>
                          <p>Water Source : {propertyData.WaterSource}</p>
                        </div>
                      </div>
                    </div>

                    <div className="full-white-block">
                      <h3>Others </h3>
                      <div className="all_points d-flex">
                        <div className="left_points">
                          <p>Special Conditions : REO / Bank Owned</p>
                          <p>Parking : {propertyData.ParkingFeatures}</p>
                        </div>
                        <div className="left_points right_points">
                          <p>Property Conditions : {propertyData.PropertyCondition}</p>
                          <p>Road Surface Type : {propertyData.RoadSurfaceType}</p>
                        </div>
                      </div>
                    </div>

                    {/* ****************************************Taxex to--others**************************************/}

                    {/* ****************************************Listing-Deail**************************************/}

                    <div className="listingdetail">
                      <p>Details provided by RMLS and may not match the public record. Learn more.</p>
                      <p>Listing provided courtesy of: Premiere Property Group, LLC (503-670-9000)</p>
                      <p>Listing agent: Kelly White (503-720-0718)</p>
                      <p>All information provided is deemed reliable but is not guaranteed and should be independently verified. The content relating to real estate for sale on this web site comes in part from the IDX program of the RMLS of Portland Oregon. Real estate listings held by brokerage firms other than Redfin are marked with the RMLS logo and detailed information about these properties includes the names of the listing brokers. Copyright © 2023 RMLS, Portland, Oregon. Data Last Updated Thu Sep 07 2023</p>
                      <p>Last Updated: Sep 6, 2023 11:58 PM</p>
                    </div>

                    {/* ****************************************Listing-Deail**************************************/}

                    {/* ****************************************Schools**************************************/}

                    <div className="overall_schools">
                      <h3>Schools</h3>
                      <h4>
                        This home is within the{" "}
                        <span>
                          <strong>Beaverton School District 48j.</strong>
                        </span>
                      </h4>
                      <p>Showing nearby schools. Please check the school district website to see all schools serving this home.</p>

                      <div className="overall_facts_features d-flex schools">
                        <div className="left_hand ">
                          <div className="full_left_hand d-flex">
                            <div className="update_detail_left">7/10</div>
                            <div className="update_detail_right">
                              Oak Hills Elementary School
                              <div className="bottom_data d-flex">
                                <div className="school_code">Public, K-5</div>
                                <p>Serves this home</p>
                                <p> 0.2mi away</p>
                              </div>
                            </div>
                          </div>
                          <div className="full_left_hand d-flex">
                            <div className="update_detail_left">8/10</div>
                            <div className="update_detail_right">
                              Stoller Middle School
                              <div className="bottom_data d-flex">
                                <div className="school_code">Public, K-5</div>
                                <p>Serves this home</p>
                                <p> 0.2mi away</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="left_hand right_hand">
                          <div className="full_left_hand d-flex">
                            <div className="update_detail_left">4/10</div>
                            <div className="update_detail_right">
                              Five Oaks Middle School
                              <div className="bottom_data d-flex">
                                <div className="school_code">Public, K-5</div>
                                <p>Serves this home</p>
                                <p> 0.2mi away</p>
                              </div>
                            </div>
                          </div>
                          <div className="full_left_hand d-flex">
                            <div className="update_detail_left">7/10</div>
                            <div className="update_detail_right">
                              Sunset High School
                              <div className="bottom_data d-flex">
                                <div className="school_code">Public, K-5</div>
                                <p>Serves this home</p>
                                <p> 0.2mi away</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ****************************************Schools**************************************/}

                    {/* ***************************************Monthly-payment-estimate**************************************/}

                    <MonthlyPaymentCalculator currentPriceForStatus={propertyData.CurrentPriceForStatus} />

                    {/* ****************************************Monthly-payment-estimate**************************************/}

                    {/* ***********************************Listed By*******************************************/}
                    <div className="listed_by_detail">
                      <h2>Listed By</h2>

                      {/* First Row */}
                      <div className="first-row">
                        <img src={PostedBy} className="round-image" alt="postedby" />
                        <div className="text-section">
                          <h3>Premier Property Group, LLC</h3>
                          <p>Regional MLS (PMAR, EMAR, CCAR)</p>
                        </div>
                      </div>

                      {/* Second Row */}
                      <div className="second-row">
                        <textarea placeholder="Write a Message..."></textarea>
                      </div>

                      {/* Third Row */}
                      <div className="third-row">
                        <button className="custom_btn ask_ques">Ask a Question</button>
                        <span className="overall_call">
                          Text or call <span className="phone_update">554-5645-5478</span>
                        </span>
                      </div>
                    </div>

                    {/* ***********************************Listed By*******************************************/}

                    {/* ***********************************Market Report*******************************************/}
                    <div className="listed_by_detail market-report">
                      <h2>Market Report</h2>

                      <div className="mt-5">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <a className={`nav-link ${activeTab === "brookhaven" ? "custom-active" : "custom-inactive"}`} id="brookhaven-tab" onClick={(e) => handleTabClick(e, "brookhaven")} role="tab" aria-controls="brookhaven">
                              Brookhaven
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a className={`nav-link ${activeTab === "west-beaverton" ? "custom-active" : "custom-inactive"}`} id="west-beaverton-tab" onClick={(e) => handleTabClick(e, "west-beaverton")} role="tab" aria-controls="west-beaverton">
                              West Beaverton
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a className={`nav-link ${activeTab === "97007" ? "custom-active" : "custom-inactive"}`} id="97007-tab" onClick={(e) => handleTabClick(e, "97007")} role="tab" aria-controls="97007">
                              97007
                            </a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a className={`nav-link ${activeTab === "beaverton" ? "custom-active" : "custom-inactive"}`} id="beaverton-tab" onClick={(e) => handleTabClick(e, "beaverton")} role="tab" aria-controls="beaverton">
                              Beaverton
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content" id="myTabContent">
                          <div className={`tab-pane fade ${activeTab === "brookhaven" ? "show active" : ""}`} id="brookhaven" role="tabpanel" aria-labelledby="brookhaven-tab">
                            <div className="price-info">
                              <div className="price-column">
                                <h4>$145,250</h4>
                                <p>Median List Price</p>
                              </div>
                              <div className="price-column">
                                <h4>$245,250</h4>
                                <p>Median Sold Price</p>
                              </div>
                              <div className="price-column">
                                <h4>+3.2%</h4>
                                <p>Median Sold/List %</p>
                              </div>
                              <div className="price-column">
                                <h4>12 Months</h4>
                                <p>Avg Days on Market</p>
                              </div>
                            </div>

                            <div className="dropdown-wrapper">
                              <select className="custom-dropdown">
                                <option value="Median Home Prices">Median Home Prices</option>
                                <option value="Option 2">Option 2</option>
                                <option value="Option 3">Option 3</option>
                                {/* Add more options as needed */}
                              </select>
                            </div>

                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="10.64%" stopColor="rgba(34, 169, 224, 1)" />
                                    <stop offset="95.84%" stopColor="rgba(34, 169, 224, 1)" />
                                  </linearGradient>
                                </defs>

                                <CartesianGrid strokeDasharray="1 0" vertical={false} stroke="var(--color-white-blue, #F0F5FF)" strokeWidth={2} />
                                <XAxis dataKey="year" ticks={[2021, 2022, 2023]} axisLine={false} tickLine={false} />
                                <YAxis ticks={[0, 200000, 400000, 600000, 800000, 1000000]} tickFormatter={(tick) => `$${tick / 1000}k`} />
                                <Tooltip content={<CustomTooltip />} />
                                <Area type="monotone" dataKey="value" stroke="var(--color-secondary-dark-light, #505564)" fill="url(#colorUv)" />
                              </AreaChart>
                            </ResponsiveContainer>
                          </div>
                          <div className={`tab-pane fade ${activeTab === "west-beaverton" ? "show active" : ""}`} id="west-beaverton" role="tabpanel" aria-labelledby="west-beaverton-tab">
                            This is content for West Beaverton tab.
                          </div>
                          <div className={`tab-pane fade ${activeTab === "97007" ? "show active" : ""}`} id="97007" role="tabpanel" aria-labelledby="97007-tab">
                            This is content for 97007 tab.
                          </div>
                          <div className={`tab-pane fade ${activeTab === "beaverton" ? "show active" : ""}`} id="beaverton" role="tabpanel" aria-labelledby="beaverton-tab">
                            This is content for Beaverton tab.
                          </div>
                        </div>

                        <div className="property-details">
                          <div className="header-section">
                            <div className="header-column">
                              <p>Location</p>
                            </div>
                            <div className="header-column">
                              <p className="header-top">Data</p>
                              <p className="header-bottom">May 2022</p>
                            </div>
                            <div className="header-column">
                              <p className="header-top">Growth</p>
                              <p className="header-bottom">% YoY</p>
                            </div>
                          </div>

                          <div className="data-section">
                            <div className="data-column firstp">
                              <p>Wilton Manors</p>
                            </div>
                            <div className="data-column">
                              <p>$ 782,000</p>
                            </div>
                            <div className="data-column">
                              <p>+1.5%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ***********************************Market Report*******************************************/}
                  </div>
                </div>
                <div className="col-lg-4  offset-lg-1">
                  <PropertySidebarForm />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <WishListWithoutLogin showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default PropertyDetail;
