import React from "react";
import Banner from "./Banner/Banner";
import SearchProperty from "./SearchProperty/SearchProperty";
import PropertyListing from "./PropertyListing/PropertyListing";
import ViewByLocation from "./ViewByLocation/ViewByLocation";
import CtaOne from "./CtaOne/CtaOne";
import Welcome from "./Welcome/Welcome";
import Agents from "./Agents/Agents";
import WhyWovnn from "./WhyWovnn/WhyWovnn";
import JustListed from "./JustListed/JustListed";
import SpaceType from "./SpaceType/SpaceType";
import Testimonial from "./Testimonial/Testimonial";
import CtaTwo from "./CtaTwo/CtaTwo";
import { useSelector } from "react-redux";
import "./HomePage.css";

function HomePage() {
  const brokerInfo = useSelector((state) => state.brokerInfo);

  return (
    <div className="homePage-container">
      <Banner />
      <SearchProperty />
      <PropertyListing />
      <ViewByLocation />
      <CtaOne />
      <Welcome />
      {brokerInfo.agentType === 1 && <Agents />}
      <WhyWovnn />
      <JustListed />
      <SpaceType />
      <Testimonial />
      <CtaTwo />
    </div>
  );
}

export default HomePage;
