import "./ContactUs.css";
import Url from "../Element/Api";
import React, { useState } from "react";
import BudgetDropdown from "../PropertySidebarForm/BudgetDropdown/BudgetDropdown";
import PropertyTypeDropdown from "../PropertySidebarForm/PropertyTypeDropdown/PropertyTypeDropdown";
import { usStatesNames } from "../../utils/utils";

function ContactForm() {
  const [errors, setErrors] = useState({});
  const Domain = window.location.hostname;
  const [budgetOpen, setBudgetOpen] = useState(false);
  const [isDataSent, setIsDataSent] = useState(false);
  const [lifestyleOpen, setLifestyleOpen] = useState(false);
  const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
  const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
  const [formData, setFormData] = useState({
    person: "",
    firstname: "",
    lastname: "",
    email: "",
    mobileNumber: "",
    location: "Oregon",
    zipCode: "",
    propertyType: {
      residential: [],
      commercial: [],
    },
    selectedBudget: {
      maxMonthly: [],
      maxPrice: [],
      minMonthly: [],
      minPrice: [],
    },
    gdprAgreement: false,
  });

  //Function for updating values in state
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const alphabetRegex = /^[a-zA-Z]*$/;
    const zipCodeRegex = /^[0-9]{0,6}$/;

    if (name === "zipCode" && !zipCodeRegex.test(value)) {
      return;
    }
    if ((name === "person" || name === "firstname" || name === "lastname") && !alphabetRegex.test(value)) {
      return;
    }
    let newValue = value;
    if (name === "mobileNumber") {
      if (/^-/.test(value)) {
        return;
      }
      newValue = value.replace(/[^\d]/g, "").slice(0, 15);
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : newValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    //Function for saving data sent to true
    setIsDataSent(false);
    if (name === "location") {
      setFormData((prevState) => ({
        ...prevState,
        location: value,
      }));
    }
  };

  //Function for submitting inquiry
  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedPropertyTypes = Object.keys(formData.propertyType);
    const formattedPropertyType = selectedPropertyTypes.reduce((acc, type) => {
      return [...acc, ...formData.propertyType[type]];
    }, []);

    const formattedFormData = {
      ...formData,
      propertyType: formattedPropertyType,
      domain: Domain,
    };

    try {
      const response = await fetch(`${Url.BASEURL}/api/user/create-inquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedFormData),
      });
      if (!response.ok) {
        const errorData = await response.json();
        setErrors(errorData.errors || {});
        return;
      }

      setFormData({
        person: "",
        firstname: "",
        lastname: "",
        email: "",
        mobileNumber: "",
        location: "Oregon",
        zipCode: "",
        propertyType: {},
        selectedBudget: {
          maxMonthly: null,
          maxPrice: null,
          minMonthly: null,
          minPrice: null,
        },
        gdprAgreement: false,
        domain: Domain,
      });

      setIsDataSent(true);
    } catch (error) {
      setErrors({ general: "An error occurred while submitting the form. Please try again later." });
    }
  };

  //Function for closing all dropdowns
  const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setPropertyFeaturesOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);
  };

  const handleDocumentClick = (e) => {
    if (!e.target.closest(".select_custom") && (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)) {
      closeAllDropdowns();
    }
  };

  document.addEventListener("click", handleDocumentClick);

  //Function for updating property on state
  const handlePropertyTypeSelect = (selectedPropertyType) => {
    setFormData((prevState) => ({
      ...prevState,
      propertyType: selectedPropertyType,
    }));
  };

  //Function for budget update  on state
  const handleBudgetSelect = (selectedBudget) => {
    const { minPrice, maxPrice, minMonthly, maxMonthly } = selectedBudget;

    const formattedBudget = {
      minPrice: minPrice ? parseFloat(minPrice) : null,
      maxPrice: maxPrice ? parseFloat(maxPrice) : null,
      minMonthly: minMonthly ? parseFloat(minMonthly) : null,
      maxMonthly: maxMonthly ? parseFloat(maxMonthly) : null,
    };

    setFormData((prevState) => ({
      ...prevState,
      selectedBudget: formattedBudget,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedBudget: "",
    }));
  };

  return (
    <div className="new_form">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="inquery property-detail-form">
              <h3 className="real_estate">Real Estate Inquiry Form</h3>
              <form className="inq_form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row mb-3">
                      <div className="col-md-5">
                        <div className="mb-3">
                          <label htmlFor="location" className="form-label">
                            Location
                          </label>
                          <select
                            id="location"
                            className="form-select"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                          // onBlur={handleBlur}
                          >
                            {usStatesNames.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                        </div>
                        <span className="error" style={{ color: "red" }}>
                          {errors.location}
                        </span>
                      </div>
                      <div className="col-md-5">
                        <div className="mb-3 marg_top">
                          <input type="text" className="form-control" id="zipcode" name="zipCode" placeholder="Zip Code" value={formData.zipCode} onChange={handleChange} />
                        </div>
                        <span className="error" style={{ color: "red" }}>
                          {errors.zipCode}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-5">
                        <div className="mb-3 ">
                          <label htmlFor="propertyType" className="form-label">
                            Property
                          </label>
                          <PropertyTypeDropdown
                            isOpen={propertyTypeOpen}
                            toggleDropdown={() => {
                              setPropertyTypeOpen(!propertyTypeOpen);
                              closeAllDropdowns();
                            }}
                            onSelectPropertyType={handlePropertyTypeSelect}
                            isDataSent={isDataSent}
                          />
                        </div>
                        <span className="error" style={{ color: "red" }}>
                          {errors.propertyType}
                        </span>
                      </div>
                      <div className="col-md-5 ">
                        <div className="mb-3 marg_top">
                          <BudgetDropdown
                            isOpen={budgetOpen}
                            toggleDropdown={() => {
                              setBudgetOpen(!budgetOpen);
                              closeAllDropdowns();
                            }}
                            onSelectBudget={handleBudgetSelect}
                            isDataSent={isDataSent}
                          />
                          <span className="error" style={{ color: "red" }}>
                            {errors.selectedBudget}
                          </span>
                        </div>
                      </div>
                    </div>
                    <label htmlFor="location" className="form-label">
                      GDPR Agreement
                    </label>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="gdprAgreement" name="gdprAgreement" checked={formData.gdprAgreement} onChange={handleChange} />
                      <label className="form-check-label custom_agreement" htmlFor="gdprAgreement">
                        I consent to having this website store my submitted information
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="information" className="form-label">
                        Information
                      </label>
                      <input type="text" className="form-control" id="information" name="person" placeholder="I’m a" value={formData.person} onChange={handleChange} />
                      <span className="error" style={{ color: "red" }}>
                        {errors.person}
                      </span>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <input type="text" className="form-control" name="firstname" placeholder="First Name" value={formData.firstname} onChange={handleChange} />
                        <span className="error" style={{ color: "red" }}>
                          {errors.firstname}
                        </span>
                      </div>
                      <div className="col-lg-6 top_ln">
                        <input type="text" className="form-control " name="lastname" placeholder="Last Name" value={formData.lastname} onChange={handleChange} />
                        <span className="error" style={{ color: "red" }}>
                          {errors.lastname}
                        </span>
                      </div>
                    </div>

                    <div className="mb-3">
                      <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} />
                      <span className="error" style={{ color: "red" }}>
                        {errors.email}
                      </span>
                    </div>

                    <div className="mb-3">
                      <input type="text" className="form-control" id="phone" name="mobileNumber" placeholder="Phone Number" value={formData.mobileNumber} onChange={handleChange} maxLength="15" />
                      <span className="error" style={{ color: "red" }}>
                        {errors.mobileNumber}
                      </span>
                    </div>
                    {isDataSent && (
                      <span className="error" style={{ color: "#22A9E0" }}>
                        Thank you for submitting your information! We'll get back to you shortly
                      </span>
                    )}
                    <div className="d-flex ">
                      <button type="submit" className="custom_btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
