import React, { useState, useEffect } from "react";
import Url from "../../Element/Api";
import axios from "axios";
import "./PropertyListing.css";
import { Link } from "react-router-dom";
import liked from "../../../public/assets/like.svg";
import notliked from "../../../public/assets/unlike.svg";
import { useSelector } from "react-redux";
import WishListWithoutLogin from "../PropertyListing/WishListWithoutLogin";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import alternative1 from "../../../public/alternative1.jpg";
import Loader from "../../Element/Loader";

function PropertyListing() {
  const UserIdtoken = useSelector((state) => state.jwtuser);
  const UserId = UserIdtoken._id;
  const regdtoken = UserIdtoken.jwtToken;
  const Domain = window.location.hostname;
  const [showModal, setShowModal] = useState(false);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);
  const [wishlistedProperties, setwishlistedProperties] = useState([]);
  const propertiesChunks = [];

  for (let i = 0; i < properties.length; i += 3) {
    propertiesChunks.push(properties.slice(i, i + 3));
  }

  //Function for carausel
  const initializeCarousel = () => {
    const carouselElement = document.getElementById("propertyCarousel");
    if (carouselElement) {
      const handleSlide = (event) => {
        setActiveSlide(event.to);
      };
      carouselElement.addEventListener("slid.bs.carousel", handleSlide);
      return () => {
        carouselElement.removeEventListener("slid.bs.carousel", handleSlide);
      };
    }
  };

  
  const handleCarouselControlClick = (direction) => {
    const totalSlides = properties.length / 3;
    let newActiveSlide;

    if (direction === "prev") {
      newActiveSlide = (activeSlide - 1 + totalSlides) % totalSlides;
    } else {
      newActiveSlide = (activeSlide + 1) % totalSlides;
    }

    setActiveSlide(newActiveSlide);
  };

  useEffect(() => {
    initializeCarousel();
  }, []);

  
  //Function for getting latest properties
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/api/mls/reso/search-result`, {
          params: {
            domain: Domain,
            userId: UserId,
            page: 1,
            status: "Active",
            size: 12,
          },
        });
        const { docs } = response.data.properties;
        if (docs && Array.isArray(docs)) {
          setProperties(docs);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        initializeCarousel();
      }
    };
    fetchData();
  }, []);

  
  //Function for saving property
  const handleLocalHeartClick = (property, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (regdtoken) {
      const updatedProperties = properties.map((p) => (p.ListingKey === property.ListingKey ? { ...p, isWishlisted: !p.isWishlisted } : p));
      setProperties(updatedProperties);
    }
  };

    //Function for saving property
  const handleHeartClick = async (property, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (regdtoken) {
      try {
        const response = await axios.post(
          `${Url.BASEURL}/api/user/create-wishlist`,
          {
            ListingKey: property.ListingKey,
          },
          {
            headers: {
              Authorization: `Bearer ${regdtoken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setwishlistedProperties([...wishlistedProperties, { userId: UserIdtoken._id, ListingKey: property.ListingKey }]);
      } catch (error) {
        console.error("Error updating wishlist:", error);
      }
    } else {
      console.log("No registration token, cannot like property.");
    }
  };

  const handleModalDisplay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowModal(true);
  };

  const formatPropertyType = (propertyType) => {
    if (propertyType === null || propertyType === undefined) {
      return "";
    }
    return propertyType.replace(/([A-Z])/g, " $1").trim();
  };

  //Loader
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container properties_listing">
      <div id="propertyCarousel" className="carousel slide">
        <div className="carousel-inner">
          {propertiesChunks.map((chunk, chunkIndex) => (
            <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? "active" : ""}`}>
              <div className="row">
                {chunk.map((property, index) => (
                  <div key={property.ListingKey} className="col-lg-4 col-md-4">
                    <Link to={`/property-detail/${property.ListingKey}`}>
                      <div className="property-card">
                        <div className="property-overlays d-flex">
                          <div className="left-overlays">
                            <button className="featured">Featured</button>
                            <button className="featured hot">Hot</button>
                          </div>
                          <div className="right-overlays d-flex">
                            <div className="right_single_block d-flex">
                              <div className="left_single_icon">
                                <img src="/assets/camera.svg" alt="Camera icon" />
                              </div>
                              <div className="right_single_text"></div>
                            </div>
                            <div className="right_single_block d-flex">
                              <div className="left_single_icon">
                                <img src="/assets/video.svg" alt="Video icon" />
                              </div>
                              <div className="right_single_text"></div>
                            </div>
                            <div className="circle_heart d-flex">
                              <div className="left_single_icon heart_set">
                                <img
                                  src={property.isWishlisted ? liked : notliked}
                                  alt="Heart icon"
                                  onClick={(event) => {
                                    if (regdtoken) {
                                      handleHeartClick(property, event);
                                      handleLocalHeartClick(property, event);
                                    } else {
                                      handleModalDisplay(event);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="property_main_image">
                          <LazyLoadImage src={property.Photo1URL} effect="blur" placeholderSrc={alternative1} />
                        </div>
                        <div className="bottom_property">
                          <div className="location_flex d-flex">
                            <div className="location_icon">
                              <img src="/assets/blue-location.png" alt="Location icon" />
                            </div>
                            <div className="location_name">{String(property.UnparsedAddress)}</div>
                          </div>
                          <div className="price-amenities d-flex">
                            <div className="price">
                              <span className="price-sign">$</span> {property.CurrentPriceForStatus && property.CurrentPriceForStatus.$numberDecimal ? Number(property.CurrentPriceForStatus.$numberDecimal).toLocaleString("en-US") : "N/A"}
                            </div>
                            <div className="amenities d-flex">
                              {property.BedroomsTotal && (
                                <div className="single_amenities d-flex">
                                  <div className="left_amenities">
                                    <img src="/assets/amenties2.png" alt="Amenities icon" />
                                  </div>
                                  <div className="right_amenities"> {property.BedroomsTotal !== null && String(property.BedroomsTotal)}</div>
                                </div>
                              )}

                              {property.BathroomsTotalInteger && (
                                <div className="single_amenities d-flex">
                                  <div className="left_amenities">
                                    <img src="/assets/amenties3.png" alt="Amenities icon" />
                                  </div>
                                  <div className="right_amenities"> {property.BathroomsTotalInteger !== null && String(property.BathroomsTotalInteger)}</div>
                                </div>
                              )}

                              <div className="single_amenities d-flex">
                                <div className="left_amenities">
                                  <img src="/assets/amenties1.png" alt="Amenities icon" />
                                </div>
                                <div className="right_amenities">
                                  {property.LotSizeSquareFeet} <span className="area">sq ft</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex_overal">
                          <div className="first_flex_overaly">{formatPropertyType(property.PropertySubType)}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {/* <div className="" onClick={handleCameraClick}>
          A
        </div> */}
        <WishListWithoutLogin showModal={showModal} setShowModal={setShowModal} />

        <div className="flexer_btns d-flex">
          <div className="left_flexer"></div>
          <div className="right_flexer">
            <div className="teder_one d-flex">
              <div className="slider-caros d-flex">
                <div className="left_caro" data-bs-target="#propertyCarousel" data-bs-slide="prev" onClick={() => handleCarouselControlClick("prev")}>
                  <i className="fa fa-caret-left"></i>
                </div>
                <div className="middle_dots_home">
                  <ul>
                    {propertiesChunks.map((_, index) => (
                      <li key={index} data-bs-target="#propertyCarousel" data-bs-slide-to={index} className={activeSlide === index ? "active" : ""}></li>
                    ))}
                  </ul>
                </div>
                <div className="left_caro right_caro" data-bs-target="#propertyCarousel" data-bs-slide="next" onClick={() => handleCarouselControlClick("next")}>
                  <i className="fa fa-caret-right"></i>
                </div>
              </div>
            </div>

            <div className="teder_two">
              <div className="mutual_btn listing_btn isting">
                <Link to={`/search-result`}>More Listing</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PropertyListing;
