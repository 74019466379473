// reducers.js
import { combineReducers } from 'redux';
import { UPDATE_USER_INFO, UPDATE_PROPERTY_TYPES, UPDATE_REALTOR_INFO, UPDATE_BROKER_INFO } from '../Action/action';

const jwttoken = {
  jwtToken: '',
  accessToken: '',
  refreshToken: '',
  _id: '',
  verified: '',
  image: '',
};
const brokerInfo = {
  agentType: '',
  companyName: '',
  bio: '',
};
const authReducer = (state = jwttoken, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

// Define reducer for property types
const propertyTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROPERTY_TYPES:
      return action.payload;
    default:
      return state;
  }
};

const realtorInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_REALTOR_INFO:
      return action.payload;
    default:
      return state;
  }
};
const brokerInfoReducer = (state = brokerInfo, action) => {
  switch (action.type) {
    case UPDATE_BROKER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};


export const rootReducer = combineReducers({
  jwtuser: authReducer,
  propertyTypes: propertyTypesReducer,
  realtorInfo: realtorInfoReducer,
  brokerInfo: brokerInfoReducer,
});
