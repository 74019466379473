import React from 'react';
import { createRoot } from 'react-dom'; // Import createRoot
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './components/Redux/Store/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { SelectedOptionsProvider } from '../src/components/SelectedOptionsContext';
import { HelmetProvider } from 'react-helmet-async';

// Use createRoot instead of ReactDOM.render
createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SelectedOptionsProvider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </SelectedOptionsProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
 
);
