import React, { useEffect } from "react";
import axios from "axios";
import Url from "../../Element/Api";
import { useParams } from "react-router-dom";
import { useSelectedOptions } from "../../SelectedOptionsContext";

function EmailVerified() {
  const { userId, token } = useParams();
  const { verificationStatus, setVerificationStatus } = useSelectedOptions({
    verified: false,
    message: "",
  });

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/api/user/${userId}/verify/${token}`);
        const { verified, message } = response.data;
        setVerificationStatus({ verified, message });
      } catch (error) {
        setVerificationStatus({
          verified: false,
          message: "An error occurred while verifying email.",
        });
      }
    };

    verifyEmail();
  }, []);

  return (
    <div className="login-register">
      <div className="loginRegister">
        <div className={`email_verification ${verificationStatus.verified ? "verification_done" : ""}`}>
          <h3>Email Verification</h3>
          <p>{verificationStatus.message}</p>
          {verificationStatus.verified && (
            <>
              <h4>Your Email Address is Now Verified</h4>
              <button className="logreg_btn btn btn-primary">Next</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailVerified;
