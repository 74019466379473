import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Url from "../../Element/Api";
import "./LifeStyleSearchDropdown.css";
import ReactSlider from "react-slider";
import SearchImage3 from "../../../public/assets/s2.svg";
import { useSelectedOptions } from "../../SelectedOptionsContext";

function LifeStyleSearchDropdown2() {
  const { isOpen, setIsOpen } = useSelectedOptions();
  const [activeIconRow1, setActiveIconRow1] = useState("Vector");
  const [activeIconRow2, setActiveIconRow2] = useState("Vector");
  const [activeIconRow3, setActiveIconRow3] = useState("Vector");
  //States for searchvalue1
  const { searchValue } = useSelectedOptions();
  const { searchValue1, setSearchValue1 } = useSelectedOptions();
  const [suggestions1, setSuggestions1] = useState([]);
  const { selectedSuggestion1, setSelectedSuggestion1 } = useSelectedOptions();
  const { nearsearchresult1, setnearsearchresult1 } = useSelectedOptions();
  const { selectednearsearchresult1, setselectednearsearchresult1 } = useSelectedOptions();
  const [writtenData, setWrittenData] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);
  const dropdownRef = useRef(null);

  //States for searchvalue2
  const { searchValue2, setSearchValue2 } = useSelectedOptions();
  const [suggestions2, setSuggestions2] = useState([]);
  const { selectedSuggestion2, setSelectedSuggestion2 } = useSelectedOptions(null);
  const { nearsearchresult2, setnearsearchresult2 } = useSelectedOptions();
  const { selectednearsearchresult2, setselectednearsearchresult2 } = useSelectedOptions();
  const [writtenData2, setWrittenData2] = useState("");

  //States for searchvalue3
  const { searchValue3, setSearchValue3 } = useSelectedOptions();
  const [suggestions3, setSuggestions3] = useState([]);
  const { selectedSuggestion3, setSelectedSuggestion3 } = useSelectedOptions(null);
  const { nearsearchresult3, setnearsearchresult3 } = useSelectedOptions();
  const { selectednearsearchresult3, setselectednearsearchresult3 } = useSelectedOptions();
  const [writtenData3, setWrittenData3] = useState("");

  const { sliderValuesearch1, setsliderValuesearch1 } = useSelectedOptions({
    value: 5,
    mode: "distance",
  });
  const { activeModesearch1, setactiveModesearch1 } = useSelectedOptions("distance");
  const { sliderValuesearch2, setsliderValuesearch2 } = useSelectedOptions({
    value: 5,
    mode: "distance",
  });
  const { activeModesearch2, setactiveModesearch2 } = useSelectedOptions("distance");
  const { sliderValuesearch3, setsliderValuesearch3 } = useSelectedOptions({
    value: 5,
    mode: "distance",
  });
  const { activeModesearch3, setactiveModesearch3 } = useSelectedOptions("distance");
  useEffect(() => {
    if (isInitialRender) {
      setIsOpen(/nearby\s/i.test(searchValue1) || /(\w+)\s+near\s+me/i.test(searchValue1) || /nearby\s/i.test(searchValue2) || /(\w+)\s+near\s+me/i.test(searchValue2) || /nearby\s/i.test(searchValue3) || /(\w+)\s+near\s+me/i.test(searchValue3));
      setIsInitialRender(false);
    }
  }, [searchValue1, searchValue2, searchValue3, setIsOpen, isInitialRender]);

  useEffect(() => {
    const performSearch1 = async () => {
      let response;
      const isNearbySearch = /nearby\s/i.test(searchValue1);
      const isNearMeSearch = /(\w+)\s+near\s+me/i.test(searchValue1);
      const inputValue = searchValue1;
      if (inputValue === selectednearsearchresult1 || inputValue === selectedSuggestion1) {
        setSuggestions1([]);
        return;
      }

      if (isNearbySearch) {
        const queryMatch = inputValue.match(/nearby\s+(.+)/i);
        const query = queryMatch ? queryMatch[1] : "";
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json`;

        try {
          const response = await axios.get(geocodingUrl, {
            params: {
              address: searchValue,
              key: "AIzaSyDS3NVLiU1X1JKmED-ecqI97CYMda6P6jA",
            },
          });

          const location = response.data.results[0].geometry.location;
          const { lat, lng } = location;
          try {
            const nearbySearchResponse = await axios.get(`${Url.BASEURL}/api/user/nearby`, {
              params: {
                query: query,
                latitude: lat,
                longitude: lng,
              },
            });

            const responseData = nearbySearchResponse.data;
            setnearsearchresult1(responseData.results);
            setSuggestions1([]);
          } catch (error) {
            console.error("Error performing nearby search:", error);
          }
        } catch (error) {
          console.error("Error performing geocoding request:", error);
        }
      } else if (isNearMeSearch) {
        const queryMatch = inputValue.match(/(\w+)\s+near\s+me/i);
        const query = queryMatch ? queryMatch[1] : "";

        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              try {
                response = await axios.get(`${Url.BASEURL}/api/user/nearme`, {
                  params: {
                    query: query,
                    latitude,
                    longitude,
                  },
                });
                const responseData = response.data;
                setnearsearchresult1(responseData.results);
                setSuggestions1([]);
              } catch (error) {
                console.error("Error performing nearby search using user location:", error);
              }
            },
            (error) => {
              console.error("Error getting user location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by your browser");
        }
      } else {
        try {
          const suggestionsResponse = await axios.get(`${Url.BASEURL}/api/user/suggestions`, {
            params: {
              input: inputValue,
            },
          });

          if (suggestionsResponse && suggestionsResponse.data) {
            const suggestionsData = suggestionsResponse.data;
            setSuggestions1(suggestionsData);
          } else {
            console.error("Error fetching suggestions:", suggestionsResponse);
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      }
    };

    const timerId = setTimeout(() => {
      performSearch1();
    }, 1000);
    return () => clearTimeout(timerId);
  }, [searchValue1, selectedSuggestion1]);

  const handleInputChange1 = (e) => {
    const inputValue = e.target.value;
    setSearchValue1(inputValue);

    const isNearby = /^nearby\s*$/i.test(inputValue);
    const isNearMe = /(\w+)\s+near\s+me/i.test(inputValue);

    if (inputValue.trim() === "" || isNearby || isNearMe) {
      setSuggestions1([]);
      return;
    }
  };

  const handleSelectSuggestion1 = (suggestion) => {
    setSelectedSuggestion1(suggestion.description);
    setSearchValue1(suggestion.description);
    setSuggestions1([]);
  };

  useEffect(() => {
    const performSearch2 = async () => {
      let response;
      const isNearbySearch = /nearby\s/i.test(searchValue2);
      const isNearMeSearch = /(\w+)\s+near\s+me/i.test(searchValue2);
      const inputValue = searchValue2;
      if (inputValue === selectednearsearchresult2 || inputValue === selectedSuggestion2) {
        setSuggestions2([]);
        return;
      }
      if (isNearbySearch) {
        const queryMatch = inputValue.match(/nearby\s+(.+)/i);
        const query = queryMatch ? queryMatch[1] : "";
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json`;
        try {
          const response = await axios.get(geocodingUrl, {
            params: {
              address: searchValue,
              key: "AIzaSyDS3NVLiU1X1JKmED-ecqI97CYMda6P6jA",
            },
          });
          const location = response.data.results[0].geometry.location;
          const { lat, lng } = location;
          try {
            const nearbySearchResponse = await axios.get(`${Url.BASEURL}/api/user/nearby`, {
              params: {
                query: query,
                latitude: lat,
                longitude: lng,
              },
            });

            const responseData = nearbySearchResponse.data;
            setnearsearchresult2(responseData.results);
            setSuggestions2([]);
          } catch (error) {
            console.error("Error performing nearby search:", error);
          }
        } catch (error) {
          console.error("Error performing geocoding request:", error);
        }
      } else if (isNearMeSearch) {
        const queryMatch = inputValue.match(/(\w+)\s+near\s+me/i);
        const query = queryMatch ? queryMatch[1] : "";

        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              try {
                response = await axios.get(`${Url.BASEURL}/api/user/nearme`, {
                  params: {
                    query: query,
                    latitude,
                    longitude,
                  },
                });
                const responseData = response.data;
                setnearsearchresult2(responseData.results);
                setSuggestions2([]);
              } catch (error) {
                console.error("Error performing nearby search using user location:", error);
              }
            },
            (error) => {
              console.error("Error getting user location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by your browser");
        }
      } else {
        try {
          const suggestionsResponse = await axios.get(`${Url.BASEURL}/api/user/suggestions`, {
            params: {
              input: inputValue,
            },
          });

          if (suggestionsResponse && suggestionsResponse.data) {
            const suggestionsData = suggestionsResponse.data;
            setSuggestions2(suggestionsData);
          } else {
            console.error("Error fetching suggestions:", suggestionsResponse);
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      }
    };
    const timerId = setTimeout(() => {
      performSearch2();
    }, 1000);
    return () => clearTimeout(timerId);
  }, [searchValue2, selectedSuggestion2]);

  const handleInputChange2 = (e) => {
    const inputValue = e.target.value;
    setSearchValue2(inputValue);

    const isNearby = /^nearby\s*$/i.test(inputValue);
    const isNearMe = /(\w+)\s+near\s+me/i.test(inputValue);

    if (inputValue.trim() === "" || isNearby || isNearMe) {
      setSuggestions2([]);
      return;
    }
  };

  const handleSelectSuggestion2 = (suggestion) => {
    setSelectedSuggestion2(suggestion.description);
    setSearchValue2(suggestion.description);
    setSuggestions2([]);
  };

  useEffect(() => {
    const performSearch3 = async () => {
      let response;
      const isNearbySearch = /nearby\s/i.test(searchValue3);
      const isNearMeSearch = /(\w+)\s+near\s+me/i.test(searchValue3);
      const inputValue = searchValue3;
      if (inputValue === selectednearsearchresult2 || inputValue === selectedSuggestion3) {
        setSuggestions3([]);
        return;
      }
      if (isNearbySearch) {
        const queryMatch = inputValue.match(/nearby\s+(.+)/i);
        const query = queryMatch ? queryMatch[1] : "";
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json`;

        try {
          const response = await axios.get(geocodingUrl, {
            params: {
              address: searchValue,
              key: "AIzaSyDS3NVLiU1X1JKmED-ecqI97CYMda6P6jA",
            },
          });
          const location = response.data.results[0].geometry.location;
          const { lat, lng } = location;
          try {
            const nearbySearchResponse = await axios.get(`${Url.BASEURL}/api/user/nearby`, {
              params: {
                query: query,
                latitude: lat,
                longitude: lng,
              },
            });
            const responseData = nearbySearchResponse.data;
            setnearsearchresult3(responseData.results);
            setSuggestions3([]);
          } catch (error) {
            console.error("Error performing nearby search:", error);
          }
        } catch (error) {
          console.error("Error performing geocoding request:", error);
        }
      } else if (isNearMeSearch) {
        const queryMatch = inputValue.match(/(\w+)\s+near\s+me/i);
        const query = queryMatch ? queryMatch[1] : "";
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              console.log("Performing nearby search using user's location");
              try {
                response = await axios.get(`${Url.BASEURL}/api/user/nearme`, {
                  params: {
                    query: query,
                    latitude,
                    longitude,
                  },
                });
                const responseData = response.data;
                setnearsearchresult3(responseData.results);
                setSuggestions3([]);
              } catch (error) {
                console.error("Error performing nearby search using user location:", error);
              }
            },
            (error) => {
              console.error("Error getting user location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by your browser");
        }
      } else {
        try {
          const suggestionsResponse = await axios.get(`${Url.BASEURL}/api/user/suggestions`, {
            params: {
              input: inputValue,
            },
          });

          if (suggestionsResponse && suggestionsResponse.data) {
            const suggestionsData = suggestionsResponse.data;
            setSuggestions3(suggestionsData);
          } else {
            console.error("Error fetching suggestions:", suggestionsResponse);
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      }
    };
    const timerId = setTimeout(() => {
      performSearch3();
    }, 1000);
    return () => clearTimeout(timerId);
  }, [searchValue3, selectedSuggestion3]);

  const handleInputChange3 = (e) => {
    const inputValue = e.target.value;
    setSearchValue3(inputValue);

    const isNearby = /^nearby\s*$/i.test(inputValue);
    const isNearMe = /(\w+)\s+near\s+me/i.test(inputValue);

    if (inputValue.trim() === "" || isNearby || isNearMe) {
      setSuggestions3([]);
      return;
    }
  };

  const handleSelectSuggestion3 = (suggestion) => {
    setSelectedSuggestion3(suggestion.description);
    setSearchValue3(suggestion.description);
    setSuggestions3([]);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen && setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [setIsOpen]);

  const renderThumbCoffee = (props, state) => {
    let displayValue;
    if (activeModesearch1 === "distance") {
      displayValue = `${Math.round(state.value)} miles`;
    } else {
      displayValue = `${Math.round(state.value)} mins`;
    }
    return <div {...props}>{displayValue}</div>;
  };

  const renderThumbSchool = (props, state) => {
    let displayValue;
    if (activeModesearch2 === "distance") {
      displayValue = `${Math.round(state.value)} miles`;
    } else {
      displayValue = `${Math.round((state.value / 200) * 180)} mins`;
    }
    return <div {...props}>{displayValue}</div>;
  };

  const renderThumbGym = (props, state) => {
    let displayValue;
    if (activeModesearch3 === "distance") {
      displayValue = `${Math.round(state.value)} miles`;
    } else {
      displayValue = `${Math.round((state.value / 200) * 180)} mins`;
    }
    return <div {...props}>{displayValue}</div>;
  };

  const handleIconClickRow1 = (iconName) => {
    setActiveIconRow1(iconName);
  };

  const handleIconClickRow2 = (iconName) => {
    setActiveIconRow2(iconName);
  };

  const handleIconClickRow3 = (iconName) => {
    setActiveIconRow3(iconName);
  };

  useEffect(() => {
    setsliderValuesearch1((prev) => ({ ...prev, mode: activeModesearch1.toLowerCase() }));
  }, [activeModesearch1]);

  useEffect(() => {
    setsliderValuesearch2((prev) => ({ ...prev, mode: activeModesearch2.toLowerCase() }));
  }, [activeModesearch2]);

  useEffect(() => {
    setsliderValuesearch3((prev) => ({ ...prev, mode: activeModesearch3.toLowerCase() }));
  }, [activeModesearch3]);

  const handleSliderChange1 = (newValue) => {
    setsliderValuesearch1({ value: newValue, mode: activeModesearch1 });
  };

  const handleSliderChange2 = (newValue) => {
    setsliderValuesearch2({ value: newValue, mode: activeModesearch2 });
  };

  const handleSliderChange3 = (newValue) => {
    setsliderValuesearch3({ value: newValue, mode: activeModesearch3 });
  };

  return (
    <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
      <div className="sel_icon">
        <img src={SearchImage3} alt="propertyType" />
      </div>
      <div className="sel_text">
        {(searchValue1 !== "" || searchValue2 !== "" || searchValue3 !== "") && (
          <>
            {searchValue1.split(",")[0]}
            {searchValue2.split(",")[0] && `,`} {searchValue2.split(",")[0]}
            {searchValue3.split(",")[0] && `,`} {searchValue3.split(",")[0]}
          </>
        )}
        {searchValue1 === "" && searchValue2 === "" && searchValue3 === "" && "Lifestyle Search (Optional)"}
      </div>

      {isOpen && (
        <div className="property_drop lifestyledrop" onClick={(e) => e.stopPropagation()}>
          <h3>LIFESTYLE CRITERIA</h3>
          <div className="single_full_search  d-flex">
            <div className="left_lifestyle ">
              <div className="single_search_area">
                <input type="text" className="form-control" placeholder="Search for nearby places or anything else" value={searchValue1} onChange={handleInputChange1} />
                {suggestions1.length > 0 && (
                  <div className="suggestion1">
                    <ul>
                      {suggestions1.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSelectSuggestion1(suggestion)}>
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="right_lifestyle">
              <div className="distancetime d-flex">
                <div className="left_time">
                  <span className={`link ${activeModesearch1 === "distance" ? "active" : ""}`} onClick={() => setactiveModesearch1("distance")}>
                    Distance
                  </span>
                  /
                  <span className={`link ${activeModesearch1 === "time" ? "active" : ""}`} onClick={() => setactiveModesearch1("time")}>
                    Time
                  </span>
                </div>
                <div className="right_time d-flex">
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "Vector" ? "active" : ""}`} onClick={() => handleIconClickRow1("Vector")}>
                      <span className="icon-Vector"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "Vector-1" ? "active" : ""}`} onClick={() => handleIconClickRow1("Vector-1")}>
                      <span className="icon-Vector-1"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "Vector-2" ? "active" : ""}`} onClick={() => handleIconClickRow1("Vector-2")}>
                      <span className="icon-Vector-2"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "icon-train" ? "active" : ""}`} onClick={() => handleIconClickRow1("icon-train")}>
                      <span className="icon-train"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "icon-car" ? "active" : ""}`} onClick={() => handleIconClickRow1("icon-car")}>
                      <span className="icon-car"></span>
                    </div>
                  </div>
                </div>
                <ReactSlider
                  className="horizontal-slider miles_time"
                  thumbClassName="thumb"
                  trackClassName="track"
                  min={0}
                  max={30}
                  value={sliderValuesearch1.value} // Access the value property of the sliderValuesearch1 object
                  onChange={handleSliderChange1}
                  renderThumb={renderThumbCoffee}
                />
              </div>
            </div>
          </div>

          {/* ************* */}

          <div className="single_full_search  d-flex">
            <div className="left_lifestyle ">
              <div className="single_search_area">
                <input type="text" className="form-control" placeholder="Search for nearby places or anything else" value={searchValue2} onChange={handleInputChange2} />
                {suggestions2.length > 0 && (
                  <div className="suggestion1">
                    <ul>
                      {suggestions2.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSelectSuggestion2(suggestion)}>
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="right_lifestyle">
              <div className="distancetime d-flex">
                <div className="left_time">
                  <span className={`link ${activeModesearch2 === "distance" ? "active" : ""}`} onClick={() => setactiveModesearch2("distance")}>
                    Distance
                  </span>
                  /
                  <span className={`link ${activeModesearch2 === "time" ? "active" : ""}`} onClick={() => setactiveModesearch2("time")}>
                    Time
                  </span>
                </div>
                <div className="right_time d-flex">
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "Vector" ? "active" : ""}`} onClick={() => handleIconClickRow2("Vector")}>
                      <span className="icon-Vector"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "Vector-1" ? "active" : ""}`} onClick={() => handleIconClickRow2("Vector-1")}>
                      <span className="icon-Vector-1"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "Vector-2" ? "active" : ""}`} onClick={() => handleIconClickRow2("Vector-2")}>
                      <span className="icon-Vector-2"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "icon-train" ? "active" : ""}`} onClick={() => handleIconClickRow2("icon-train")}>
                      <span className="icon-train"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "icon-car" ? "active" : ""}`} onClick={() => handleIconClickRow2("icon-car")}>
                      <span className="icon-car"></span>
                    </div>
                  </div>
                </div>
                <ReactSlider className="horizontal-slider miles_time" thumbClassName="thumb" trackClassName="track" min={0} max={30} value={sliderValuesearch2.value} onChange={handleSliderChange2} renderThumb={renderThumbSchool} />
              </div>
            </div>
          </div>

          {/* *************** */}

          <div className="single_full_search  d-flex">
            <div className="left_lifestyle ">
              <div className="single_search_area ">
                <input type="text" className="form-control" placeholder="Search for nearby places or anything else" value={searchValue3} onChange={handleInputChange3} />
                {suggestions3.length > 0 && (
                  <div className="suggestion1">
                    <ul>
                      {suggestions3.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSelectSuggestion3(suggestion)}>
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="right_lifestyle">
              <div className="distancetime d-flex">
                <div className="left_time">
                  <span className={`link ${activeModesearch3 === "distance" ? "active" : ""}`} onClick={() => setactiveModesearch3("distance")}>
                    Distance
                  </span>
                  /
                  <span className={`link ${activeModesearch3 === "time" ? "active" : ""}`} onClick={() => setactiveModesearch3("time")}>
                    Time
                  </span>
                </div>
                <div className="right_time d-flex">
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "Vector" ? "active" : ""}`} onClick={() => handleIconClickRow3("Vector")}>
                      <span className="icon-Vector"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "Vector-1" ? "active" : ""}`} onClick={() => handleIconClickRow3("Vector-1")}>
                      <span className="icon-Vector-1"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "Vector-2" ? "active" : ""}`} onClick={() => handleIconClickRow3("Vector-2")}>
                      <span className="icon-Vector-2"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "icon-train" ? "active" : ""}`} onClick={() => handleIconClickRow3("icon-train")}>
                      <span className="icon-train"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "icon-car" ? "active" : ""}`} onClick={() => handleIconClickRow3("icon-car")}>
                      <span className="icon-car"></span>
                    </div>
                  </div>
                </div>
                <ReactSlider className="horizontal-slider miles_time" thumbClassName="thumb" trackClassName="track" min={0} max={30} value={sliderValuesearch3.value} onChange={handleSliderChange3} renderThumb={renderThumbGym} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LifeStyleSearchDropdown2;
