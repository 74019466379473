import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Blogs/Blogs.css";
import Arrow from "../../public/assets/arrows.svg";
import { useParams } from "react-router-dom";
import Url from "../Element/Api";
import Loader from "../Element/Loader";

function MetatagBlog() {
  const blogsPerPage = 12;
  const { name } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/blog/metatag/${name}`);
        const data = await response.json();

        if (data && data.blogs && Array.isArray(data.blogs)) {
          setBlogs(data.blogs);
        } else {
          console.error("Error: Invalid data structure", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name]);

  return (
    <div className="all_agents blogs_all">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1>{name}</h1>
            {loading ? (
              <Loader />
            ) : (
              <>
                {blogs.length === 0 ? (
                  <p>No blogs available.</p>
                ) : (
                  <>
                    <div className="d-flex search_count blogs">
                      <div className="Second_coint">
                        <div className="pagination">
                          <button className={`left_awo ${currentPage <= 1 ? "disabled-btn" : ""}`} disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}>
                            <img src={Arrow} alt="Left Arrow" />
                          </button>
                          {[...Array(totalPages)].map((_, i) => {
                            if (i === 0 || i === totalPages - 1 || (i < 5 && currentPage < 6) || (i < currentPage + 2 && i > currentPage - 2) || (i > totalPages - 5 && currentPage > totalPages - 5)) {
                              return (
                                <button key={i} onClick={() => setCurrentPage(i + 1)} className={currentPage === i + 1 ? "active" : ""}>
                                  {i + 1}
                                </button>
                              );
                            } else if (i === 5 && currentPage < 6) {
                              return <span key={i}>...</span>;
                            } else if (i === currentPage + 2) {
                              return <span key={i}>...</span>;
                            } else if (i === totalPages - 5 && currentPage > totalPages - 5) {
                              return <span key={i}>...</span>;
                            }
                            return null;
                          })}
                          <button className={`${currentPage >= totalPages ? "disabled-btn" : ""}`} disabled={currentPage >= totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
                            <img src={Arrow} alt="Right Arrow" />
                          </button>
                        </div>
                      </div>
                      <div className="agents_count">
                        <span className="ml">
                          {indexOfFirstBlog + 1}-{Math.min(indexOfLastBlog, blogs.length)}
                        </span>
                        of <span>{blogs.length}</span> Results
                      </div>
                    </div>
                    <div className="blog_posts d-flex">
                      {currentBlogs.map((blog) => (
                        <Link to={`/single-blog/${blog._id}`} key={blog._id} className="single_blog_block">
                          <img src={blog.imageURL} className="img-fluid" alt="Blog" />
                          <h2>{blog.title}</h2>
                          <p dangerouslySetInnerHTML={{ __html: blog.description.split(" ").slice(0, 31).join(" ") }} />
                        </Link>
                      ))}
                    </div>
                    <div className="pagination">
                      <button className={`left_awo ${currentPage <= 1 ? "disabled-btn" : ""}`} disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}>
                        <img src={Arrow} alt="Left Arrow" />
                      </button>
                      {[...Array(totalPages)].map((_, i) => {
                        if (i === 0 || i === totalPages - 1 || (i < 5 && currentPage < 6) || (i < currentPage + 2 && i > currentPage - 2) || (i > totalPages - 5 && currentPage > totalPages - 5)) {
                          return (
                            <button key={i} onClick={() => setCurrentPage(i + 1)} className={currentPage === i + 1 ? "active" : ""}>
                              {i + 1}
                            </button>
                          );
                        } else if (i === 5 && currentPage < 6) {
                          return <span key={i}>...</span>;
                        } else if (i === currentPage + 2) {
                          return <span key={i}>...</span>;
                        } else if (i === totalPages - 5 && currentPage > totalPages - 5) {
                          return <span key={i}>...</span>;
                        }
                        return null;
                      })}
                      <button className={`${currentPage >= totalPages ? "disabled-btn" : ""}`} disabled={currentPage >= totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
                        <img src={Arrow} alt="Right Arrow" />
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MetatagBlog;
