import React, { useState, useEffect, useRef } from "react";
import "./PropertyTypeDropdown.css";
import Building from "../../../public/assets/Building.svg";
import Chevron from "../../../public/assets/chevron.svg";
import { useSelector } from "react-redux";

const PropertyTypeDropdown = ({ onSelectPropertyType, isDataSent }) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setselectedType] = useState({});
  const propertyTypes = useSelector((state) => state.propertyTypes);
  const [selectedPropertyType, setSelectedPropertyType] = useState("Property Type");
  const [categoryOpenStates, setCategoryOpenStates] = useState({
    "Commercial Sale": true,
  });

  //Function for fetching property types 
  useEffect(() => {
    const fetchPropertyTypes = async () => {
      try {
        const initialOpenStates = {};
        Object.keys(propertyTypes).forEach((category) => {
          initialOpenStates[category] = category === "CommercialSale";
        });
        setCategoryOpenStates(initialOpenStates);
      } catch (error) {
        console.error("Error fetching property types:", error);
      }
    };

    fetchPropertyTypes();
  }, []);

  
  //Function to clear the state
  useEffect(() => {
    if (isDataSent) {
      setselectedType({});
    }
  }, [isDataSent]);

  const toggleSelection = (event, category, option) => {
    event.stopPropagation();
    const selectedCategory = selectedType[category] || [];
    const updatedSelectedCategory = selectedCategory.includes(option) ? selectedCategory.filter((item) => item !== option) : [...selectedCategory, option];
    const updatedSelectedType = {
      ...selectedType,
      [category]: updatedSelectedCategory,
    };
    setselectedType(updatedSelectedType);
    handleSelectPropertyType(updatedSelectedType);
  };

  const toggleAllSubtypes = (event, category) => {
    event.stopPropagation();
    const allSubtypes = propertyTypes[category];
    const isChecked = event.target.checked;
    let updatedSelectedType;
    if (isChecked) {
      updatedSelectedType = {
        ...selectedType,
        [category]: allSubtypes.filter((option) => option !== null),
      };
    } else {
      updatedSelectedType = {
        ...selectedType,
        [category]: [],
      };
    }
    setselectedType(updatedSelectedType);
    handleSelectPropertyType(updatedSelectedType);
  };

  //Function to show property types
  const renderOptions = (options, category) => {
    const filteredOptions = options.filter((option) => option !== null);
    return filteredOptions.map((option) => {
      const formattedOption = option.replace(/([A-Z])/g, " $1").trim();
      return (
        <div key={option} className={`option ${selectedType[category] && selectedType[category].includes(option) ? "selected" : ""}`} onClick={(event) => toggleSelection(event, category, option)}>
          {formattedOption}
        </div>
      );
    });
  };

  
  //Function to select all the property types
  const areAllSubtypesSelected = (category) => {
    const allSubtypes = propertyTypes[category].filter((option) => option !== null);
    const selectedSubtypes = (selectedType[category] || []).filter((option) => option !== null);
    return allSubtypes.length > 0 && allSubtypes.every((subtype) => selectedSubtypes.includes(subtype));
  };

  const toggleCategoryVisibility = (category) => {
    setCategoryOpenStates((prevStates) => {
      const updatedStates = {};
      Object.keys(prevStates).forEach((cat) => {
        updatedStates[cat] = cat === category ? !prevStates[cat] : false;
      });
      return updatedStates;
    });
  };

  //Function to close the modal if clicked outside 
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  
  //Function to update the property type on state
  const handleSelectPropertyType = (updatedSelectedType) => {
    onSelectPropertyType(updatedSelectedType);
  };

  //Function to show the selected values on the field
    useEffect(() => {
    const selected = Object.values(selectedType).flat().join(", ");
    setSelectedPropertyType(selected || "Property Type");
  }, [selectedType]);

  return (
    <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
      <div className="sel_icon">
        <img src={Building} alt="propertyType" />
      </div>
      <div className="sel_text">{selectedPropertyType}</div>

      {isOpen && (
        <div className="property_drop">
          {Object.keys(propertyTypes).map((category) => (
            <div key={category}>
              <div
                className="comercial_block d-flex"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCategoryVisibility(category);
                }}
              >
                <div className="left_commerc">
                  <input className="boxx" type="checkbox" checked={areAllSubtypesSelected(category)} onClick={(e) => toggleAllSubtypes(e, category)} />
                  <h3>{category}</h3>
                </div>
                <div className="chevron">
                  <img src={Chevron} alt="chevron" style={categoryOpenStates[category] ? {} : { transform: "rotate(180deg)" }} />
                </div>
              </div>
              {categoryOpenStates[category] && <div className="options">{renderOptions(propertyTypes[category], category)}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PropertyTypeDropdown;
