import React, { useState, useEffect } from "react";
import "./Agents.css";
import { Link } from "react-router-dom";
import Url from "../../Element/Api";
import { useDispatch } from "react-redux";
import { updateRealtorInfo } from "../../Redux/Action/action";

function Agents() {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const Domain = window.location.hostname;
  const dispatch = useDispatch();

  //Function to fetch realtor data
  useEffect(() => {
    const fetchRealtors = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/getRealtors?domain=${Domain}&pageSize=4`);
        if (!response.ok) {
          throw new Error("Failed to fetch realtors");
        }
        const data = await response.json();
        setAgents(data.realtors.slice(0, 4));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching realtors:", error);
        setError("Failed to fetch realtors. Please try again later.");
        setLoading(false);
      }
    };

    fetchRealtors();
  }, []);

  
  //Function to save realtor id on redux
  const handleRealtorClick = (realtorId) => {
    dispatch(updateRealtorInfo(realtorId));
  };

  return (
    <div className="agent_list">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>Our Realtors</h2>
            {agents.length === 0 && <div className="no_realtor">No realtors available.</div>}
            <div className="overall_agents d-flex">
              {agents.map((agent, index) => (
                <div className="agent_one" key={index} onClick={() => handleRealtorClick(agent._id)}>
                  <Link to={`/agent/${agent.firstname.toLowerCase() + "-" + agent.lastname.toLowerCase()}`}>
                    <img src={agent.image} className="img-fluid" alt="agents" />
                    <h3>
                      {agent.firstname} {agent.lastname}
                    </h3>
                    <h4>{agent.profileTagline}</h4>
                    <p>{agent.metaDescription}</p>
                    <div className="view_agent">View Profile</div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agents;
