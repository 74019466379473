import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import Url from "../Element/Api";
import ContactForm from "./ContactForm";
import Agents from "../HomePage/Agents/Agents";
import CtaOne from "../HomePage/CtaOne/CtaOne";
import ClipLoader from "react-spinners/ClipLoader";

function ContactUs() {
  const [loading, setLoading] = useState(true);
  const [Contactusdata, setContactusdata] = useState({});

  //Function for getting contact us data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const Domain = window.location.hostname;
        const response = await fetch(`${Url.BASEURL}/api/user/broker-contactus?domain=${Domain}`);
        const data = await response.json();
        setContactusdata(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="full_contactus">
      <div className="container-fluid">
        <div className="row d_mis">
          {loading ? (
            <div className="col-lg-6">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" />
              </div>
            </div>
          ) : (
            <div className="col-lg-6">
              <div className="contact_text">
                <h1>{Contactusdata.contactus && Contactusdata.contactus.length > 0 ? Contactusdata.contactus[0].title : "N/A"}</h1>
                <p dangerouslySetInnerHTML={{ __html: `${Contactusdata.contactus && Contactusdata.contactus.length > 0 ? Contactusdata.contactus[0].description : "N/A"}` }} />
              </div>
            </div>
          )}
          <div className="col-lg-6">
            <div className="location_map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.7104613024653!2d-122.8828031!3d45.495775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950e491f21ed19%3A0x2c833f8ef7c511b4!2s20001%20SW%20Tualatin%20Valley%20Hwy%2C%20Beaverton%2C%20OR%2097006%2C%20USA!5e0!3m2!1sen!2sin!4v1698298246128!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0" }} allowFullScreen loading="lazy" title="Location Map" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_cta">
        <CtaOne />
      </div>
      <ContactForm />
      <div className="about_team">
        <Agents />
      </div>
    </div>
  );
}

export default ContactUs;
