import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./PropertyDetail.css";
import Url from "../Element/Api";
import Car from "../../public/car.png";
import Img1 from "../../public/p1.svg";
import Img2 from "../../public/p2.svg";
import Img3 from "../../public/p3.svg";
import Img4 from "../../public/p4.svg";
import Img5 from "../../public/p5.svg";
import Img6 from "../../public/p6.svg";
import Img7 from "../../public/p7.svg";
import Arrow from "../../public/down-arrow.svg";

const Directionservice = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [distance, setDistance] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [propertyData, setPropertyData] = useState();
  const [locationName, setLocationName] = useState("");
  const [durationValue, setDurationvalue] = useState(null);
  const [selectedIconA, setSelectedIconA] = useState(Car);
  const [dropdownOpenA, setDropdownOpenA] = useState(false);
  const [selectedIconB, setSelectedIconB] = useState(Car);
  const [dropdownOpenB, setDropdownOpenB] = useState(false);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [SelectedlocationName, setSelectedLocationName] = useState("");
  const [selectedTravelModeA, setSelectedTravelModeA] = useState("");
  const [selectedTravelModeBy, setSelectedTravelModeBy] = useState("");
  const [selectedTravelModeB, setSelectedTravelModeB] = useState("");
  const [editDropdownOpenA, setEditDropdownOpenA] = useState(false);
  const [editDropdownOpenB, setEditDropdownOpenB] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [tempLocationName, setTempLocationName] = useState("");

  //Frtching property data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/mls/reso/property/${id}??expand=Media,Member&select=ListingKey,ModificationTimestamp,Latitude,Longitude,StandardStatus,BedroomsTotal,BathroomsTotalInteger,Country,City,PostalCode,ListPrice,Photo1URL,BuildingAreaCalculated,UnparsedAddress,PublicRemarks,ListAgentFullName,OnMarketDate,ModificationTimestamp,PropertyType,PropertySubType,YearBuilt,BuildingAreaTotal,FireplaceFeatures,Stories,Sewer,ArchitecturalStyle,TaxYear,TaxAnnualAmount,Country,Directions,ListingService,LotFeatures,LotSizeRange,MlsStatus,ListingTerms,MainLevelAreaTotal,Basement,BuildingAreaCalculated,Roof,BuildingAreaDescription,ExteriorDescription,FuelDescription,Heating,UpperLevelAreaTotal,WaterSource,ParkingFeatures,PropertyCondition,RoadSurfaceType`);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setPropertyData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);


  const handleIconClickA = () => {
    setDropdownOpenA(!dropdownOpenA);
  };

  const handleEditClickA = () => {
    setEditDropdownOpenA(!editDropdownOpenA);
  };

  
  //Function for 1st selction
  const handleSelectionA = (icon, label, travelMode) => {
    if (label === "Change Location") {
      setIsEditingLocation(true);
      setTempLocationName(locationName);
      setLocationName("");
    }

    if (label !== "Change Location" && !label.includes("Remove Location")) {
      setSelectedTravelModeA(travelMode);
      setSelectedTravelModeBy(label);
      setSelectedIconA(icon);
    }

    if (label.includes("Remove Location")) {
      if (directionsRenderer) {
        directionsRenderer.setMap(null);
        setDirectionsRenderer(null);
      }
      setDistance(null);
      setDurationvalue(null);
      setLocationName("");
      setSelectedLocationName("");
    }
    setDropdownOpenA(false);
    setEditDropdownOpenA(false);
  };

  
  //Function cancelling the selected location
  const handleCancelClick = () => {
    setIsEditingLocation(false);
    setLocationName(tempLocationName);
  };

  
  const handleSelectionB = (icon, label, travelMode) => {
    setSelectedIconB(icon);
    setSelectedTravelModeB(travelMode);
    setDropdownOpenB(false);
    setEditDropdownOpenB(false);
  };

  
  //Function for showing options
  const renderDropdown = (dropdownType, isEditDropdown) => {
    const dropdownItems = isEditDropdown
      ? [
          [Img3, "Walk", "walking"],
          [Img1, "Cycle", "bicycling"],
          [Img2, "Bike", "driving"],
          [Img4, "Car", "driving"],
          [Img5, "Train", "transit"],
          [Img6, "Change Location"],
          [Img7, "Remove Location"],
        ]
      : [
          [Img3, "Walk", "walking"],
          [Img1, "Cycle", "bicycling"],
          [Img2, "Bike", "driving"],
          [Img4, "Car", "driving"],
          [Img5, "Train", "transit"],
        ];
    const isOpen = dropdownType === "A" ? (isEditDropdown ? editDropdownOpenA : dropdownOpenA) : isEditDropdown ? editDropdownOpenB : dropdownOpenB;
    const handleSelection = dropdownType === "A" ? handleSelectionA : handleSelectionB;
    return (
      isOpen && (
        <div className="locate_drop_down">
          {dropdownItems.map(([img, label, travelMode], index) => (
            <p key={index} onClick={() => handleSelection(img, label, travelMode)}>
              <img src={img} alt={label} />
              {label}
            </p>
          ))}
        </div>
      )
    );
  };

  //Function for loading map
  useEffect(() => {
    const loadMap = () => {
      if (!propertyData) {
        return;
      }

      const { Latitude, Longitude } = propertyData;
      if (!Latitude || !Longitude) {
        console.error("Latitude or Longitude is missing in propertyData.");
        return;
      }

      const mapOptions = {
        center: { lat: Latitude, lng: Longitude },
        zoom: 12,
      };

      const map = new window.google.maps.Map(document.getElementById("map"), mapOptions);
      const marker = new window.google.maps.Marker({
        position: {
          lat: Latitude,
          lng: Longitude,
        },
        map,
        title: "Home",
      });
      setDirectionsRenderer(new window.google.maps.DirectionsRenderer({ map }));
    };

    if (propertyData) {
      loadMap();
    }
  }, [propertyData]);

  
  //Function for changing location
  const handleLocationChange = (event) => {
    const value = event.target.value;
    setLocationName(value);
  };

  
  //Function for fetching suggested location
  const fetchSuggestions = async () => {
    try {
      if (locationName.trim() !== "" && locationName !== SelectedlocationName) {
        const response = await axios.get(`${Url.BASEURL}/api/user/suggestions?input=${locationName}`);
        const responseData = response.data;
        setSuggestions(responseData);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      setSuggestions([]);
    }
  };

  //Function for selecting suggested location
  const handleSuggestionClick = (suggestion) => {
    setLocationName(suggestion.description);
    setSelectedLocationName(suggestion.description);
    setIsEditingLocation(false);
    setSuggestions([]);
  };

  //Function for calculating distance and time from property to selected distance
  useEffect(() => {
    const calculateDistance = async () => {
      try {
        if (!directionsRenderer) {
          const mapOptions = {
            center: { lat: propertyData.Latitude, lng: propertyData.Longitude },
            zoom: 12,
          };
          const map = new window.google.maps.Map(document.getElementById("map"), mapOptions);
          setDirectionsRenderer(new window.google.maps.DirectionsRenderer({ map }));
        }

        const response = await axios.post(`${Url.BASEURL}/api/user/getDistance`, {
          origin: `${propertyData.Latitude},${propertyData.Longitude}`,
          destination: SelectedlocationName,
          travelMode: selectedTravelModeA,
        });

        if (response.data) {
          const distanceValue = response.data.distance;
          const durationvalue = response.data.duration;
          setDistance(distanceValue);
          setDurationvalue(durationvalue);

          const directionsService = new window.google.maps.DirectionsService();
          directionsService.route(
            {
              origin: `${propertyData.Latitude},${propertyData.Longitude}`,
              destination: SelectedlocationName,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                if (directionsRenderer) {
                  directionsRenderer.setDirections(result);
                }
              } else {
                console.error(`Error fetching directions: ${status}`);
              }
            }
          );
        } else {
          console.error("Error fetching distance:", response.data);
        }
      } catch (error) {
        console.error("Error calculating distance:", error);
      }
    };

    calculateDistance();
  }, [SelectedlocationName, selectedTravelModeA]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchSuggestions();
    }, 1000);
    return () => clearTimeout(timerId);
  }, [locationName]);

  return (
    <div className="map_area">
      <div id="map" style={{ height: "350px", width: "100%" }} />
      {durationValue ? (
        <div className="overall_calculator_diatnace d-flex second_full_dist">
          <div className="left_part_dist">
            <div className="a_letter">A</div>
          </div>
          <div className="right_part_dist illusion">
            {isEditingLocation ? (
              <div className="edit_location_container">
                <input type="text" className="form-control locate_control" placeholder="Write Location Name here to Calculate Distance" value={locationName} onChange={handleLocationChange} />
                {suggestions.length > 0 && (
                  <div className="suggestion-direction-container">
                    <ul>
                      {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="location_populated">
                  To {SelectedlocationName}, {distance}
                </div>
                <div className="btm_row d-flex">
                  <div className="btm_l_row">
                    <img src={selectedIconA} alt="Selected mode" className="selected-iconer" />
                  </div>
                  <div className="btm_rt_row">
                    By {selectedTravelModeBy} <span>{durationValue}</span> mins
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="edit_distance" onClick={isEditingLocation ? handleCancelClick : handleEditClickA}>
            {isEditingLocation ? "Cancel" : "Edit"}
            {renderDropdown("A", true)}
          </div>
        </div>
      ) : (
        /* Section A Inputs and Suggestions */
        <div className="overall_calculator_diatnace d-flex">
          <div className="left_part_dist" onClick={handleIconClickA}>
            <img src={selectedIconA} alt="Selected mode" className="selected-iconer" />
            <img src={Arrow} alt="Arrow" />
            {renderDropdown("A", false)}
          </div>
          <div className="right_part_dist">
            <input type="text" className="form-control locate_control" placeholder="Write Location Name here to Calculate Distance" value={locationName} onChange={handleLocationChange} />
            {suggestions.length > 0 && (
              <div className="suggestion-direction-container">
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Directionservice;
