import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const color = "#22A9E0";

const Loader = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <ClipLoader color={color} loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
    </div>
  );
};

export default Loader;
