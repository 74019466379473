import React from "react";
import PropertySearchResult from "../SearchByArea/PropertySearchResult/PropertySearchResult";

function SearchByArea() {
  return (
    <div className="searchview-container">
      <PropertySearchResult />
    </div>
  );
}

export default SearchByArea;
