import React, { createContext, useContext, useState } from "react";
const SelectedOptionsContext = createContext();

export const SelectedOptionsProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isNewConstructionSelected, setIsNewConstructionSelected] = useState(false);
  const [listingType, setListingType] = useState("");
  const [selectedBeds, setSelectedBeds] = useState("");
  const [selectedBathrooms, setSelectedBathrooms] = useState("");
  const [selectedGarage, setSelectedGarage] = useState("");
  const [selectedStories, setSelectedStories] = useState("");
  const [selectedParkingSpace, setSelectedParkingSpace] = useState("");
  const [selectedUnits, setSelectedUnits] = useState("");
  const [selectedTimeOnSite, setSelectedTimeOnSite] = useState("");
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [squareFeet, setSquareFeet] = useState({ min: "", max: "" });
  const [yearBuilt, setYearBuilt] = useState({ minYear: "", maxYear: "" });
  const [Hoafee, setHoafee] = useState({ minMonthly: "", maxMonthly: "" });
  const [Status, setStatus] = useState("location1");
  const [propertyType, setpropertyType] = useState("");
  const [propertysubType, setpropertysubType] = useState("");
  const [Specialcondition, setSpecialcondition] = useState("");
  const [Style, setStyle] = useState("");
  const [lotSizeadvance, setLotSizeadvance] = useState({ unit: "acres", min: "", max: "" });
  const [showredirectloginModal, setShowredirectloginModal] = useState(false);
  const [Brandings, setBrandings] = useState();
  const [selectedSearchvalue, setselectedSearchvalue] = useState();
  const [selectedType, setselectedType] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [lifestyleOpen, setLifestyleOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [featuredPropertydata, setfeaturedPropertydata] = useState([]);
  const [isTriggeredsearch, setisTriggeredsearch] = useState(false);
  const [isTriggeredisochrone, setisTriggeredisochrone] = useState(false);
  const [savedProperties, setSavedProperties] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState({
    verified: false,
    message: "",
  });
  const [selectedBudget, setSelectedBudget] = useState({
    minPrice: "",
    maxPrice: "",
    minMonthly: "",
    maxMonthly: "",
  });

  const [searchValue1, setSearchValue1] = useState("");
  const [nearsearchresult1, setnearsearchresult1] = useState([]);
  const [selectedSuggestion1, setSelectedSuggestion1] = useState(null);
  const [activeModesearch1, setactiveModesearch1] = useState("distance");
  const [selectednearsearchresult1, setselectednearsearchresult1] = useState([]);
  const [sliderValuesearch1, setsliderValuesearch1] = useState({
    value: 5,
    mode: "distance",
  });

  const [searchValue2, setSearchValue2] = useState("");
  const [nearsearchresult2, setnearsearchresult2] = useState([]);
  const [selectedSuggestion2, setSelectedSuggestion2] = useState(null);
  const [activeModesearch2, setactiveModesearch2] = useState("distance");
  const [selectednearsearchresult2, setselectednearsearchresult2] = useState([]);
  const [sliderValuesearch2, setsliderValuesearch2] = useState({
    value: 5,
    mode: "distance",
  });

  const [searchValue3, setSearchValue3] = useState("");
  const [nearsearchresult3, setnearsearchresult3] = useState([]);
  const [selectedSuggestion3, setSelectedSuggestion3] = useState(null);
  const [activeModesearch3, setactiveModesearch3] = useState("distance");
  const [selectednearsearchresult3, setselectednearsearchresult3] = useState([]);
  const [sliderValuesearch3, setsliderValuesearch3] = useState({
    value: 5,
    mode: "distance",
  });

  return (
    <SelectedOptionsContext.Provider
      value={{
        selectedType,
        setselectedType,
        selectedFeatures,
        setSelectedFeatures,
        selectedBudget,
        setSelectedBudget,
        searchValue1,
        setSearchValue1,
        sliderValuesearch1,
        setsliderValuesearch1,
        activeModesearch1,
        setactiveModesearch1,
        activeModesearch2,
        setactiveModesearch2,
        activeModesearch3,
        setactiveModesearch3,
        searchValue2,
        setSearchValue2,
        sliderValuesearch2,
        setsliderValuesearch2,
        searchValue3,
        setSearchValue3,
        sliderValuesearch3,
        setsliderValuesearch3,
        searchValue,
        setSearchValue,
        selectedSearchvalue,
        setselectedSearchvalue,
        nearsearchresult1,
        setnearsearchresult1,
        selectednearsearchresult1,
        setselectednearsearchresult1,
        selectednearsearchresult2,
        setselectednearsearchresult2,
        selectednearsearchresult3,
        setselectednearsearchresult3,
        nearsearchresult2,
        setnearsearchresult2,
        nearsearchresult3,
        setnearsearchresult3,
        lifestyleOpen,
        setLifestyleOpen,
        setIsOpen,
        isOpen,
        featuredPropertydata,
        setfeaturedPropertydata,
        isTriggeredsearch,
        setisTriggeredsearch,
        isTriggeredisochrone,
        setisTriggeredisochrone,
        selectedSuggestion1,
        setSelectedSuggestion1,
        selectedSuggestion2,
        setSelectedSuggestion2,
        selectedSuggestion3,
        setSelectedSuggestion3,
        verificationStatus,
        setVerificationStatus,
        savedProperties,
        setSavedProperties,
        listingType,
        setListingType,
        selectedBeds,
        setSelectedBeds,
        selectedBathrooms,
        setSelectedBathrooms,
        selectedGarage,
        setSelectedGarage,
        selectedStories,
        setSelectedStories,
        selectedParkingSpace,
        setSelectedParkingSpace,
        selectedUnits,
        setSelectedUnits,
        selectedTimeOnSite,
        setSelectedTimeOnSite,
        selectedDataSource,
        setSelectedDataSource,
        squareFeet,
        setSquareFeet,
        yearBuilt,
        setYearBuilt,
        Hoafee,
        setHoafee,
        Status,
        setStatus,
        propertyType,
        setpropertyType,
        propertysubType,
        setpropertysubType,
        Specialcondition,
        setSpecialcondition,
        Style,
        setStyle,
        lotSizeadvance,
        setLotSizeadvance,
        showredirectloginModal,
        setShowredirectloginModal,
        Brandings,
        setBrandings,
        isNewConstructionSelected,
        setIsNewConstructionSelected,
      }}
    >
      {children}
    </SelectedOptionsContext.Provider>
  );
};

export const useSelectedOptions = () => {
  const context = useContext(SelectedOptionsContext);
  if (context === undefined) {
    throw new Error("useSelectedOptions must be used within a SelectedOptionsProvider");
  }
  return context;
};
