import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Blogs/Blogs.css";
import Url from "../Element/Api";
import Loader from "../Element/Loader";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import DemoImage from "../../public/assets/agent0.png";
import Clock from "../../public/assets/blogs/clock.svg";
import Angle from "../../public/assets/blogs/angle1.svg";
import Angle2 from "../../public/assets/blogs/angle2.svg";
import CommentwithoutLogin from "../Element/NotLoginmodal";

function SingleBlog() {
  const { slug } = useParams();
  const Domain = window.location.hostname;
  const UserIdtoken = useSelector((state) => state.jwtuser);
  const regdtoken = UserIdtoken.jwtToken;
  const image = UserIdtoken.image ? UserIdtoken.image : DemoImage;
  const [blog, setBlog] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [nextBlog, setNextBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [metaTitle, setMetaTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [previousBlog, setPreviousBlog] = useState(null);
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [nestedReplyText, setNestedReplyText] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [activeCommentField, setActiveCommentField] = useState(null);
  const [nestedReplyCommentId, setNestedReplyCommentId] = useState(null);

  //Function for getting blog based on slug
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/blog/${slug}`);
        const data = await response.json();
        setBlog(data.blogWithImageURL);
        setMetaTitle(data.blogWithImageURL.meta_title);
        setMetaDescription(data.blogWithImageURL.meta_description);
        await fetchPreviousAndNextBlogs(slug);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, [slug]);

  //Function for getting previous and next blogs to the parent blog
  const fetchPreviousAndNextBlogs = async (currentBlogId) => {
    try {
      const response = await axios.get(`${Url.BASEURL}/api/user/blog/all?domain=${Domain}`);
      const data = response.data;
      if (!data || !Array.isArray(data.blogs)) {
        console.error("API did not return a valid array of blogs:", data);
        return;
      }
      const blogsArray = data.blogs;
      const currentIndex = blogsArray.findIndex((blog) => blog.slug === currentBlogId);

      if (currentIndex > 0) {
        setPreviousBlog({
          title: blogsArray[currentIndex - 1].title,
          link: `/single-blog/${blogsArray[currentIndex - 1].slug}`,
        });
      }

      if (currentIndex < blogsArray.length - 1) {
        setNextBlog({
          title: blogsArray[currentIndex + 1].title,
          link: `/single-blog/${blogsArray[currentIndex + 1].slug}`,
        });
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };


  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  //Function for formatting date for comments
  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diff = Math.floor((now - date) / (1000 * 60 * 60 * 24)); // Difference in days

    if (diff === 0) {
      return "today";
    } else if (diff === 1) {
      return "yesterday";
    } else {
      return `${diff} days ago`;
    }
  };

  //Function for adding comment
  const handleAddComment = async () => {
    try {
      if (!regdtoken) {
        setShowModal(true);
        return;
      }
      const requestBody = {
        commentedText: newComment,
        image: image,
      };

      const response = await fetch(`${Url.BASEURL}/api/user/create-comment/${blog._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${regdtoken}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      setNewComment("");
      const newCommentData = {
        commentId: data.commentId,
        commentedText: data.comment,
        user: {
          name: data.name,
          image: data.image,
        },
        createdAt: data.time,
        replies: [],
      };
      setComments([...comments, newCommentData]);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  //Function for giving reply to the comment
  const handleCommentReply = async (commentId) => {
    try {
      const requestBody = {
        repliedText: newComment,
        image: image,
      };
      const response = await fetch(`${Url.BASEURL}/api/user/create-reply/${commentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${regdtoken}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      setNewComment("");
      setReplyCommentId(null);

      const updatedComments = comments.map((comment) => {
        if (comment.commentId === commentId) {
          const newReplyData = {
            replyId: data.comment._id,
            repliedText: data.comment,
            user: {
              name: data.name,
              image: data.image,
            },
            createdAt: data.time,
          };
          return { ...comment, replies: [...comment.replies, newReplyData] };
        }
        return comment;
      });
      setComments(updatedComments);
    } catch (error) {
      console.error("Error replying to comment:", error);
    }
  };

  //Function for giving reply to the replied comment
  const handleNestedReply = async (commentId) => {
    try {
      if (!nestedReplyText.trim()) {
        console.error("Nested reply text is empty");
        return;
      }

      const requestBody = {
        repliedText: nestedReplyText,
        image: image,
      };

      const response = await fetch(`${Url.BASEURL}/api/user/create-nestedreply/${commentId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${regdtoken}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      setNestedReplyText("");
      setNestedReplyCommentId(null);

      // Update comments state with the new nested reply
      const updatedComments = comments.map((comment) => {
        const updatedReplies = comment.replies.map((reply) => {
          return {
            ...reply,
            nestedReplies: [
              ...reply.nestedReplies,
              {
                nestedReplyId: data.nestedReplyId,
                repliedText: data.reply,
                user: {
                  name: data.name,
                  image: data.image,
                },
                createdAt: data.time,
              },
            ],
          };
        });
        return { ...comment, replies: updatedReplies };
      });
      setComments(updatedComments);
    } catch (error) {
      console.error("Error replying to comment:", error);
    }
  };

  //Function for fetching all comments
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(`${Url.BASEURL}/api/user/get-all-comments/${blog._id}`);
        const data = await response.json();
        if (data.success) {
          setComments(data.commentsWithReplies);
        } else {
          console.error("Error fetching comments:", data.message);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };
    fetchComments();
  }, [blog]);

  //Function for getting commentId
  const handleReplyButtonClick = (commentId) => {
    setReplyCommentId(commentId);
    setActiveCommentField("reply");
  };

  //Function for getting commentId
  const handleNestedReplyButtonClick = (commentId) => {
    setNestedReplyCommentId(commentId);
    setActiveCommentField("nestedReply");
  };

  return (
    <div className="blog_single">
      {loading ? (
        <Loader />
      ) : (
        <>
          {blog && (
            <>
              <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
              </Helmet>
              <div className="blog_banner_container">
                <div className="blog_banner">
                  <img src={blog.imageURL} className="img-fluid" alt="Blog-Banner" />
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single_data">
                      <h1>{blog.title}</h1>
                      <div className="date_time">
                        <span>
                          <img src={Clock} className="img-fluid" alt="time" />
                        </span>
                        {formatDateString(blog.createdAt)}
                      </div>

                      <p dangerouslySetInnerHTML={{ __html: blog.description }} />

                      <div className="comment_zone" style={{ maxHeight: "350px", overflowY: "auto" }}>
                        <h2>Comments</h2>
                        {comments.map((comment) => (
                          <div key={comment.commentId} className="single_comment_loop">
                            <div className="single_commenting d-flex">
                              <div className="comment_image">
                                <img src={comment.user.image} className="user" alt="User" />
                              </div>
                              <div className="comment_data">
                                <div className="comment_top_row d-flex">
                                  <div className="comment_name">{comment.user.name}</div>
                                  <div className="time-ago">{formatRelativeTime(comment.createdAt)}</div>
                                  <div className="comment_reply" onClick={() => handleReplyButtonClick(comment.commentId)} style={{ cursor: "pointer" }}>
                                    reply
                                  </div>
                                </div>
                                <p>{comment.commentedText}</p>
                                {comment.replies.map((reply) => (
                                  <div key={reply.replyId} className="single_comment_loop reply">
                                    <div className="single_commenting d-flex">
                                      <div className="comment_image">
                                        <img src={reply.user.image} className="user" alt="User" />
                                      </div>
                                      <div className="comment_data">
                                        <div className="comment_top_row d-flex">
                                          <div className="comment_name">{reply.user.name}</div>
                                          <div className="time-ago">{formatRelativeTime(reply.createdAt)}</div>
                                          <div className="comment_reply" style={{ cursor: "pointer" }} onClick={() => handleNestedReplyButtonClick(reply.replyId)}>
                                            reply
                                          </div>
                                        </div>
                                        <p style={{ width: "85%", wordWrap: "break-word" }}>{reply.repliedText}</p>
                                        {activeCommentField === "nestedReply" && nestedReplyCommentId === reply.replyId && (
                                          <div className="comment_textarea">
                                            <textarea className="form-control" placeholder="Write a  reply..." value={nestedReplyText} onChange={(e) => setNestedReplyText(e.target.value)} style={{ width: "85%", minHeight: "50px" }} />
                                            <button className="reply_submit_btn" onClick={() => handleNestedReply(nestedReplyCommentId)}>
                                              Reply
                                            </button>
                                          </div>
                                        )}
                                        {reply.nestedReplies &&
                                          reply.nestedReplies.map((nestedReply) => (
                                            <div key={nestedReply.nestedReplyId} className="single_comment_loop nested-reply">
                                              <div className="single_commenting d-flex">
                                                <div className="comment_image">
                                                  <img src={nestedReply.user.image} className="user" alt="User" />
                                                </div>
                                                <div className="comment_data">
                                                  <div className="comment_top_row d-flex">
                                                    <div className="comment_name">{nestedReply.user.name}</div>
                                                    <div className="time-ago">{formatRelativeTime(nestedReply.createdAt)}</div>
                                                  </div>
                                                  <p style={{ width: "85%", wordWrap: "break-word" }}>{nestedReply.repliedText}</p>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                ))}

                                {activeCommentField === "reply" && replyCommentId === comment.commentId && (
                                  <div className="comment_textarea">
                                    <textarea className="form-control" placeholder="Write a reply..." value={newComment} onChange={(e) => setNewComment(e.target.value)} style={{ width: "85%", minHeight: "50px" }} />
                                    <button className="reply_submit_btn" onClick={() => handleCommentReply(replyCommentId)}>
                                      Reply
                                    </button>
                                  </div>
                                )}
                                {/* {nestedReplyCommentId === comment.replies.replyId && (
                                  <div className="comment_textarea">
                                    <textarea className="form-control" placeholder="Write a nested reply..." value={nestedReplyText} onChange={(e) => setNestedReplyText(e.target.value)} style={{ width: "85%", minHeight: "50px" }} />
                                    <button className="reply_submit_btn" onClick={() => handleNestedReply(comment.replies.replyId)}>
                                      Submit Nested Reply
                                    </button>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        ))}

                        {/* {replyCommentId && (
                          <div className="comment_textarea">
                            <textarea className="form-control custom_comment_box" placeholder="Write a reply..." value={newComment} onChange={(e) => setNewComment(e.target.value)}></textarea>
                            <button className="comment_submit_btn" onClick={() => handleCommentReply(replyCommentId)}>
                              Submit Reply
                            </button>
                          </div>
                        )} */}
                      </div>
                      {!replyCommentId && !nestedReplyCommentId && (
                        <div className="comment_textarea">
                          <textarea className="form-control custom_comment_box" placeholder="Write a comment..." value={newComment} onChange={(e) => setNewComment(e.target.value)}></textarea>
                          <button className="comment_submit_btn" onClick={handleAddComment}>
                            Submit
                          </button>
                        </div>
                      )}

                      <div className="previous_next d-flex">
                        {previousBlog && (
                          <div className="previous_blog">
                            <Link to={previousBlog.link}>
                              <div className="top_blog">
                                <span>
                                  <img src={Angle2} className="img-fluid" alt="arrows" />
                                </span>
                                Previous
                              </div>
                              <div className="bottom_blog">{previousBlog.title}</div>
                            </Link>
                          </div>
                        )}
                        {nextBlog && (
                          <div className="previous_blog next_blog">
                            <Link to={nextBlog.link}>
                              <div className="top_blog">
                                Next{" "}
                                <span>
                                  <img src={Angle} className="img-fluid" alt="arrows" />
                                </span>
                              </div>
                              <div className="bottom_blog">{nextBlog.title}</div>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <CommentwithoutLogin showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default SingleBlog;
