import React, { useState, useEffect, useRef } from "react";
import "./BudgetDropdown.css";
import Dollar from "../../../public/assets/dollar.svg";
import ReactSlider from "react-slider";

//Min and max value options
const minPriceOptions = ["Min", "$ 10000", "$ 20000", "$ 30000", "$ 50000", "$ 75000", "$ 100000", "$ +100,000"];
const maxPriceOptions = ["Max", "$ 100000", "$ 200000", "$ 300000", "$ 500000", "$ 750000", "$ 1000000", "$ +1000,000"];

//Function for showing the Min and max value in dropdown
function Dropdown({ placeholder, openDropdown, setOpenDropdown, type, selectedValue, setSelectedValue }) {
  const handleInputChange = (e, type) => {
    let { value } = e.target;
    value = value.replace(/[^\d]/g, "");
    setSelectedValue((prevBudget) => ({
      ...prevBudget,
      [type]: value || "",
    }));
  };

  return (
    <div className="dropdown-container" onClick={(e) => e.stopPropagation()}>
      <input type="text" className={`form-control dropdown-input ${type}`} placeholder={placeholder} value={selectedValue[type]} onChange={(e) => handleInputChange(e, type)} onClick={() => setOpenDropdown(openDropdown === type ? null : type)} />
    </div>
  );
}

function BudgetDropdown({ onSelectBudget, isDataSent }) {
  const dropdownRef = useRef(null);
  const minMonthlyOptions = minPriceOptions;
  const maxMonthlyOptions = maxPriceOptions;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("PRICE");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState({
    minPrice: "",
    maxPrice: "",
    minMonthly: "",
    maxMonthly: "",
  });

  //Function to update the budget on state
  useEffect(() => {
    onSelectBudget(selectedBudget);
  }, [selectedBudget]);

  
  //Function to clear the state values after data sent 
  useEffect(() => {
    if (isDataSent) {
      setSelectedBudget({
        minPrice: "",
        maxPrice: "",
        minMonthly: "",
        maxMonthly: "",
      });
    }
  }, [isDataSent]);

  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };

  //Function to close the modal if clicked outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  
  //Function to update the minmonthly and minprice budget on state
  const handleMinChange = (value, tab) => {
    const numericValue = parseInt(value, 10);
    const formattedValue = `${numericValue.toLocaleString()}`.replace(/,/g, ""); // Remove commas
    setSelectedBudget((prev) => ({
      ...prev,
      [tab === "PRICE" ? "minPrice" : "minMonthly"]: formattedValue,
    }));
  };

  
  //Function to update the maxprice and maxmonthly budget on state
  const handleMaxChange = (value, tab) => {
    const numericValue = parseInt(value, 10);
    const formattedValue = `${numericValue.toLocaleString()}`.replace(/,/g, "");
    setSelectedBudget((prev) => ({
      ...prev,
      [tab === "PRICE" ? "maxPrice" : "maxMonthly"]: formattedValue,
    }));
  };

//Function to render options
const renderOptions = (options, setValueFunction, tab) => {
  return options.map((option, index) => (
    <div
      key={index}
      className={`Priceoption ${option.includes("Min") || option.includes("Max") ? "min-max-option" : ""}`}
      onClick={() => {
        // Only execute if the option is not "Min" or "Max"
        if (!option.includes("Min") && !option.includes("Max")) {
          const numericValue = option.replace(/^\D+/g, "");
          setValueFunction(numericValue, tab);
          setOpenDropdown(null);
        }
      }}
    >
      {option.includes("$") ? (
        <>
          <span className="dollar-sign">$</span>
          <span>{option.replace("$", "").trim()}</span>
        </>
      ) : (
        option
      )}
    </div>
  ));
};


  return (
    <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
      <div className="sel_icon">
        <img src={Dollar} alt="propertyType" />
      </div>
    <div className="sel_text">
        {activeTab === "PRICE" && (selectedBudget.minPrice !== "" || selectedBudget.maxPrice !== "") && `${selectedBudget.minPrice !== "" ? selectedBudget.minPrice : ""}${selectedBudget.minPrice !== "" && selectedBudget.maxPrice !== "" ? " - " : ""}${selectedBudget.maxPrice !== "" ? selectedBudget.maxPrice : ""}`}
        {activeTab === "MONTHLY_PAYMENT" && (selectedBudget.minMonthly !== "" || selectedBudget.maxMonthly !== "") && `${selectedBudget.minMonthly !== "" ? selectedBudget.minMonthly : ""}${selectedBudget.minMonthly !== "" && selectedBudget.maxMonthly !== "" ? " - " : ""}${selectedBudget.maxMonthly !== "" ? selectedBudget.maxMonthly : ""}`}
        {activeTab === "PRICE" && selectedBudget.minPrice === "" && selectedBudget.maxPrice === "" && selectedBudget.minMonthly === "" && selectedBudget.maxMonthly === "" && "Budget"}
      </div>
      {isOpen && (
        <div className="property_drop budget_drop" onClick={handleDropdownClick}>
          <div className="full_price_filter">
            <div className="right_swap budget_rad">
              <div className="left-radio">
                <input type="radio" id="uniqueRadio1" name="unique-radio-group" checked={activeTab === "MONTHLY_PAYMENT"} onChange={() => setActiveTab("MONTHLY_PAYMENT")} />
                <label htmlFor="uniqueRadio1" className="unique-radio-label">
                  <span>MONTHLY PAYMENT</span>
                </label>
              </div>
              <div className="right-radio">
                <input type="radio" id="uniqueRadio2" name="unique-radio-group" checked={activeTab === "PRICE"} onChange={() => setActiveTab("PRICE")} />
                <label htmlFor="uniqueRadio2" className="unique-radio-label">
                  <span>PRICE</span>
                </label>
              </div>
            </div>
            {activeTab === "PRICE" && (
              <div className="two_input_blocks d-flex square_ft price_data">
                <div className="left_input_blocks">
                  <Dropdown placeholder="Min Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="minPrice" selectedValue={selectedBudget} setSelectedValue={setSelectedBudget} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={100000} value={Number(String(selectedBudget.minPrice).replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMinChange(value, "PRICE")} />
                </div>
                <div className="right_input_blocks">
                  <Dropdown placeholder="Max Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="maxPrice" selectedValue={selectedBudget} setSelectedValue={setSelectedBudget} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={1000000} value={Number(String(selectedBudget.maxPrice).replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMaxChange(value, "PRICE")} />
                </div>
              </div>
            )}
            {activeTab === "MONTHLY_PAYMENT" && (
              <div className="two_input_blocks d-flex square_ft price_data">
                <div className="left_input_blocks">
                  <Dropdown placeholder="Min Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="minMonthly" selectedValue={selectedBudget} setSelectedValue={setSelectedBudget} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={100000} value={Number(selectedBudget.minMonthly.replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMinChange(value, "MONTHLY_PAYMENT")} />
                </div>
                <div className="right_input_blocks">
                  <Dropdown placeholder="Max Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="maxMonthly" selectedValue={selectedBudget} setSelectedValue={setSelectedBudget} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={1000000} value={Number(selectedBudget.maxMonthly.replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMaxChange(value, "MONTHLY_PAYMENT")} />
                </div>
              </div>
            )}

            <div className="common-options-container">
              {activeTab === "PRICE" && openDropdown === "minPrice" && renderOptions(minPriceOptions, (option) => handleMinChange(option, "PRICE"), "PRICE")}
              {activeTab === "PRICE" && openDropdown === "maxPrice" && renderOptions(maxPriceOptions, (option) => handleMaxChange(option, "PRICE"), "PRICE")}

              {activeTab === "MONTHLY_PAYMENT" && openDropdown === "minMonthly" && renderOptions(minMonthlyOptions, (option) => handleMinChange(option, "MONTHLY_PAYMENT"), "MONTHLY_PAYMENT")}
              {activeTab === "MONTHLY_PAYMENT" && openDropdown === "maxMonthly" && renderOptions(maxMonthlyOptions, (option) => handleMaxChange(option, "MONTHLY_PAYMENT"), "MONTHLY_PAYMENT")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BudgetDropdown;
