import React from "react";
import "./SearchView.css";
import Map from "./Map/Map";
import SearchForm from "./SearchForm/SearchForm";
import PropertySearchResult from "./PropertySearchResult/PropertySearchResult";

function SearchView() {
  return (
    <div className="searchview-container">
      <SearchForm />
      <Map />
      <PropertySearchResult />
    </div>
  );
}

export default SearchView;
