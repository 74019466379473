import React, { useState, useEffect, useRef } from "react";
import "./BudgetDropdown.css";
import Dollar from "../../../public/assets/dollar.svg";
import ReactSlider from "react-slider";
import { useSelectedOptions } from "../../SelectedOptionsContext";

function Dropdown({ placeholder, openDropdown, setOpenDropdown, type, selectedValue, setSelectedValue, selectedBudget, setSelectedBudget }) {
  const isEditable = type.includes('Monthly');

 const handleInputChange = (e) => {
  let value = e.target.value;

  value = value.replace(/[^0-9]/g, '');

  // Update the selectedBudget value based on the type
  if (type === 'minPrice') {
    setSelectedBudget({ ...selectedBudget, minPrice: value });
  } else if (type === 'maxPrice') {
    setSelectedBudget({ ...selectedBudget, maxPrice: value });
  } else if (type === 'minMonthly') {
    setSelectedBudget({ ...selectedBudget, minMonthly: value });
  } else if (type === 'maxMonthly') {
    setSelectedBudget({ ...selectedBudget, maxMonthly: value });
  }

  setSelectedValue(value);
};


  return (
    <div className="dropdown-container" onClick={(e) => e.stopPropagation()}>
      <input
        type="text"
        className={`form-control dropdown-input ${type}`}
        placeholder={placeholder}
        value={selectedValue}
        onChange={handleInputChange}
        onClick={() => setOpenDropdown(openDropdown === type ? null : type)}
      />
    </div>
  );
}

const minPriceOptions = ["Min", "$ 10000", "$ 20000", "$ 30000", "$ 50000", "$ 75000", "$ 100000", "$ +100,000"];
const maxPriceOptions = ["Max", "$ 100000", "$ 200000", "$ 300000", "$ 500000", "$ 750000", "$ 1000000", "$ +1000,000"];

function BudgetDropdown() {
  const dropdownRef = useRef(null);
  const minMonthlyOptions = minPriceOptions;
  const maxMonthlyOptions = maxPriceOptions;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("PRICE");
  const [openDropdown, setOpenDropdown] = useState(null);
  const { selectedBudget, setSelectedBudget } = useSelectedOptions();
  const [priceSliderValues, setPriceSliderValues] = useState({
    minPrice: selectedBudget.minPrice,
    maxPrice: selectedBudget.maxPrice,
  });
  const [monthlySliderValues, setMonthlySliderValues] = useState({
    minMonthly: selectedBudget.minMonthly,
    maxMonthly: selectedBudget.maxMonthly,
  });

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    const isRadio = e.target.type === "radio";
    if (isRadio && e.target.checked) {
      return;
    }

    if (activeTab === "MONTHLY_PAYMENT") {
      setPriceSliderValues({ minPrice: "", maxPrice: "" });
      setSelectedBudget({ ...selectedBudget, minPrice: "", maxPrice: "" });
    }

    if (activeTab === "PRICE") {
      setMonthlySliderValues({ minMonthly: "", maxMonthly: "" });
      setSelectedBudget({ ...selectedBudget, minMonthly: "", maxMonthly: "" });
    }

    setOpenDropdown(null);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleMinChange = (value, tab) => {
    const numericValue = parseInt(value, 10);
    const formattedValue = `${numericValue.toLocaleString()}`.replace(/,/g, ""); // Remove commas
    if (tab === "PRICE") {
      setPriceSliderValues({ ...priceSliderValues, minPrice: formattedValue });
      setSelectedBudget({ ...selectedBudget, minPrice: formattedValue });
    } else {
      setMonthlySliderValues({ ...monthlySliderValues, minMonthly: formattedValue });
      setSelectedBudget({ ...selectedBudget, minMonthly: formattedValue });
    }
  };

  const handleMaxChange = (value, tab) => {
    const numericValue = parseInt(value, 10);
    const formattedValue = `${numericValue.toLocaleString()}`.replace(/,/g, "");
    if (tab === "PRICE") {
      setPriceSliderValues({ ...priceSliderValues, maxPrice: formattedValue });
      setSelectedBudget({ ...selectedBudget, maxPrice: formattedValue });
    } else {
      setMonthlySliderValues({ ...monthlySliderValues, maxMonthly: formattedValue });
      setSelectedBudget({ ...selectedBudget, maxMonthly: formattedValue });
    }
  };

  const renderOptions = (options, setValueFunction, tab) => {
    return options.map((option, index) => (
      <div
        key={index}
        className={`Priceoption ${option.includes("Min") || option.includes("Max") ? "min-max-option" : ""}`}
        onClick={() => {
          const numericValue = option.replace(/^\D+/g, "");
          setValueFunction(numericValue, tab);
          setOpenDropdown(null);
        }}
      >
        {option.includes("$") ? (
          <>
            <span className="dollar-sign">$</span>
            <span>{option.replace("$", "").trim()}</span>
          </>
        ) : (
          option
        )}
      </div>
    ));
  };

  return (
    <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
      <div className="sel_icon">
        <img src={Dollar} alt="propertyType" />
      </div>
      {/* <div className="sel_text">Budget</div> */}
      <div className="sel_text">
        {activeTab === "PRICE" && (selectedBudget.minPrice !== "" || selectedBudget.maxPrice !== "") && `${selectedBudget.minPrice !== "" ? selectedBudget.minPrice : ""}${selectedBudget.minPrice !== "" && selectedBudget.maxPrice !== "" ? " - " : ""}${selectedBudget.maxPrice !== "" ? selectedBudget.maxPrice : ""}`}
        {activeTab === "MONTHLY_PAYMENT" && (selectedBudget.minMonthly !== "" || selectedBudget.maxMonthly !== "") && `${selectedBudget.minMonthly !== "" ? selectedBudget.minMonthly : ""}${selectedBudget.minMonthly !== "" && selectedBudget.maxMonthly !== "" ? " - " : ""}${selectedBudget.maxMonthly !== "" ? selectedBudget.maxMonthly : ""}`}
        {activeTab === "PRICE" && selectedBudget.minPrice === "" && selectedBudget.maxPrice === "" && selectedBudget.minMonthly === "" && selectedBudget.maxMonthly === "" && "Budget"}
      </div>

      {isOpen && (
        <div className="property_drop budget_drop" onClick={handleDropdownClick}>
          <div className="full_price_filter">
            <div className="right_swap budget_rad">
              <div className="left-radio">
                <input type="radio" id="uniqueRadio1" name="unique-radio-group" checked={activeTab === "MONTHLY_PAYMENT"} onChange={() => setActiveTab("MONTHLY_PAYMENT")} />
                <label htmlFor="uniqueRadio1" className="unique-radio-label">
                  <span>MONTHLY PAYMENT</span>
                </label>
              </div>
              <div className="right-radio">
                <input type="radio" id="uniqueRadio2" name="unique-radio-group" checked={activeTab === "PRICE"} onChange={() => setActiveTab("PRICE")} />
                <label htmlFor="uniqueRadio2" className="unique-radio-label">
                  <span>PRICE</span>
                </label>
              </div>
            </div>
            {activeTab === "PRICE" && (
              <div className="two_input_blocks d-flex square_ft price_data">
                <div className="left_input_blocks">
                  <Dropdown placeholder="Min Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="minPrice" selectedValue={priceSliderValues.minPrice} setSelectedValue={(value) => setPriceSliderValues({ ...priceSliderValues, minPrice: value })} selectedBudget={selectedBudget} setSelectedBudget={setSelectedBudget} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={100000} value={Number(String(priceSliderValues.minPrice).replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMinChange(value, "PRICE")} />
                </div>
                <div className="right_input_blocks">
                  <Dropdown placeholder="Max Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="maxPrice" selectedValue={priceSliderValues.maxPrice} setSelectedValue={(value) => setPriceSliderValues({ ...priceSliderValues, maxPrice: value })} selectedBudget={selectedBudget} setSelectedBudget={setSelectedBudget} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={1000000} value={Number(String(priceSliderValues.maxPrice).replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMaxChange(value, "PRICE")} />
                </div>
              </div>
            )}
            {activeTab === "MONTHLY_PAYMENT" && (
              <div className="two_input_blocks d-flex square_ft price_data">
                <div className="left_input_blocks">
                  <Dropdown placeholder="Min Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="minMonthly" selectedValue={monthlySliderValues.minMonthly} setSelectedValue={(value) => setMonthlySliderValues({ ...monthlySliderValues, minMonthly: value })} selectedBudget={selectedBudget} setSelectedBudget={setSelectedBudget} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={100000} value={Number(monthlySliderValues.minMonthly.replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMinChange(value, "MONTHLY_PAYMENT")} selectedBudget={selectedBudget} setSelectedBudget={setSelectedBudget} />
                </div>
                <div className="right_input_blocks">
                  <Dropdown placeholder="Max Price" openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} type="maxMonthly" selectedValue={monthlySliderValues.maxMonthly} setSelectedValue={(value) => setMonthlySliderValues({ ...monthlySliderValues, maxMonthly: value })} />
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={10000} max={1000000} value={Number(monthlySliderValues.maxMonthly.replace(/[^0-9.-]+/g, ""))} onChange={(value) => handleMaxChange(value, "MONTHLY_PAYMENT")} />
                </div>
              </div>
            )}
            <div className="common-options-container">
              {activeTab === "PRICE" && openDropdown === "minPrice" && renderOptions(minPriceOptions, (option) => setPriceSliderValues({ ...priceSliderValues, minPrice: option }), "PRICE")}
              {activeTab === "PRICE" && openDropdown === "maxPrice" && renderOptions(maxPriceOptions, (option) => setPriceSliderValues({ ...priceSliderValues, maxPrice: option }), "PRICE")}
              {activeTab === "MONTHLY_PAYMENT" && openDropdown === "minMonthly" && renderOptions(minMonthlyOptions, (option) => setMonthlySliderValues({ ...monthlySliderValues, minMonthly: option }), "MONTHLY_PAYMENT")}
              {activeTab === "MONTHLY_PAYMENT" && openDropdown === "maxMonthly" && renderOptions(maxMonthlyOptions, (option) => setMonthlySliderValues({ ...monthlySliderValues, maxMonthly: option }), "MONTHLY_PAYMENT")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default BudgetDropdown;
