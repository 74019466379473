import React from "react";
import "./Banner.css";

function Banner() {
  return (
    <div className="banner">
      <h1>Your Search, Your Way</h1>
    </div>
  );
}

export default Banner;
