import React, { useState, useEffect, useRef } from "react";
import "./LifeStyleSearchDropdown.css";
import SearchImage3 from "../../../public/assets/s2.svg";
import ReactSlider from "react-slider";
import axios from "axios";
import { useSelectedOptions } from "../../SelectedOptionsContext";
import Url from "../../Element/Api";

function LifeStyleSearchDropdown() {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // Search 1
  const [suggestions1, setSuggestions1] = useState([]);
  const [writtenData1, setWrittenData1] = useState("");
  const { searchValue1, setSearchValue1 } = useSelectedOptions();
  const [activeIconRow1, setActiveIconRow1] = useState("Vector");
  const { selectedSuggestion1, setSelectedSuggestion1 } = useSelectedOptions();
  const { activeModesearch1, setactiveModesearch1 } = useSelectedOptions("distance");
  const { sliderValuesearch1, setsliderValuesearch1 } = useSelectedOptions({
    value: 5,
    mode: "distance",
  });

  // Search 2
  const [suggestions2, setSuggestions2] = useState([]);
  const [writtenData2, setWrittenData2] = useState("");
  const { searchValue2, setSearchValue2 } = useSelectedOptions();
  const [activeIconRow2, setActiveIconRow2] = useState("Vector");
  const { selectedSuggestion2, setSelectedSuggestion2 } = useSelectedOptions();
  const { activeModesearch2, setactiveModesearch2 } = useSelectedOptions("distance");
  const { sliderValuesearch2, setsliderValuesearch2 } = useSelectedOptions({
    value: 5,
    mode: "distance",
  });

  // Search 3
  const [suggestions3, setSuggestions3] = useState([]);
  const [writtenData3, setWrittenData3] = useState("");
  const { searchValue3, setSearchValue3 } = useSelectedOptions();
  const { selectedSuggestion3, setSelectedSuggestion3 } = useSelectedOptions();
  const { activeModesearch3, setactiveModesearch3 } = useSelectedOptions("distance");
  const [activeIconRow3, setActiveIconRow3] = useState("Vector");
  const { sliderValuesearch3, setsliderValuesearch3 } = useSelectedOptions({
    value: 5,
    mode: "distance",
  });

  const fetchSuggestions1 = async () => {
    try {
      if (searchValue1 && searchValue1 !== selectedSuggestion1) {
        const response = await axios.get(`${Url.BASEURL}/api/user/suggestions`, {
          params: {
            input: searchValue1,
          },
        });
        const responseData = response.data;
        console.log("responsesuggestion", responseData);
        setSuggestions1(responseData);
      } else {
        setSuggestions1(null);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions1([]);
    }
  };

  const handleInputChange1 = (e) => {
    const inputValue = e.target.value;
    setSearchValue1(inputValue);

    const isNearby = /^nearby\s*$/i.test(inputValue);
    const isNearMe = /(\w+)\s+near\s+me/i.test(inputValue);

    if (inputValue.trim() === "" || isNearby || isNearMe) {
      setSuggestions1([]);
      setWrittenData1(inputValue);
      return;
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchSuggestions1();
    }, 1000);

    return () => clearTimeout(timerId);
  }, [searchValue1, selectedSuggestion1]);

  const handleSelectSuggestion1 = (suggestion) => {
    setSelectedSuggestion1(suggestion.description);
    setSearchValue1(suggestion.description);
    setSuggestions1([]);
  };

  const fetchSuggestions2 = async () => {
    try {
      if (searchValue2 && searchValue2 !== selectedSuggestion2) {
        const response = await axios.get(`${Url.BASEURL}/api/user/suggestions`, {
          params: {
            input: searchValue2,
          },
        });
        const responseData = response.data;
        setSuggestions2(responseData);
      } else {
        setSuggestions2(null);
      }
    } catch (error) {
      setSuggestions2([]);
    }
  };

  const handleInputChange2 = (e) => {
    const inputValue = e.target.value;
    setSearchValue2(inputValue);

    const isNearby = /^nearby\s*$/i.test(inputValue);
    const isNearMe = /(\w+)\s+near\s+me/i.test(inputValue);

    if (inputValue.trim() === "" || isNearby || isNearMe) {
      setSuggestions2([]);
      setWrittenData2(inputValue);
      return;
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchSuggestions2();
    }, 1000);

    return () => clearTimeout(timerId);
  }, [searchValue2, selectedSuggestion2]);

  const handleSelectSuggestion2 = (suggestion) => {
    setSelectedSuggestion2(suggestion.description);
    setSearchValue2(suggestion.description);
    setSuggestions2([]);
  };

  const fetchSuggestions3 = async () => {
    try {
      if (searchValue3 && searchValue3 !== selectedSuggestion3) {
        const response = await axios.get(`${Url.BASEURL}/api/user/suggestions`, {
          params: {
            input: searchValue3,
          },
        });
        const responseData = response.data;
        setSuggestions3(responseData);
      } else {
        setSuggestions3(null);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions3([]);
    }
  };

  const handleInputChange3 = (e) => {
    const inputValue = e.target.value;
    setSearchValue3(inputValue);

    const isNearby = /^nearby\s*$/i.test(inputValue);
    const isNearMe = /(\w+)\s+near\s+me/i.test(inputValue);

    if (inputValue.trim() === "" || isNearby || isNearMe) {
      setSuggestions3([]);
      setWrittenData3(inputValue);
      return;
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchSuggestions3();
    }, 1000);

    return () => clearTimeout(timerId);
  }, [searchValue3, selectedSuggestion3]);

  const handleSelectSuggestion3 = (suggestion) => {
    setSelectedSuggestion3(suggestion.description);
    setSearchValue3(suggestion.description);
    setSuggestions3([]);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const renderThumbCoffee = (props, state) => {
    let displayValue;
    if (activeModesearch1 === "distance") {
      displayValue = `${Math.round(state.value)} miles`;
    } else {
      displayValue = `${Math.round(state.value)} mins`;
    }
    return <div {...props}>{displayValue}</div>;
  };

  const renderThumbSchool = (props, state) => {
    let displayValue;
    if (activeModesearch2 === "distance") {
      displayValue = `${Math.round(state.value)} miles`;
    } else {
      displayValue = `${Math.round((state.value / 200) * 180)} mins`;
    }
    return <div {...props}>{displayValue}</div>;
  };

  const renderThumbGym = (props, state) => {
    let displayValue;
    if (activeModesearch3 === "Distance") {
      displayValue = `${Math.round(state.value)} miles`;
    } else {
      displayValue = `${Math.round((state.value / 200) * 180)} mins`;
    }
    return <div {...props}>{displayValue}</div>;
  };

  const handleIconClickRow1 = (iconName) => {
    setActiveIconRow1(iconName);
  };

  const handleIconClickRow2 = (iconName) => {
    setActiveIconRow2(iconName);
  };

  const handleIconClickRow3 = (iconName) => {
    setActiveIconRow3(iconName);
  };

  useEffect(() => {
    setsliderValuesearch1((prev) => ({ ...prev, mode: activeModesearch1.toLowerCase() }));
  }, [activeModesearch1]);

  useEffect(() => {
    setsliderValuesearch2((prev) => ({ ...prev, mode: activeModesearch2.toLowerCase() }));
  }, [activeModesearch2]);

  useEffect(() => {
    setsliderValuesearch3((prev) => ({ ...prev, mode: activeModesearch3.toLowerCase() }));
  }, [activeModesearch3]);

  const handleSliderChange1 = (newValue) => {
    setsliderValuesearch1({ value: newValue, mode: activeModesearch1 });
  };

  const handleSliderChange2 = (newValue) => {
    setsliderValuesearch2({ value: newValue, mode: activeModesearch2 });
  };

  const handleSliderChange3 = (newValue) => {
    setsliderValuesearch3({ value: newValue, mode: activeModesearch3 });
  };

  return (
    <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
      <div className="sel_icon">
        <img src={SearchImage3} alt="propertyType" />
      </div>
      <div className="sel_text">
        {(searchValue1 !== "" || searchValue2 !== "" || searchValue3 !== "") && (
          <>
            {searchValue1.split(",")[0]}
            {searchValue2.split(",")[0] && `,`} {searchValue2.split(",")[0]}
            {searchValue3.split(",")[0] && `,`} {searchValue3.split(",")[0]}
          </>
        )}
        {searchValue1 === "" && searchValue2 === "" && searchValue3 === "" && "Lifestyle Search (Optional)"}
      </div>

      {isOpen && (
        <div className="property_drop lifestyledrop" onClick={(e) => e.stopPropagation()}>
          <h3>LIFESTYLE CRITERIA</h3>
          <div className="single_full_search  d-flex">
            <div className="left_lifestyle ">
              <div className="single_search_area">
                <input type="text" className="form-control" placeholder="Search for nearby places or anything else" value={searchValue1} onChange={handleInputChange1} />
                {Array.isArray(suggestions1) &&
                  suggestions1.map((suggestion) => (
                    <div className="suggestion1">
                      <ul>
                        {suggestions1.map((suggestion, index) => (
                          <li key={index} onClick={() => handleSelectSuggestion1(suggestion)}>
                            {suggestion.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>

            <div className="right_lifestyle">
              <div className="distancetime d-flex">
                <div className="left_time">
                  <span className={`link ${activeModesearch1 === "distance" ? "active" : ""}`} onClick={() => setactiveModesearch1("distance")}>
                    Distance
                  </span>
                  /
                  <span className={`link ${activeModesearch1 === "time" ? "active" : ""}`} onClick={() => setactiveModesearch1("time")}>
                    Time
                  </span>
                </div>
                <div className="right_time d-flex">
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "Vector" ? "active" : ""}`} onClick={() => handleIconClickRow1("Vector")}>
                      <span className="icon-Vector"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "Vector-1" ? "active" : ""}`} onClick={() => handleIconClickRow1("Vector-1")}>
                      <span className="icon-Vector-1"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "Vector-2" ? "active" : ""}`} onClick={() => handleIconClickRow1("Vector-2")}>
                      <span className="icon-Vector-2"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "icon-train" ? "active" : ""}`} onClick={() => handleIconClickRow1("icon-train")}>
                      <span className="icon-train"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow1 === "icon-car" ? "active" : ""}`} onClick={() => handleIconClickRow1("icon-car")}>
                      <span className="icon-car"></span>
                    </div>
                  </div>
                </div>
                <ReactSlider
                  className="horizontal-slider miles_time"
                  thumbClassName="thumb"
                  trackClassName="track"
                  min={0}
                  max={30}
                  value={sliderValuesearch1.value} // Access the value property of the sliderValuesearch1 object
                  onChange={handleSliderChange1}
                  renderThumb={renderThumbCoffee}
                />
              </div>
            </div>
          </div>

          {/* ************* */}

          <div className="single_full_search  d-flex">
            <div className="left_lifestyle ">
              <div className="single_search_area ">
                <input type="text" className="form-control" placeholder="Search for nearby places or anything else" value={searchValue2} onChange={handleInputChange2} />
                {Array.isArray(suggestions2) &&
                  suggestions2.map((suggestion) => (
                    <div className="suggestion1">
                      <ul>
                        {suggestions2.map((suggestion, index) => (
                          <li key={index} onClick={() => handleSelectSuggestion2(suggestion)}>
                            {suggestion.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>

            <div className="right_lifestyle">
              <div className="distancetime d-flex">
                <div className="left_time">
                  <span className={`link ${activeModesearch2 === "distance" ? "active" : ""}`} onClick={() => setactiveModesearch2("distance")}>
                    Distance
                  </span>
                  /
                  <span className={`link ${activeModesearch2 === "time" ? "active" : ""}`} onClick={() => setactiveModesearch2("time")}>
                    Time
                  </span>
                </div>
                <div className="right_time d-flex">
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "Vector" ? "active" : ""}`} onClick={() => handleIconClickRow2("Vector")}>
                      <span className="icon-Vector"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "Vector-1" ? "active" : ""}`} onClick={() => handleIconClickRow2("Vector-1")}>
                      <span className="icon-Vector-1"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "Vector-2" ? "active" : ""}`} onClick={() => handleIconClickRow2("Vector-2")}>
                      <span className="icon-Vector-2"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "icon-train" ? "active" : ""}`} onClick={() => handleIconClickRow2("icon-train")}>
                      <span className="icon-train"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow2 === "icon-car" ? "active" : ""}`} onClick={() => handleIconClickRow2("icon-car")}>
                      <span className="icon-car"></span>
                    </div>
                  </div>
                </div>

                <ReactSlider className="horizontal-slider miles_time" thumbClassName="thumb" trackClassName="track" min={0} max={30} value={sliderValuesearch2.value} onChange={handleSliderChange2} renderThumb={renderThumbSchool} />
              </div>
            </div>
          </div>

          {/* *************** */}

          <div className="single_full_search  d-flex">
            <div className="left_lifestyle ">
              <div className="single_search_area ">
                <input type="text" className="form-control" placeholder="Search for nearby places or anything else" value={searchValue3} onChange={handleInputChange3} />
                {Array.isArray(suggestions3) &&
                  suggestions3.map((suggestion) => (
                    <div className="suggestion1">
                      <ul>
                        {suggestions3.map((suggestion, index) => (
                          <li key={index} onClick={() => handleSelectSuggestion3(suggestion)}>
                            {suggestion.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>

            <div className="right_lifestyle">
              <div className="distancetime d-flex">
                <div className="left_time">
                  <span className={`link ${activeModesearch3 === "distance" ? "active" : ""}`} onClick={() => setactiveModesearch3("distance")}>
                    Distance
                  </span>
                  /
                  <span className={`link ${activeModesearch3 === "time" ? "active" : ""}`} onClick={() => setactiveModesearch3("time")}>
                    Time
                  </span>
                </div>
                <div className="right_time d-flex">
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "Vector" ? "active" : ""}`} onClick={() => handleIconClickRow3("Vector")}>
                      <span className="icon-Vector"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "Vector-1" ? "active" : ""}`} onClick={() => handleIconClickRow3("Vector-1")}>
                      <span className="icon-Vector-1"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "Vector-2" ? "active" : ""}`} onClick={() => handleIconClickRow3("Vector-2")}>
                      <span className="icon-Vector-2"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "icon-train" ? "active" : ""}`} onClick={() => handleIconClickRow3("icon-train")}>
                      <span className="icon-train"></span>
                    </div>
                  </div>
                  <div className="icons_row">
                    <div className={`list_icon ${activeIconRow3 === "icon-car" ? "active" : ""}`} onClick={() => handleIconClickRow3("icon-car")}>
                      <span className="icon-car"></span>
                    </div>
                  </div>
                </div>
                <ReactSlider className="horizontal-slider miles_time" thumbClassName="thumb" trackClassName="track" min={0} max={30} value={sliderValuesearch3.value} onChange={handleSliderChange3} renderThumb={renderThumbGym} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LifeStyleSearchDropdown;
