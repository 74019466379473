import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./AdvancedSearch.css";
import Url from "../../Element/Api";
import Chevron from "../../../public/chvdn.svg";
import Cross from "../../../public/assets/cross.svg";
import { useSelectedOptions } from "../../SelectedOptionsContext";
import BudgetDropdown from "../../ReusableComponents/BudgetDropdown/BudgetDropdown";
import PropertyTypeDropdown from "../../ReusableComponents/PropertyTypeDropdown/PropertyTypeDropdown";

function AdvancedSearch({ showModal, setShowModal }) {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
  const [budgetOpen, setBudgetOpen] = useState(false);
  const [lifestyleOpen, setLifestyleOpen] = useState(false);
  const [bedsOpen, setBedsOpen] = useState(false);
  const [bathroomsOpen, setBathroomsOpen] = useState(false);
  const [garageOpen, setGarageOpen] = useState(false);
  const [storiesOpen, setStoriesOpen] = useState(false);
  const [parkingSpaceOpen, setParkingSpaceOpen] = useState(false);
  const [unitsOpen, setUnitsOpen] = useState(false);
  const [timeOnSiteOpen, setTimeOnSiteOpen] = useState(false);
  const [dataSourceOpen, setDataSourceOpen] = useState(false);
  const { isTriggeredsearch, setisTriggeredsearch } = useSelectedOptions();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectedOpenOption, setSelectedOpenOption] = useState(null);
  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);
  const [AdvancedsearchValue, setAdvancedSearchValue] = useState("");
  const [SelectedAdvancedsearchValue, setSelectedAdvancedSearchValue] = useState("");
  const { listingType, setListingType } = useSelectedOptions("");
  const { selectedBeds, setSelectedBeds } = useSelectedOptions("");
  const { selectedBathrooms, setSelectedBathrooms } = useSelectedOptions("");
  const { selectedGarage, setSelectedGarage } = useSelectedOptions("");
  const { selectedStories, setSelectedStories } = useSelectedOptions("");
  const { selectedParkingSpace, setSelectedParkingSpace } = useSelectedOptions("");
  const { selectedUnits, setSelectedUnits } = useSelectedOptions("");
  const { selectedTimeOnSite, setSelectedTimeOnSite } = useSelectedOptions("");
  const { selectedDataSource, setSelectedDataSource } = useSelectedOptions("");
  const { squareFeet, setSquareFeet } = useSelectedOptions({ min: "", max: "" });
  const { yearBuilt, setYearBuilt } = useSelectedOptions({ minYear: "", maxYear: "" });
  const { Hoafee, setHoafee } = useSelectedOptions({ minMonthly: "", maxMonthly: "" });
  const { Status, setStatus } = useSelectedOptions("location1");
  const { propertyType, setpropertyType } = useSelectedOptions("");
  const { propertysubType, setpropertysubType } = useSelectedOptions("");
  const { Specialcondition, setSpecialcondition } = useSelectedOptions("");
  const { Style, setStyle } = useSelectedOptions("");
  const { lotSizeadvance, setLotSizeadvance } = useSelectedOptions({ unit: "acres", min: "", max: "" });
  const { selectedSearchvalue, setselectedSearchvalue, searchValue, setSearchValue, selectedType, setselectedType, selectedFeatures, setSelectedFeatures } = useSelectedOptions();
  const [foreclosureselected, setForeclosureselected] = useState(false);
  const { isNewConstructionSelected, setIsNewConstructionSelected } = useSelectedOptions(false);


  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (!e.target.closest(".select_custom")) {
        closeAllDropdowns();
      }
    };
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setSelectedFeatures({
      ...selectedFeatures,
      bedrooms: selectedBeds || "",
      bathrooms: selectedBathrooms || "",
      minSqft: squareFeet.min || "",
      maxSqft: squareFeet.max || "",
      minLotSizeAcres: lotSizeadvance.min || "",
      maxLotSizeAcres: lotSizeadvance.max || "",
    });
  }, [selectedBeds, selectedBathrooms, squareFeet, lotSizeadvance]);

  //Function for autosuggestion of places
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        if (AdvancedsearchValue && AdvancedsearchValue !== SelectedAdvancedsearchValue) {
          const response = await axios.get(`${Url.BASEURL}/api/user/suggestions?input=${AdvancedsearchValue}`);
          const responseData = response.data;
          setSuggestions(responseData);
        } else {
          setSuggestions(null);
        }
      } catch (error) {
        setSuggestions([]);
      }
    };
    const timerId = setTimeout(() => {
      fetchSuggestions();
    }, 2000);
    return () => clearTimeout(timerId);
  }, [AdvancedsearchValue, SelectedAdvancedsearchValue]);

  //Function for saving selected value on state
  const handleSuggestionselection = (suggestion) => {
    setAdvancedSearchValue(suggestion.description);
    setSelectedAdvancedSearchValue(suggestion.description);
    setSearchValue(suggestion.description);
    setselectedSearchvalue(suggestion.description);
    setSuggestions([]);
  };

    //Function for saving selected beds value on state
  const handleBedsSelection = (option) => {
    setSelectedBeds(option);
    setBedsOpen(false);
  };

    //Function for saving selected bathroom value on state
  const handleBathroomSelection = (option) => {
    setSelectedBathrooms(option);
    setBathroomsOpen(false);
  };

    //Function for saving selected garage value on state
  const handleGarageSelection = (option) => {
    setSelectedGarage(option.value);
    setGarageOpen(false);
  };

    //Function for saving selected bathroom value on state
  const handleStoriesSelection = (option) => {
    setSelectedStories(option.value);
    setStoriesOpen(false);
  };

    //Function for saving selected parking space value on state
  const handleParkingSpaceSelection = (option) => {
    setSelectedParkingSpace(option);
    setParkingSpaceOpen(false);
  };

    //Function for saving selected unit value on state
  const handleUnitsSelection = (option) => {
    setSelectedUnits(option);
    setUnitsOpen(false);
  };

    //Function for saving selected time on site  value on state
  const handleTimeOnSiteSelection = (option) => {
    setSelectedTimeOnSite(option);
    setTimeOnSiteOpen(false);
  };

    //Function for saving selected data source value on state
  const handleDataSourceSelection = (option) => {
    setSelectedDataSource(option);
    setDataSourceOpen(false);
  };

    //Function for saving selected sqft value on state
  const handleSquareFeetChange = (inputType, e) => {
    const { value } = e.target;
    setSquareFeet((prevSquareFeet) => ({
      ...prevSquareFeet,
      [inputType]: value,
    }));
  };

    //Function for saving selected lotsize value on state
  const handlelotSizecahange = (event) => {
    const { name, value, type, checked } = event.target;
    setLotSizeadvance((prevLotSize) => ({
      ...prevLotSize,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

    //Function for saving selected year built value on state
  const handleYearBuiltChange = (inputType, value) => {
    setYearBuilt((prevYearBuilt) => ({
      ...prevYearBuilt,
      [inputType]: value,
    }));
  };

    //Function for saving selected HOA value on state
  const handleHoaChanage = (inputType, value) => {
    setHoafee((previousHoafee) => ({
      ...previousHoafee,
      [inputType]: value,
    }));
  };
  
  //Function for closing modal if clicked outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleDocumentClick = (e) => {
    if (!e.target.closest(".select_custom") && (propertyTypeOpen || bedsOpen || budgetOpen || lifestyleOpen || bathroomsOpen || garageOpen || storiesOpen || parkingSpaceOpen || unitsOpen || timeOnSiteOpen || dataSourceOpen)) {
      closeAllDropdowns();
    }
  };
  document.addEventListener("click", handleDocumentClick);

  const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setBedsOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);
    setBathroomsOpen(false);
    setGarageOpen(false);
    setStoriesOpen(false);
    setParkingSpaceOpen(false);
    setUnitsOpen(false);
    setTimeOnSiteOpen(false);
    setDataSourceOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setSelectedOpenOption("Any");
    } else {
      setSelectedOpenOption(null);
    }
  };

  return (
    <div className="free_market_analysis mutual_popuus advanced_search_modal">
      <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal_closer">
              <img src={Cross} alt="close Icon" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body">
              <div className="advanced_searchs">
                <h2>Advanced Search</h2>
                <form className="inq_form">
                  <div className="full_form">
                    <div className="flex_form_row">
                      <div className="flex_half_row">
                        <input
                          type="text"
                          className="form-control"
                          name="Enter City or Zip Code"
                          placeholder="Enter City or Zip Code"
                          value={AdvancedsearchValue}
                          onChange={(e) => {
                            setAdvancedSearchValue(e.target.value);
                          }}
                        />
                        {suggestions && suggestions.length > 0 && (
                          <div className="advance-suggestion-container">
                            <ul>
                              {suggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleSuggestionselection(suggestion)}>
                                  {suggestion.description}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="flex_half_row">
                        <select
                          id="location"
                          className="form-select"
                          name="location"
                          value={listingType}
                          onChange={(e) => {
                            setListingType(e.target.value);
                          }}
                        >
                          <option value="" disabled hidden>
                            Select Listing Type
                          </option>
                          <option value="location1">Location 1</option>
                          <option value="location2">Location 2</option>
                        </select>
                      </div>
                    </div>
                    <div className="row adv_sch_only">
                      <div className="col-md-6">
                        <div className="mb-3 ">
                          <label htmlFor="propertyType" className="form-label">
                            Property
                          </label>
                          <PropertyTypeDropdown
                            isOpen={propertyTypeOpen}
                            toggleDropdown={() => {
                              setPropertyTypeOpen(!propertyTypeOpen);
                              closeAllDropdowns();
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="mb-3 marg_top">
                          <BudgetDropdown
                            isOpen={budgetOpen}
                            toggleDropdown={() => {
                              setBudgetOpen(!budgetOpen);
                              closeAllDropdowns();
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="select_custom" onClick={() => setBedsOpen(!bedsOpen)}>
                          <div className="sel_text">{selectedBeds || "Beds"}</div>
                          {bedsOpen && (
                            <div className="property_drop beds_list">
                              {["Any", "1", "2", "3", "4"].map((option) => (
                                <div key={option} className="custom_drop_list" onClick={() => handleBedsSelection(option)}>
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="select_custom" onClick={() => setBathroomsOpen(!bathroomsOpen)}>
                          <div className="sel_text">{selectedBathrooms || "Baths"}</div>
                          {bathroomsOpen && (
                            <div className="property_drop beds_list">
                              {["Any", "1", "2", "3", "4", "5"].map((option) => (
                                <div key={option} className="custom_drop_list" onClick={() => handleBathroomSelection(option)}>
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="select_custom" onClick={() => setGarageOpen(!garageOpen)}>
                          <div className="sel_text">{selectedGarage || "Garage"}</div>
                          {garageOpen && (
                            <div className="property_drop beds_list">
                              {[
                                { label: "Any", value: "Any" },
                                { label: "1+car", value: 1 },
                                { label: "2+car", value: 2 },
                                { label: "3+car", value: 3 },
                                { label: "4+car", value: 4 },
                              ].map((option) => (
                                <div key={option.label} className="custom_drop_list" onClick={() => handleGarageSelection(option)}>
                                  {option.label}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="select_custom" onClick={() => setStoriesOpen(!storiesOpen)}>
                          <div className="sel_text">{selectedStories || "Stories"}</div>
                          {storiesOpen && (
                            <div className="property_drop beds_list">
                              {[
                                { label: "Any", value: "Any" },
                                { label: "Single-Story", value: 1 },
                                { label: "Multi-Story", value: 2 },
                              ].map((option) => (
                                <div key={option.label} className="custom_drop_list" onClick={() => handleStoriesSelection(option)}>
                                  {option.label}s
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="select_custom" onClick={() => setParkingSpaceOpen(!parkingSpaceOpen)}>
                          <div className="sel_text">{selectedParkingSpace || "Parking Space"}</div>
                          {parkingSpaceOpen && (
                            <div className="property_drop beds_list">
                              {["Any", "1", "2", "3", "4", "5"].map((option) => (
                                <div key={option} className="custom_drop_list" onClick={() => handleParkingSpaceSelection(option)}>
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="select_custom" onClick={() => setUnitsOpen(!unitsOpen)}>
                          <div className="sel_text">{selectedUnits || "Units"}</div>
                          {unitsOpen && (
                            <div className="property_drop beds_list">
                              {["Any", "1", "2", "3", "4", "5"].map((option) => (
                                <div key={option} className="custom_drop_list" onClick={() => handleUnitsSelection(option)}>
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="select_custom" onClick={() => setTimeOnSiteOpen(!timeOnSiteOpen)}>
                          <div className="sel_text">{selectedTimeOnSite || "Time On Site"}</div>
                          {timeOnSiteOpen && (
                            <div className="property_drop beds_list">
                              {["Any", "Less than 1 day", "Less than 3 days", "Less than 1 week", "Less than 2 weeks", "Less than 1 Month", "More than 1 Week", "More than 1 Month", "More than 3 Months", "More than 6 Months"].map((option) => (
                                <div key={option} className="custom_drop_list" onClick={() => handleTimeOnSiteSelection(option)}>
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="select_custom" onClick={() => setDataSourceOpen(!dataSourceOpen)}>
                          <div className="sel_text">{selectedDataSource || "Data Source"}</div>
                          {dataSourceOpen && (
                            <div className="property_drop beds_list">
                              {["Any", "Lincoln County MLS", "Regional MLS (PMAR, EMAR, CCAR)", "Southern Oregon MLS", "Tillamook Board of REALTORS®", "Willamette Valley MLS"].map((option) => (
                                <div key={option} className="custom_drop_list" onClick={() => handleDataSourceSelection(option)}>
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label className="custom-checkbox-container">
                          <input type="checkbox" className="custom-checkbox" />
                          <span className="checkmark"></span>
                          Include Pending
                        </label>
                      </div>
                      <div className="col-md-6">
                        <div className="all_dropd_dns">
                          <div className="single_drop_check">
                            <label className="custom-checkbox-container">
                              <input type="checkbox" className="custom-checkbox" onChange={handleCheckboxChange} />
                              <span className="checkmark"></span>
                              Open House
                            </label>
                            <div className="sub_options_house">
                              <div className="unique-radio-wrapper">
                                <div className="single_rad_btn">
                                  <input type="radio" id="uniqueRadio10" name="openHouse" className="radious" checked={selectedOpenOption === "Any"} onChange={() => setSelectedOpenOption("Any")} disabled={!isCheckboxChecked} />
                                  <label htmlFor="uniqueRadio10" className="unique-radio-label">
                                    <div className="peros">Any</div>
                                  </label>
                                </div>
                                <div className="single_rad_btn">
                                  <input type="radio" id="uniqueRadio11" name="openHouse" className="radious" checked={selectedOpenOption === "Virtual"} onChange={() => setSelectedOpenOption("Virtual")} disabled={!isCheckboxChecked} />
                                  <label htmlFor="uniqueRadio11" className="unique-radio-label">
                                    <div className="peros">Virtual</div>
                                  </label>
                                </div>
                                <div className="single_rad_btn">
                                  <input type="radio" id="uniqueRadio12" name="openHouse" className="radious" checked={selectedOpenOption === "In-Person"} onChange={() => setSelectedOpenOption("In-Person")} disabled={!isCheckboxChecked} />
                                  <label htmlFor="uniqueRadio12" className="unique-radio-label">
                                    <div className="peros">In-Person</div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="single_drop_check">
                            <label className="custom-checkbox-container">
                              <input type="checkbox" className="custom-checkbox" />
                              <span className="checkmark"></span>
                              Virtual Tour
                            </label>
                          </div>
                          <div className="single_drop_check">
                            <label className="custom-checkbox-container">
                              <input type="checkbox" className="custom-checkbox" />
                              <span className="checkmark"></span>
                              Price Reduced
                            </label>
                          </div>
                          <div className="single_drop_check">
                            <label className="custom-checkbox-container">
                              <input
                                type="checkbox"
                                className="custom-checkbox"
                                checked={foreclosureselected}
                                onChange={(e) => {
                                  setForeclosureselected(e.target.checked);
                                }}
                              />
                              <span className="checkmark"></span>
                              Foreclosure
                            </label>
                          </div>
                          <div className="single_drop_check">
                            <label className="custom-checkbox-container">
                              <input type="checkbox" className="custom-checkbox" />
                              <span className="checkmark"></span>
                              Auction
                            </label>
                          </div>
                          <div className="single_drop_check">
                            <label className="custom-checkbox-container">
                              <input
                                type="checkbox"
                                className="custom-checkbox"
                                checked={isNewConstructionSelected}
                                onChange={(e) => {
                                  setIsNewConstructionSelected(e.target.checked);
                                }}
                              />
                              <span className="checkmark"></span>
                              New Construction
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="motial">
                          <h3>Square Feet</h3>
                          <div className="two_input_blocks d-flex square_ft">
                            <div className="left_input_blocks">
                              <input type="text" className="form-control" placeholder="Min sqft" value={squareFeet.min} onChange={(e) => handleSquareFeetChange("min", e)} />
                            </div>
                            <div className="right_input_blocks">
                              <input type="text" className="form-control" placeholder="Max sqft" value={squareFeet.max} onChange={(e) => handleSquareFeetChange("max", e)} />
                            </div>
                          </div>
                          <div className="d-flex acre_swap">
                            <div className="left_swap">
                              <h3>Lot Size</h3>
                            </div>
                            <div className="right_swap">
                              <div className="unique-radio-wrapper">
                                <input type="radio" id="uniqueRadio105" name="unit" value="acres" checked={lotSizeadvance.unit === "acres"} onChange={handlelotSizecahange} />
                                <label htmlFor="uniqueRadio1105" className="unique-radio-label">
                                  <span>acres</span>
                                </label>
                                <input type="radio" id="uniqueRadio205" name="unit" value="sqft" checked={lotSizeadvance.unit === "sqft"} onChange={handlelotSizecahange} />
                                <label htmlFor="uniqueRadio205" className="unique-radio-label">
                                  <span>sqft</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="two_input_blocks d-flex square_ft">
                            <div className="left_input_blocks">
                              <input type="text" name="min" className="form-control" placeholder={`Min ${lotSizeadvance.unit}`} value={lotSizeadvance.min} onChange={handlelotSizecahange} />
                            </div>
                            <div className="right_input_blocks">
                              <input type="text" name="max" className="form-control" placeholder={`Max ${lotSizeadvance.unit}`} value={lotSizeadvance.max} onChange={handlelotSizecahange} />
                            </div>
                          </div>
                          <div className="year_built">
                            <h3>Year Built</h3>
                            <div className="flex_form_row">
                              <div className="flex_half_row">
                                <input type="text" className="form-control" name="Enter City or Zip Code" placeholder="Min year" value={yearBuilt.minYear} onChange={(e) => handleYearBuiltChange("minYear", e.target.value)} />
                              </div>
                              <div className="flex_half_row">
                                <input type="text" className="form-control" name="Enter City or Zip Code" placeholder="Max year" value={yearBuilt.maxYear} onChange={(e) => handleYearBuiltChange("maxYear", e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 topper">
                        <input type="text" className="form-control" name="keyword" placeholder="Type / choose keywords from the list" />
                      </div>
                      <div className="col-md-6">
                        <div className="hoa_fees">
                          <h3>HOA Monthly Fee</h3>
                          <div className="flex_form_row">
                            <div className="flex_half_row">
                              <select id="location" className="form-select" name="location" value={Hoafee.monthly} onChange={(e) => handleHoaChanage("minMonthly", e.target.value)}>
                                <option value="location1">Min fee</option>
                                <option value="location2">Location 2</option>
                              </select>
                            </div>
                            <div className="flex_half_row">
                              <select id="location" className="form-select" name="location" value={Hoafee.monthly} onChange={(e) => handleHoaChanage("maxMonthly", e.target.value)}>
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hidden_submit">
                        {!isAdvancedFilterOpen && (
                          <div className="col-md-6">
                            <Link to={foreclosureselected ? "/foreclosure" : "/search-result"}>
                              <div
                                className="custom_btn search_forming hidebtn"
                                onClick={() => {
                                  setShowModal(false); // Hide modal when the search button is clicked
                                  setisTriggeredsearch(true);
                                }}
                              >
                                Search
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="chavron">
                        <div
                          className="comercial_block d-flex"
                          onClick={() => {
                            setIsAdvancedFilterOpen(!isAdvancedFilterOpen);
                          }}
                        >
                          <div className="left_commerc">
                            <h3>{isAdvancedFilterOpen ? "Hide Advance Filter" : "Show Advance Filter"}</h3>
                          </div>
                          <div className="chevron">
                            <img src={Chevron} alt="chevron" style={{ transform: isAdvancedFilterOpen ? "rotate(180deg)" : "none" }} />
                          </div>
                        </div>
                      </div>
                      {isAdvancedFilterOpen && (
                        <div className="open_xcloser">
                          <div className="row">
                            <div className="col-md-4">
                              <h3>Elementary School</h3>
                              <input type="text" className="form-control" name="Enter City or Zip Code" placeholder="Search" />
                            </div>
                            <div className="col-md-4">
                              <h3>Middle School</h3>
                              <input type="text" className="form-control" name="Enter City or Zip Code" placeholder="Search" />
                            </div>
                            <div className="col-md-4">
                              <h3>High School</h3>
                              <input type="text" className="form-control" name="Enter City or Zip Code" placeholder="Search" />
                            </div>
                            <div className="col-md-6">
                              <h3>Status</h3>
                              <select
                                id="location"
                                className="form-select"
                                name="location"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                                value={Status}
                              >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <h3>Display Property Type</h3>
                              <select
                                id="location"
                                className="form-select"
                                name="location"
                                onChange={(e) => {
                                  setpropertyType(e.target.value);
                                }}
                                value={propertyType}
                              >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <h3>Property Subtype</h3>
                              <select
                                id="location"
                                className="form-select"
                                name="location"
                                onChange={(e) => {
                                  setpropertysubType(e.target.value);
                                }}
                                value={propertysubType}
                              >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <h3>Style</h3>
                              <select
                                id="location"
                                className="form-select"
                                name="location"
                                onChange={(e) => {
                                  setStyle(e.target.value);
                                }}
                                value={Style}
                              >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <h3>Special Conditions</h3>
                              <select
                                id="location"
                                className="form-select"
                                name="location"
                                onChange={(e) => {
                                  setSpecialcondition(e.target.value);
                                }}
                                value={Specialcondition}
                              >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <div className="col-md-6">
                                <Link to="/search-result">
                                  <div
                                    className="custom_btn search_forming"
                                    onClick={() => {
                                      setShowModal(false); // Hide modal when the search button is clicked
                                      setisTriggeredsearch(true);
                                    }}
                                  >
                                    Search
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Backdrop for modal */}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
}
export default AdvancedSearch;
